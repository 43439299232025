import type { Identifier, XYCoord } from "dnd-core";
import type { FC } from "react";
import { Box, Card, TextField, Stack, Typography } from "@mui/material";
import { useMemo, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import { ItemTypes } from "./ItemTypes";
import { MAIN_COLOR } from "../../config/color-config";

const style = {
  width: "25%",
  border: `3px solid ${MAIN_COLOR}`,
  marginBottom: ".5rem",
  backgroundColor: "white",
  cursor: "move",
  display: "inline-block",
};

export interface CardProps {
  index: number; //카드의 순서를 나타내는 인덱스
  id: any; // 고유식별자
  text: string; //카드에 표시될 글자
  moveCard: (dragIndex: number, hoverIndex: number) => void; //카드위치 변경 함수
  writeCard:(e:React.ChangeEvent<HTMLTextAreaElement>) => void; //
}

interface DragItem {
  index: number; //카드의 순서를 나타내는 인덱스
  id: any; // 고유식별자
  type: string; //드래그된 아이템의 타입
}

export const CardItemRow: FC<CardProps> = ({ id, text, index, moveCard,writeCard }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD, //해당타입만 드롭할 수 있게 설정함
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleX =
        (hoverBoundingRect.left - hoverBoundingRect.right) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientX =
        (clientOffset as XYCoord).x - hoverBoundingRect.right;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });


  const keywordCount = useMemo(()=>{
    return text.split('\n').filter(item => item !== '').length
  },[text])

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  preview(drop(ref));
  return (
    <Card ref={ref} sx={{ ...style, opacity }} data-handler-id={handlerId}>
      <Stack
        direction="row"
        sx={{ p: 2, borderBottom: "2px solid gray" }}
        justifyContent={"space-between"}
      >
        <Stack direction="row" gap={1}>
          <Typography variant="subtitle1" fontWeight={"bold"} sx={{color:MAIN_COLOR}}>
            키워드{index+1}
          </Typography>
          <Typography variant="subtitle1" color="grey">
            {keywordCount}/100
          </Typography>
        </Stack>
        <Box ref={drag}>
          <OpenWithIcon sx={{color:MAIN_COLOR}}/>
        </Box>
      </Stack>
      <TextField placeholder="키워드를 입력해주세요. (최대 100개)"multiline rows={10} id={id} sx={{width:"100%"}} value={text} onChange={writeCard}/>
    </Card>
  );
};
