import update from "immutability-helper";
import { useCallback, useMemo } from "react";
import { CardItemRow } from "./CardItemRow";
import { CardItemColumn } from "./CardItemColumn";
import { Stack, useMediaQuery } from "@mui/material";

export interface Item {
  id: number;
  text: string;
}

export interface ContainerState {
  cards: Item[];
}

interface CardContainerProps {
  cards: Item[];
  setCards: any;
}


const CardContainer: React.FC<CardContainerProps> = ({ cards, setCards }) => {
  //화면 사이즈 감지
  const isMdScreen = useMediaQuery("(min-width:900px)");
  //카드 이동함수
  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setCards((prevCards: Item[]) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex] as Item],
          ],
        })
      );
    },
    [setCards]
  );

  //모든 키워드 카운트하기
  const keywordCount = useMemo(() => {
    let result = 0;

    for (let i = 0; i < 4; i++) {
      result += cards[i].text.split("\n").filter((item) => item !== "").length;
    }

    return result;
  }, [cards]);

  //카드에 글쓰는 함수
  const writeCard = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { id, value } = e.target;

      if (
        keywordCount < 200 ||
        value.length < cards[Number(id) - 1].text.length
      ) {
        const keywordsWithoutSpaces = value.replace(" ", "").toUpperCase(); // 공백 문자를 제거합니다.
        setCards((prev: Item[]) =>
          prev.map((item) => {
            if (item.id === Number(id)) {
              return { id: Number(id), text: keywordsWithoutSpaces };
            } else {
              return item;
            }
          })
        );
      }
    },

    [setCards, cards, keywordCount]
  );

  //카드 랜더링 함수
  const renderCardRow = useCallback(
    (card: { id: number; text: string }, index: number) => {
      return (
        <CardItemRow
          writeCard={writeCard}
          key={card.id}
          index={index}
          id={card.id}
          text={card.text}
          moveCard={moveCard}
        />
      );
    },
    [moveCard, writeCard]
  );

  //카드 랜더링 함수
  const renderCardColumn = useCallback(
    (card: { id: number; text: string }, index: number) => {
      return (
        <CardItemColumn
          writeCard={writeCard}
          key={card.id}
          index={index}
          id={card.id}
          text={card.text}
          moveCard={moveCard}
        />
      );
    },
    [moveCard, writeCard]
  );

  return (
    <>
      {isMdScreen ? (
        <Stack direction={"row"} justifyContent={"space-between"} gap={2}>
          {cards.map((card, i) => renderCardRow(card, i))}
        </Stack>
      ) : (
        <Stack direction={"column"} justifyContent={"space-between"} gap={2}>
          {cards.map((card, i) => renderCardColumn(card, i))}
        </Stack>
      )}
    </>
  );
};

export default CardContainer;
