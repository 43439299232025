import whiteLogo from "../../asset/image/logo-white.png";
import blackLogo from "../../asset/image/logo-black.png";

interface LogoImageProps {
  color?: "white" | "black";
  height?: string;
}

const LogoImage: React.FC<LogoImageProps> = ({
  color = "black",
  height = "32",
}) => {
  const handleReload = () => {
    window.location.href = "/";
  };

  return (
    <>
      <img
        style={{ cursor: "pointer" }}
        src={color === "white" ? whiteLogo : blackLogo}
        height={height}
        alt="로고"
        onClick={handleReload}
      />
    </>
  );
};

export default LogoImage;
