import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Drawer, Stack, Typography } from "@mui/material";
import { nav_items } from "./config";
import SideNavItem from "./SideNavItem";
import LogoImage from "../logo/LogoImage";
import { MAIN_COLOR } from "../../config/color-config";
import { Divider } from "@mui/material";
import { useLogoutMutation } from "../../service/user/userHook";
import { userInfoState } from "../../store/userInfoState";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import LogoutIcon from "@mui/icons-material/Logout";
export const SIDENAV_WIDTH = "260px";

interface sideNavProps {
  open: boolean;
  onClose: () => void;
  lgUp: boolean;
}

const SideNav: React.FC<sideNavProps> = (props) => {
  const { open, onClose, lgUp } = props;
  const { userInfo } = userInfoState();
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  const navigate = useNavigate();
  const { mutate: logout } = useLogoutMutation();

  const handleMoveToAdminPage = () => {
    navigate("admin");
  };

  const content = (
    <Box
      sx={{
        zIndex: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: MAIN_COLOR,
        minHeight: "100vh",
      }}
    >
      <Box
        component="nav"
        sx={{
          flexGrow: 1,
          p: 3,
        }}
      >
        <LogoImage color="white" />
        <Stack component="div" sx={{ mt: 10 }}>
          {nav_items.map((item, i) => {
            return (
              <Box key={i}>
                <Typography
                  color="white"
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    fontSize: "18px",
                    p: 1,
                  }}
                >
                  {item.icon}

                  {item.title}
                </Typography>
                {item.subtitles.map((item, i) => {
                  const active = item.path ? pathname === item.path : false;
                  return (
                    <SideNavItem
                      onClose={onClose}
                      active={active}
                      key={i}
                      path={item.path}
                      title={item.title}
                    />
                  );
                })}
                {i !== 3 && <Divider color="#989898" sx={{ my: 2 }} />}
              </Box>
            );
          })}
        </Stack>
      </Box>
      {!!userInfo.name && (
        <Stack sx={{ p: 4, pl: 5 }} gap={2} alignItems={"start"}>
          {userInfo.isAdmin && (
            <Button
              startIcon={<SupervisorAccountIcon />}
              onClick={handleMoveToAdminPage}
              sx={{ color: "white" }}
            >
              관리자 페이지
            </Button>
          )}
          <Button
            onClick={() => {
              logout();
            }}
            startIcon={<LogoutIcon />}
            sx={{ color: "white" }}
          >
            로그아웃
          </Button>
        </Stack>
      )}
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        sx={{ backgroundColor: MAIN_COLOR, minHeight: "100%" }}
        anchor="left"
        open
        PaperProps={{
          sx: {
            width: SIDENAV_WIDTH,
          },
        }}
        variant="permanent"
      >
        <Box sx={{ backgroundColor: MAIN_COLOR }}>{content}</Box>
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          width: SIDENAV_WIDTH,
        },
      }}
      sx={{
        zIndex: (theme) => theme.zIndex.appBar + 100,
      }}
      variant="temporary"
    >
      <Box sx={{ backgroundColor: MAIN_COLOR }}>{content}</Box>
    </Drawer>
  );
};

export default SideNav;
