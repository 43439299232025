import { useMutation } from "@tanstack/react-query";
import { blogKeywordAnalysisLiteAPI } from "./blogKeywordAnalysisLiteAxios";
import { relDataType } from "../keywordAnalysis/keywordAnalysisHooks";

// 첫 번째 매개변수: mutation 성공 시 반환될 데이터의 타입
// 두 번째 매개변수: mutation 실패 시 반환될 에러의 타입
// 세 번째 매개변수: mutation 함수의 매개변수 타입


// 블로그 키워드 간편 분석
export const useBlogInfoLiteMutation = () => {
  return useMutation<getBlogInfoLiteType,Error,string>({
    mutationFn: (keywords:string) =>
    blogKeywordAnalysisLiteAPI.getBlogInfoLite(keywords),
    onSuccess: (data: any) => {
      return data;
    },
    onError: (err) => {
      //console.log("블로그 키워드 간편 분석의 onError >>> ", err);
    },
  });
};

// 블로그 키워드 간편 분석 only items
export const useBlogInfoOnlyItemsMutation = () => {
  return useMutation<getBlogInfoLiteType,Error,string>({
    mutationFn: (keywords:string) =>
    blogKeywordAnalysisLiteAPI.getBlogInfoOnlyItems(keywords),
    onSuccess: (data: any) => {
      return data;
    },
    onError: (err) => {
      //console.log("블로그 키워드 간편 분석 only items onError >>> ", err);
    },
  });
};


export type getBlogInfoLiteType ={
  relData:relDataType,
  total:number,
  items:blogItemType[]
}

export type blogItemType={
  title:string,
  link:string,
  description:string,
  bloggername:string,
  bloggerlink:string,
  postdate:string
}