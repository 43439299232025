import { customAxios } from "../axios-config";

export const shoppingKeywordAnalysisAPI = {
  // 키워드 기본 정보
  getShoppingInfoLite: async (keywords: string) => {
    return customAxios.post("shopping_lite", {keywords, type:"all"}).then((res) => res.data);
  },
  // item만 가지고 오기
  getShoppinginfoLiteOnlyItems :async (keywords:string) => {
    return customAxios.post("shopping_lite", {keywords}).then((res) => res.data);
  },
};


