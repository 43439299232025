import { Box, ButtonBase } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface sideNavItemProps {
  active: boolean;
  path: string;
  title?: string;
  onClose: () => void;
}

const backgroundImage = (opacity:number) => {
  return `
  linear-gradient(45deg, rgba(123, 104, 244, ${opacity}), rgba(71, 142, 237, ${opacity}))
`;
};

const SideNavItem: React.FC<sideNavItemProps> = (props) => {
  const { active = false, path, title } = props;

  const navigate = useNavigate();

  const handlerClick = () => {
    navigate(path);
  };

  return (
    <>
      <ButtonBase
        sx={{
          mb:0.5,
          color:"white",
          alignItems: "center",
          borderRadius: 1,
          display: "flex",
          justifyContent: "flex-start",
          p: 1,
          textAlign: "left",
          pl:"45px",
          width: "100%",
          ...(active && {
            backgroundImage:backgroundImage(0.8),
            color: "white",
          }),
          "&:hover": {
            backgroundColor:"#453c9f",
          },
          transition: "all 0.1s",
        }}
        onClick={handlerClick}
      >
        <Box
          component="span"
          sx={{
            flexGrow: 1,
            fontSize: 14,
            fontWeight: 600,
            lineHeight: "24px",
            whiteSpace: "nowrap",
          }}
        >
          {title}
        </Box>
      </ButtonBase>
    </>
  );
};

export default SideNavItem;
