import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface RememberMeStateType{
  rememberMe:boolean,
  check:()=>void,
  unCheck:()=>void,
}

export const rememberMeState = create(
  persist<RememberMeStateType>(
    (set, get) => ({
      rememberMe: false,
      check: () => {set({ rememberMe: true })},
      unCheck: () => {set({ rememberMe: false })},
    }),
    {
      name: "REMEMBER_ME",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export const unCheckRememberMe = rememberMeState.getState().unCheck;
