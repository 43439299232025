import { Box, Typography, Stack, Grid } from "@mui/material";
import SearchInput from "../input/SearchInput";
import { useBannerListQuery } from "../../service/admin/adminHook";
interface SearchAndBannerProps {
  value: string;
  onChange: (value?: any) => void;
  onSubmit: (value?: any) => void;
}

const SearchAndBanner: React.FC<SearchAndBannerProps> = ({
  value,
  onChange,
  onSubmit,
}) => {
  const { data: bannerList } = useBannerListQuery();

  const openNewSite = (url: string) => {
    // 입력된 URL이 이미 절대 경로인지 확인
    if (url.startsWith("http://") || url.startsWith("https://")) {
      window.open(url, "_blank");
    } else {
      // 상대 경로인 경우 현재 도메인과 조합하여 절대 경로 생성
      window.open("http://" + url, "_blank");
    }
  };

  return (
    <>
      <Box
        component="div"
        sx={{
          mt: 1,
        }}
      >
        <Stack
          sx={{ textAlign: "center", maxWidth: "1000px", margin: "0 auto" }}
          gap={2}
        >
          <Typography
            fontWeight="600"
            color="#1e1e1e"
            sx={{
              wordBreak: "keep-all",
              overflowWrap: "break-word",
              lineHeight: "25px",
              
            }}
          >
            네이버 기반 키워드에 대한 데이터를 가장 정확하게 분석합니다.
            <br />
            키워드에 대한 여러가지 분석과 검색량 데이터를 제공하며 다운로드
            기능까지 제공합니다.
          </Typography>
          <SearchInput
            onChange={onChange}
            value={value}
            onSubmit={onSubmit}
            placeholder="쉼표로 구분하여 최대 5개까지!"
          />
          {!!bannerList && (
            <Grid container spacing={2}>
              {Object.keys(bannerList).map((key) => {
                const { id, url, image } = bannerList[key];
                return (
                  <Grid key={id} item xs={12} sm={6}>
                    <Box
                      sx={{
                        borderRadius: "8px",
                        backgroundImage: `url(${image})`,
                        backgroundSize: "100% 100%",
                        aspectRatio: "2/1",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        openNewSite(url)
                      }}
                    >
                      {url}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default SearchAndBanner;
