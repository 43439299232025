import { Card, Stack, Typography, Button, Grid, Divider } from "@mui/material";
import CombineItem from "./CombineItem";
import { combinePattern } from "../../pages/KeywordCombiner";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { MAIN_COLOR } from "../../config/color-config";

interface CombineContainerProps {
  selected: boolean[];
  handleCheckAll: () => void;
  handleUnCheckAll: () => void;
  handleCheckOne: (idx: number) => void;
}

const CombineContainer: React.FC<CombineContainerProps> = ({
  selected,
  handleCheckAll,
  handleUnCheckAll,
  handleCheckOne,
}) => {
  return (
    <>
      <Card sx={{ p: 3, height: "900px" }}>
        <Stack direction="column">
          {/* 제목 버튼 */}
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ mb: 5 }}
          >
            <Typography variant="subtitle1" fontWeight={"bold"}>
              키워드 조합 규칙 설정
            </Typography>
            <Stack direction={"row"} gap={2}>
              <Button
              sx={buttonStyle}
                variant="contained"
                onClick={handleCheckAll}
                startIcon={<AddCircleOutlinedIcon />}
              >
                전체 선택
              </Button>
              <Button
              sx={buttonStyle}
                variant="contained"
                onClick={handleUnCheckAll}
                startIcon={<RemoveCircleOutlinedIcon />}
              >
                전체 해제
              </Button>
            </Stack>
          </Stack>

          <Grid container>
            {/* 첫번째 조합 */}
            {combinePattern(1).map((item, i) => (
              <Grid item xs={3} key={i}>
                <CombineItem
                  text={item}
                  index={i}
                  onCheck={handleCheckOne}
                  check={selected[i]}
                />
              </Grid>
            ))}
            <Grid xs={12}>
              <Divider sx={{ my: 2 }} />
            </Grid>
            {/* 두번째 조합 */}
            {combinePattern(2).map((item, i) => (
              <Grid item xs={3} key={i}>
                <CombineItem
                  text={item}
                  index={i + 4}
                  onCheck={handleCheckOne}
                  check={selected[i + 4]}
                />
              </Grid>
            ))}
            <Grid xs={12}>
              <Divider sx={{ my: 2 }} />
            </Grid>
            {/*세번째 조합 */}
            {combinePattern(3).map((item, i) => (
              <Grid item xs={3} key={i}>
                <CombineItem
                  text={item}
                  index={i + 16}
                  onCheck={handleCheckOne}
                  check={selected[i + 16]}
                />
              </Grid>
            ))}
            <Grid xs={12}>
              <Divider sx={{ my: 2 }} />
            </Grid>
            {/* 네번째 조합 */}
            {combinePattern(4).map((item, i) => (
              <Grid item xs={3} key={i}>
                <CombineItem
                  text={item}
                  index={i + 40}
                  onCheck={handleCheckOne}
                  check={selected[i + 40]}
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Card>
    </>
  );
};

export default CombineContainer;

const buttonStyle = {
  borderRadius:"30px",
  backgroundColor:MAIN_COLOR,
};
