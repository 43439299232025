import Layout from "../pages/Layout";
import { createBrowserRouter } from "react-router-dom";
import KeywordAnalysis from "../pages/KeywordAnalysis";
import KeywordAnalysisBulk from "../pages/KeywordAnalysisBulk";
import KeywordCombiner from "../pages/KeywordCombiner";
import BlogKeywordAnalysis from "../pages/BlogKeywordAnalysis";
import BlogKeywordAnalysisLite from "../pages/BlogKeywordAnalysisLite";
import ShoppingKeywordAnalysis from "../pages/ShoppingKeywordAnalysis";
import ErrorBoundary from "../component/error/ErrorBoundary";
// import Test from "../pages/Test";
import SignUp from "../pages/SignUp";
import Admin from "../pages/Admin";
export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "keyword-analysis",
        element: <KeywordAnalysis />,
      },
      {
        path: "keyword-analysis-bulk",
        element: <KeywordAnalysisBulk />,
      },
      {
        path: "keyword-combiner",
        element: <KeywordCombiner />,
      },
      {
        path: "blog-keyword-analysis",
        element: <BlogKeywordAnalysis />,
      },
      {
        path: "blog-keyword-analysis-lite",
        element: <BlogKeywordAnalysisLite />,
      },
      {
        path: "shopping-keyword-analysis",
        element: <ShoppingKeywordAnalysis />,
      },
      {
        path: "admin",
        element: <Admin />,
      },
    ],
  },
  {
    path: "signup",
    element: <SignUp />,
  },
  // {
  //   path: "/test",
  //   element: <Test />,
  // },
]);
