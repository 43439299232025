import { create } from "zustand";

interface OpenLoginModalStateType {
  openLoginModal: boolean;
  open: () => void;
  close: () => void;
}

export const openLoginModalState = create<OpenLoginModalStateType>()((set) => ({
  openLoginModal: false,
  open: () => set({ openLoginModal: true }),
  close: () => set({ openLoginModal: false }),
}));

export const openLoginModal = openLoginModalState.getState().open;
