import { Box, Container } from "@mui/material";
import SideNav from "../component/side-nav/SideNav";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import TopNav from "../component/top-nav/TopNav";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../component/footer/Footer";
import { useNavigate } from "react-router-dom";
import { SIDENAV_WIDTH } from "../component/side-nav/SideNav";

const Layout: React.FC = () => {
  
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  const [openNav, setOpenNav] = useState(false);
  const lgUp = useMediaQuery("(min-width:1200px)");
  const navigation = useNavigate();

  useEffect(() => {
    // 페이지 이동하면 사이드바 접기 
    setOpenNav(false);
    
    // '/'로 오면 페이지 강제 redirect
    if(!pathname){
      navigation("/keyword-analysis")
    }
    
   
  }, [pathname, navigation]);

  //1200보다 작으면 false 크면 true
  const handleChangeOpen = () => {
    setOpenNav(!openNav);
    
  };
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        position: "relative",
        boxSizing: "border-box",
        paddingLeft: lgUp ? SIDENAV_WIDTH : "",
      }}
    >
      {/* top-Navigation */}
      <TopNav onOpen={handleChangeOpen} lgUp={lgUp}/>

      {/* side-Navigation */}
      <SideNav open={openNav} onClose={handleChangeOpen} lgUp={lgUp} />
      {/* <SideNav open={true} onClose={handleChangeOpen} lgUp={false} /> */}

      {/* main-content */}
      <Container
        sx={{
          pt: 10,
          maxWidth: "1200px",
          margin: "0 auto",
          boxSizing: "border-box",
          minHeight: "100%",
          paddingBottom: "250px",
        }}
      >
        <Outlet />
      </Container>
      <Footer lgUp={lgUp} />
    </Box>
  );
};

export default Layout;
