import { Stack, Grid, Typography, Box, Card } from "@mui/material";
import TitleAndDescription from "../title-and-description/TitleAndDescription";
import { getColor } from "../../config/color-config";
import { ComponentStyle } from "./common";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";
import {
  genderRateType,
  relDataType,
} from "../../service/keywordAnalysis/keywordAnalysisHooks";
import { useMemo } from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

interface GenderAndDeviceSearchRatioProps {
  keywords: string[];
  genderRate: genderRateType | undefined;
  relData: relDataType | undefined;
}

const GenderAndDeviceSearchRatio: React.FC<GenderAndDeviceSearchRatioProps> = ({
  genderRate,
  relData,
  keywords,
}) => {
  const dataset = useMemo(() => {
    if (!!relData && !!keywords && !!genderRate) {
      return keywords.map((keyword) => {
        const pc = Number(
          typeof relData[keyword].monthlyPcQcCnt === "number"
            ? relData[keyword].monthlyPcQcCnt
            : 10
        );
        const mobile = Number(
          typeof relData[keyword].monthlyMobileQcCnt === "number"
            ? relData[keyword].monthlyMobileQcCnt
            : 10
        );

        return {
          keyword,
          man: Math.round(Number(genderRate[keyword]["m"]) * 100),
          woman: Math.round(Number(genderRate[keyword]["f"]) * 100),
          pc: Math.round((pc * 100) / (pc + mobile)),
          mobile: Math.round((mobile * 100) / (pc + mobile)),
        };
      });
    } else {
      return [];
    }
  }, [keywords, genderRate, relData]);

  return (
    <Card>
      <Stack sx={ComponentStyle} gap={!!relData ? 2 : 0}>
        <TitleAndDescription
          title={description.title}
          content={description.content}
        />
        <Grid container spacing={2} sx={{ p: 1 }}>
          {dataset.map((item, i) => {
            return (
              <Grid item xs={12} md={6} key={i}>
                <RatioItem
                  idx={i}
                  keyword={item.keyword}
                  man={item.man}
                  woman={item.woman}
                  pc={Number(item.pc)}
                  mobile={Number(item.mobile)}
                />
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Card>
  );
};

export default GenderAndDeviceSearchRatio;

function commonStyle(idx: number) {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    p: 2,
    backgroundColor: getColor(idx, 0.1),
    borderRadius: "8px",
  };
}

interface RatioItemProps {
  idx: number;
  keyword: string;
  man: number;
  woman: number;
  pc: number;
  mobile: number;
}

const RatioItem: React.FC<RatioItemProps> = ({
  idx,
  keyword,
  man,
  woman,
  pc,
  mobile,
}) => {
  return (
    <>
      <Box sx={commonStyle(idx)}>
        <Stack direction={"row"} alignItems={"center"} sx={{ width: "100%" }}>
          <Box
            component="div"
            sx={{
              width: "8px",
              height: "30px",
              backgroundColor: getColor(idx),
              borderRadius: "8px",
              mr: 1,
            }}
          />
          <Typography variant="subtitle1" fontWeight={"bold"}>
            {keyword}
          </Typography>
        </Stack>

        <Grid container sx={{ width: "100%" }}>
          {/* 성별 */}
          <Grid item xs={12} sm={6} sx={{ position: "relative" }}>
            {man + woman === 0 ? (
              <Card
                sx={{
                  border: "none",
                  p: 3,
                  m: 2,
                  borderRadius: "8px",
                  boxShadow: "1px 1px 1px grey",
                  backgroundColor: "rgba(255,255,255,0.9)",
                  position: "relative",
                  top: "20%",
                }}
              >
                <Box sx={{ textAlign: "center", width: "100%", mb: 1 }}>
                  <WarningAmberIcon color="error" />
                </Box>
                <Typography
                  textAlign={"center"}
                  variant="body2"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  죄송합니다.
                </Typography>
                <Typography
                  textAlign={"center"}
                  variant="body2"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  데이터가 부족하여
                </Typography>
                <Typography
                  textAlign={"center"}
                  variant="body2"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  표시할 수 없습니다.
                </Typography>
              </Card>
            ) : (
              <Typography
                variant="subtitle1"
                fontWeight={"bold"}
                sx={{
                  position: "absolute",
                  top: "45%", // 부모 요소의 상단에서 50% 지점에 배치
                  left: "50%", // 부모 요소의 왼쪽에서 50% 지점에 배치
                  transform: "translate(-50%, -50%)",
                }}
              >
                성별
              </Typography>
            )}
            {man + woman !== 0 && (
              <PieChart
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: "white",
                    fontWeight: "bold",
                  },
                }}
                series={[
                  {
                    innerRadius: 33,
                    arcLabel: (item) => `${item.value}%`,
                    arcLabelMinAngle: 45,
                    data: [
                      { id: 0, value: man, label: "남자", color: getColor(0) },
                      {
                        id: 1,
                        value: woman,
                        label: "여자",
                        color: getColor(1),
                      },
                    ],
                  },
                ]}
                height={280}
                margin={{ left: 10, right: 10, bottom: 60, top: 30 }}
                slotProps={{
                  legend: {
                    direction: "row",
                    position: {
                      vertical: "bottom",
                      horizontal: "middle",
                    },
                  },
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ position: "relative" }}>
            {pc + mobile === 0 ? (
              <Card
                sx={{
                  border: "none",
                  p: 3,
                  m: 2,
                  borderRadius: "8px",
                  boxShadow: "1px 1px 1px grey",
                  backgroundColor: "rgba(255,255,255,0.9)",
                  position: "relative",
                  top: "20%",
                }}
              >
                <Box sx={{ textAlign: "center", width: "100%" }}>
                  <WarningAmberIcon color="error" />
                </Box>
                <Typography
                  textAlign={"center"}
                  variant="body2"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  죄송합니다.
                </Typography>
                <Typography
                  textAlign={"center"}
                  variant="body2"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  데이터가 부족하여
                </Typography>
                <Typography
                  textAlign={"center"}
                  variant="body2"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  표시할 수 없습니다.
                </Typography>
              </Card>
            ) : (
              <Typography
                variant="subtitle1"
                fontWeight={"bold"}
                sx={{
                  position: "absolute",
                  top: "45%", // 부모 요소의 상단에서 50% 지점에 배치
                  left: "50%", // 부모 요소의 왼쪽에서 50% 지점에 배치
                  transform: "translate(-50%, -50%)",
                }}
              >
                기기
              </Typography>
            )}
            {/* 기기 */}

            {pc + mobile !== 0 && (
              <PieChart
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: "white",
                    fontWeight: "bold",
                  },
                }}
                series={[
                  {
                    innerRadius: 33,
                    arcLabel: (item) => `${item.value}%`,
                    arcLabelMinAngle: 45,
                    data: [
                      { id: 0, value: pc, label: "PC", color: getColor(2) },
                      {
                        id: 1,
                        value: mobile,
                        label: "Mobile",
                        color: getColor(3),
                      },
                    ],
                  },
                ]}
                height={280}
                margin={{ left: 10, right: 10, bottom: 60, top: 30 }}
                slotProps={{
                  legend: {
                    direction: "row",
                    position: {
                      vertical: "bottom",
                      horizontal: "middle",
                    },
                  },
                }}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

//임시 데이터들
const description = {
  title: "성별 및 기기 검색 비율",
  content: ["전체 기간에서 성별 및 기기 검색 비율을 나타냅니다."],
};
