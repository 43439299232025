import { Grid, Typography, Stack } from "@mui/material";
import { DESCRIPTION_COLOR, MAIN_COLOR } from "../config/color-config";
import RelatedKeywordsList from "../component/shopping-keyword-analysis/RelatedKeywordsList";
import RelatedKeywordsTable from "../component/shopping-keyword-analysis/RelatedKeywordsTable";
import {
  useShoppingMutation,
  useShoppingOnlyItemsMutation,
} from "../service/shoppingKeywordAnalysis/shoppingKeywordAnalysisHooks";
import Loading from "../component/loading/Loading";
import { ChangeEvent, useState } from "react";
import { shoppingItemType } from "../service/shoppingKeywordAnalysis/shoppingKeywordAnalysisHooks";
import { reg } from "../config/regular-expression";
import { Helmet } from "react-helmet";
import SearchInput from "../component/input/SearchInput";
import { CustomIcon } from "../component/side-nav/config";
import shoppingIcon from "../asset/image/shopping-icon-black.png";
export type shoppingDetailListType = {
  keyword: string;
  pc: number;
  mobile: number;
  total: number;
  productCount: number;
  rate: number;
  items: shoppingItemType[];
};

const ShoppingKeywordAnalysis: React.FC = () => {
  const [inputText, setInputText] = useState<string>("");
  const [shoppingList, setShoppingList] = useState<shoppingDetailListType[]>(
    []
  );
  const { mutateAsync: getShoppingInfoOnlyItems, isPending: isPending1 } =
    useShoppingOnlyItemsMutation(); // item만 가져오기
  const {
    data: shoppingInfo,
    mutateAsync: getShoppingInfo,
    isPending: isPending2,
  } = useShoppingMutation(); //전부다 가져오기

  //검색창에서 검색했을 경우
  const onSubmit = async (keyword: string) => {
    //연관 키워드 불러오기
    const data = await getShoppingInfo(keyword);
    if (!!data) {
      setShoppingList((prev) => {
        if (prev.filter((item) => item.keyword === keyword).length === 0) {
          const pc = Number(
            typeof data.relData[keyword].monthlyPcQcCnt === "string"
              ? 10
              : data.relData[keyword].monthlyPcQcCnt
          );
          const mobile = Number(
            typeof data.relData[keyword].monthlyMobileQcCnt === "string"
              ? 10
              : data.relData[keyword].monthlyMobileQcCnt
          );
          return [
            ...prev,
            {
              keyword,
              pc,
              mobile,
              total: pc + mobile,
              productCount: data.total,
              rate: data.total / (pc + mobile),
              items: data.items,
            },
          ];
        } else {
          return prev;
        }
      });
    }
  };

  //연관 키워드 => 테이블에 추가하기
  const handleAddKeyword = async (keyword: string) => {
    const arr = shoppingList.filter((item) => item.keyword === keyword);
    if (arr.length === 0) {
      const data = await getShoppingInfoOnlyItems(keyword);
      if (!!data && !!shoppingInfo) {
        setShoppingList((prev) => {
          const pc = Number(
            typeof shoppingInfo.relData[keyword].monthlyPcQcCnt === "string"
              ? 10
              : shoppingInfo.relData[keyword].monthlyPcQcCnt
          );
          const mobile = Number(
            typeof shoppingInfo.relData[keyword].monthlyMobileQcCnt === "string"
              ? 10
              : shoppingInfo.relData[keyword].monthlyMobileQcCnt
          );
          return [
            ...prev,
            {
              keyword,
              pc,
              mobile,
              total: pc + mobile,
              productCount: data.total,
              rate: data.total / (pc + mobile),
              items: data.items,
            },
          ];
        });
      }
    }
  };

  //연관 키워드에서 제거
  const handleDeleteKeywordDetail = (keyword: string) => {
    setShoppingList((prev) => {
      return prev.filter((item) => item.keyword !== keyword);
    });
  };

  //input 값 변경
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value.toUpperCase());
  };

  //enter 누를 때 발생하는 일
  const handleSubmit = () => {
    const value = inputText.replace(reg, "");
    if (!!value) {
      setInputText(value);
      onSubmit(value);
    }
  };

  return (
    <>
      <Helmet>
        <meta
          name="쇼핑 키워드 분석"
          content="키워드를 통해 쇼핑관련 검색량을 통해 더욱 간편하게 분석할 수 있습니다."
        />
      </Helmet>
      <Loading open={isPending2 || isPending1} />
      <Grid rowSpacing={5} container>
        <Grid item xs={12}>
          {/* 제목 */}
          <Stack gap={1}>
            <Typography fontWeight={900} variant="h5" color={MAIN_COLOR}>
              <CustomIcon
                src={shoppingIcon}
                alt="blog-icon"
                width={"40px"}
                top="5px"
              />
              쇼핑 키워드 분석
            </Typography>
            <Typography
              variant="body1"
              fontWeight={600}
              color={DESCRIPTION_COLOR}
            >
              키워드를 통해 쇼핑관련 검색량을 통해 더욱 간편하게 분석할 수
              있습니다.
            </Typography>
            <SearchInput
              placeholder="키워드를 입력해 주세요"
              value={inputText}
              onChange={handleInputChange}
              onSubmit={handleSubmit}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <RelatedKeywordsList
            shoppingInfo={shoppingInfo}
            handleAddKeyword={handleAddKeyword}
          />
        </Grid>
        <Grid item xs={12}>
          <RelatedKeywordsTable
            handleDeleteKeywordDetail={handleDeleteKeywordDetail}
            rows={shoppingList}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ShoppingKeywordAnalysis;
