import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import { Button, Card, Grid } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import SearchIcon from "@mui/icons-material/Search";
import { MAIN_COLOR, getColor } from "../../config/color-config";
import { Stack } from "@mui/material";
import { CSVLink } from "react-csv";
import { bulkKeywordToolsType } from "../../service/keywordAnalysisBulk/keywordAnalysisBulkHooks";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import SearchInput from "../input/SearchInput";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";

function createData(
  id: number,
  relKeyword: string,
  monthlyPcQcCnt: number,
  monthlyMobileQcCnt: number,
  monthlyTotalQcCnt: number,
  monthlyAvePcClkCnt: number,
  monthlyAveMobileClkCnt: number,
  monthlyAvePcCtr: number,
  monthlyAveMobileCtr: number,
  plAvgDepth: number,
  compIdx: string
) {
  return {
    id,
    relKeyword,
    monthlyPcQcCnt,
    monthlyMobileQcCnt,
    monthlyTotalQcCnt,
    monthlyAvePcClkCnt,
    monthlyAveMobileClkCnt,
    monthlyAvePcCtr,
    monthlyAveMobileCtr,
    plAvgDepth,
    compIdx,
  };
}

interface Data {
  id: number;
  relKeyword: string;
  monthlyPcQcCnt: number;
  monthlyMobileQcCnt: number;
  monthlyTotalQcCnt: number;
  monthlyAvePcClkCnt: number;
  monthlyAveMobileClkCnt: number;
  monthlyAvePcCtr: number;
  monthlyAveMobileCtr: number;
  plAvgDepth: number;
  compIdx: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead sx={{ borderTop: "1px solid #e3e3e3" }}>
      <TableRow>
        {/* 체크박스 */}
        <TableCell
          padding="checkbox"
          rowSpan={2}
          sx={{ ...headerTableCellStyle, minWidth: "0px" }}
        >
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {/* 키워드 */}

        <TableCell
          sx={{ ...headerTableCellStyle, minWidth: "150px" }}
          rowSpan={2}
          key={"relKeyword"}
          sortDirection={orderBy === "relKeyword" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "relKeyword"}
            direction={orderBy === "relKeyword" ? order : "asc"}
            onClick={createSortHandler("relKeyword")}
            hideSortIcon={orderBy !== "relKeyword"}
          >
            {"키워드"}
            {orderBy === "relKeyword" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
        {/* 월간 검색량 */}
        <TableCell sx={headerTableCellStyle} colSpan={3}>
          월간 검색량
        </TableCell>
        {/* 월 평균 클릭수 */}
        <TableCell sx={headerTableCellStyle} colSpan={2}>
          월 평균 클릭수
        </TableCell>
        {/* 월 평균 클릭율 */}
        <TableCell sx={headerTableCellStyle} colSpan={2}>
          월 평균 클릭율
        </TableCell>
        {/* 경쟁정도 */}
        <TableCell
          sx={headerTableCellStyle}
          rowSpan={2}
          key={"compIdx"}
          sortDirection={orderBy === "compIdx" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "compIdx"}
            direction={orderBy === "compIdx" ? order : "asc"}
            onClick={createSortHandler("compIdx")}
            hideSortIcon={orderBy !== "compIdx"}
          >
            {"경쟁정도"}
            {orderBy === "compIdx" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>

        {/* 월 평균 노출 광고수 */}
        <TableCell
          sx={headerTableCellStyle}
          rowSpan={2}
          key={"plAvgDepth"}
          sortDirection={orderBy === "plAvgDepth" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "plAvgDepth"}
            direction={orderBy === "plAvgDepth" ? order : "asc"}
            onClick={createSortHandler("plAvgDepth")}
            hideSortIcon={orderBy !== "plAvgDepth"}
          >
            {"월 평균 노출 광고수"}
            {orderBy === "plAvgDepth" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
      </TableRow>
      <TableRow>
        {/* 월간 검색량 PC */}
        <TableCell
          sx={headerTableCellStyle}
          rowSpan={2}
          key={"monthlyPcQcCnt"}
          sortDirection={orderBy === "monthlyPcQcCnt" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "monthlyPcQcCnt"}
            direction={orderBy === "monthlyPcQcCnt" ? order : "asc"}
            onClick={createSortHandler("monthlyPcQcCnt")}
            hideSortIcon={orderBy !== "monthlyPcQcCnt"}
          >
            {"PC"}
            {orderBy === "monthlyPcQcCnt" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
        {/* 월간 검색량 모바일 */}
        <TableCell
          sx={headerTableCellStyle}
          rowSpan={2}
          key={"monthlyMobileQcCnt"}
          sortDirection={orderBy === "monthlyMobileQcCnt" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "monthlyMobileQcCnt"}
            direction={orderBy === "monthlyMobileQcCnt" ? order : "asc"}
            onClick={createSortHandler("monthlyMobileQcCnt")}
            hideSortIcon={orderBy !== "monthlyMobileQcCnt"}
          >
            {"모바일"}
            {orderBy === "monthlyMobileQcCnt" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
        {/* 월간 검색량 전체 */}
        <TableCell
          sx={headerTableCellStyle}
          rowSpan={2}
          key={"monthlyTotalQcCnt"}
          sortDirection={orderBy === "monthlyTotalQcCnt" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "monthlyTotalQcCnt"}
            direction={orderBy === "monthlyTotalQcCnt" ? order : "asc"}
            onClick={createSortHandler("monthlyTotalQcCnt")}
            hideSortIcon={orderBy !== "monthlyTotalQcCnt"}
          >
            {"전체"}
            {orderBy === "monthlyTotalQcCnt" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
        {/* 월 평균 클릭수 PC */}
        <TableCell
          sx={headerTableCellStyle}
          rowSpan={2}
          key={"monthlyAvePcClkCnt"}
          sortDirection={orderBy === "monthlyAvePcClkCnt" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "monthlyAvePcClkCnt"}
            direction={orderBy === "monthlyAvePcClkCnt" ? order : "asc"}
            onClick={createSortHandler("monthlyAvePcClkCnt")}
            hideSortIcon={orderBy !== "monthlyAvePcClkCnt"}
          >
            {"PC"}
            {orderBy === "monthlyAvePcClkCnt" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
        {/* 월 평균 클릭수 모바일 */}
        <TableCell
          sx={headerTableCellStyle}
          rowSpan={2}
          key={"monthlyAveMobileClkCnt"}
          sortDirection={orderBy === "monthlyAveMobileClkCnt" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "monthlyAveMobileClkCnt"}
            direction={orderBy === "monthlyAveMobileClkCnt" ? order : "asc"}
            onClick={createSortHandler("monthlyAveMobileClkCnt")}
            hideSortIcon={orderBy !== "monthlyAveMobileClkCnt"}
          >
            {"모바일"}
            {orderBy === "monthlyAveMobileClkCnt" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
        {/* 월 평균 클릭율 PC */}
        <TableCell
          sx={headerTableCellStyle}
          rowSpan={2}
          key={"monthlyAvePcCtr"}
          sortDirection={orderBy === "monthlyAvePcCtr" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "monthlyAvePcCtr"}
            direction={orderBy === "monthlyAvePcCtr" ? order : "asc"}
            onClick={createSortHandler("monthlyAvePcCtr")}
            hideSortIcon={orderBy !== "monthlyAvePcCtr"}
          >
            {"PC"}
            {orderBy === "monthlyAvePcCtr" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
        {/* 월 평균 클릭율 모바일 */}
        <TableCell
          sx={headerTableCellStyle}
          rowSpan={2}
          key={"monthlyAveMobileCtr"}
          sortDirection={orderBy === "monthlyAveMobileCtr" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "monthlyAveMobileCtr"}
            direction={orderBy === "monthlyAveMobileCtr" ? order : "asc"}
            onClick={createSortHandler("monthlyAveMobileCtr")}
            hideSortIcon={orderBy !== "monthlyAveMobileCtr"}
          >
            {"모바일"}
            {orderBy === "monthlyAveMobileCtr" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const headerTableCellStyle = {
  borderRight: "1px solid #e3e3e3",
  borderLeft: "1px solid #e3e3e3",
  textAlign: "center",
  minWidth: "80px",
  padding: "5px",
};

interface EnhancedTableToolbarProps {
  numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 && (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      )}
    </Toolbar>
  );
}

const TableCellStyle = (index: number) => ({
  backgroundColor: index % 2 === 0 ? "#f4faff" : "",
  textAlign: "center",
});

interface KeywordTableProps {
  data: bulkKeywordToolsType | undefined;
}
const KeywordTable: React.FC<KeywordTableProps> = ({ data }) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("relKeyword");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [rows, setRows] = React.useState<Data[]>([]); // 여러 조작을 해야하기 때문에 담아두고 사용하는게 맞음

  useEffect(() => {
    if (!!data) {
      const keywords = Object.keys(data);
      const newRows = keywords.map((keyword, i) => {
        const {
          relKeyword,
          monthlyPcQcCnt,
          monthlyMobileQcCnt,
          monthlyAvePcClkCnt,
          monthlyAveMobileClkCnt,
          monthlyAvePcCtr,
          monthlyAveMobileCtr,
          plAvgDepth,
          compIdx,
        } = data[keyword];
        const pc = Number(
          typeof monthlyPcQcCnt === "string" ? 10 : monthlyPcQcCnt
        );
        const mobile = Number(
          typeof monthlyMobileQcCnt === "string" ? 10 : monthlyMobileQcCnt
        );
        return createData(
          i + 1,
          relKeyword,
          pc,
          mobile,
          pc + mobile,
          monthlyAvePcClkCnt,
          monthlyAveMobileClkCnt,
          monthlyAvePcCtr,
          monthlyAveMobileCtr,
          plAvgDepth,
          compIdx
        );
      });
      setRows(newRows);
    }
  }, [data]);

  const handleDeleteSelected = () => {
    const newRows = rows.filter((item) => !selected.includes(item.relKeyword));
    setRows(newRows);
    setSelected([]);
  };

  const csvData = React.useMemo(() => {
    return rows
      .filter((item) => selected.includes(item.relKeyword))
      .map((row: Data) => {
        return Object.values(row);
      });
  }, [rows, selected]);

  const handleChangeSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.relKeyword);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  //선택하기
  const handleClick = (event: React.MouseEvent<unknown>, keyword: string) => {
    const selectedIndex = selected.indexOf(keyword);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, keyword);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (keyword: string) => selected.indexOf(keyword) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).filter((item) =>
        item.relKeyword.includes(searchQuery)
      ),
    [rows, order, orderBy, searchQuery]
  );

  return (
    <Card sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", pt: 3 }}>
        {/* 검색 및 선택한 항목 삭제, 다운로드 */}
        <Grid container sx={{ p: 3 }} rowSpacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
            }}
          >
            <SearchInput
              value={searchQuery}
              width={"200px"}
              onChange={handleChangeSearchQuery}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack
              direction={"row"}
              justifyContent={"end"}
              gap={2}
              sx={{ position: "relative", top: "7px" }}
            >
              <Button
                startIcon={<HighlightOffIcon />}
                variant="contained"
                sx={{
                  height: "50px",
                  backgroundColor: MAIN_COLOR,
                  borderRadius: "30px",
                  color: "white",
                }}
                onClick={handleDeleteSelected}
              >
                선택한 항목 삭제
              </Button>

              <CSVLink
                data={csvData}
                headers={csvHeader}
                filename="대량 키워드 분석.csv"
              >
                <Button
                  startIcon={<SaveAltOutlinedIcon />}
                  sx={{
                    height: "50px",
                    backgroundColor: MAIN_COLOR,
                    borderRadius: "30px",
                    color: "white",
                  }}
                  variant="contained"
                >
                  선택한 항목 다운로드
                </Button>
              </CSVLink>
            </Stack>
          </Grid>
        </Grid>
        <EnhancedTableToolbar numSelected={selected.length} />

        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.relKeyword);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.relKeyword)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{ ...TableCellStyle(index), textAlign: "center" }}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{ ...TableCellStyle(index), textAlign: "left" }}
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <Link
                        to="/keyword-analysis"
                        state={{ keyword: row.relKeyword }}
                      >
                        <IconButton
                          sx={{
                            backgroundColor: getColor(0, 0.8),
                            color: "white",

                            mr: 2,
                          }}
                        >
                          <SearchIcon fontSize="small" />
                        </IconButton>
                      </Link>
                      {row.relKeyword}
                    </TableCell>
                    <TableCell sx={TableCellStyle(index)}>
                      {row.monthlyPcQcCnt.toLocaleString()}
                    </TableCell>
                    <TableCell sx={TableCellStyle(index)}>
                      {row.monthlyMobileQcCnt.toLocaleString()}
                    </TableCell>
                    <TableCell sx={TableCellStyle(index)}>
                      {row.monthlyTotalQcCnt.toLocaleString()}
                    </TableCell>
                    <TableCell sx={TableCellStyle(index)}>
                      {row.monthlyAvePcClkCnt.toLocaleString()}
                    </TableCell>
                    <TableCell sx={TableCellStyle(index)}>
                      {row.monthlyAveMobileClkCnt.toLocaleString()}
                    </TableCell>
                    <TableCell sx={TableCellStyle(index)}>
                      {row.monthlyAvePcCtr.toLocaleString()}
                    </TableCell>
                    <TableCell sx={TableCellStyle(index)}>
                      {row.monthlyAveMobileCtr.toLocaleString()}
                    </TableCell>
                    <TableCell sx={TableCellStyle(index)}>
                      {row.compIdx}
                    </TableCell>
                    <TableCell sx={TableCellStyle(index)}>
                      {row.plAvgDepth.toLocaleString()}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="subtitle1" color="grey" sx={{ p: 2 }}>
          {" "}
          전체 {rows.length}개
        </Typography>
      </Paper>
    </Card>
  );
};

export default KeywordTable;

const csvHeader = [
  "NO",
  "키워드",
  "월간 PC 검색량",
  "월간 모바일 검색량",
  "월간 총 검색량",
  "월평균 PC 클릭수",
  "월 평균 모바일 클릭수",
  "월 평균 PC 클릭율",
  "월 평균 모바일 클릭율",
  "경쟁정도",
  "월평균 노출 광고수",
];
