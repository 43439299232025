import { Card } from "@mui/material";
import TitleAndDescription from "../title-and-description/TitleAndDescription";
import { LineChart } from "@mui/x-charts/LineChart";
import { getColor } from "../../config/color-config";
import { ComponentStyle } from "./common";
import { cntDataType } from "../../service/keywordAnalysis/keywordAnalysisHooks";
import { generateDateArray } from "./SearchAmountGraph";
import { useMemo, useState, useEffect } from "react";

//월별 검색 비율
// const valueFormatter = (value: number) => `${value}%`;

interface WeeklySearchRatioProps {
  data: cntDataType | undefined;
}

type resultType = {
  [key: string]: [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number
  ];
};

const weeks = ["월", "화", "수", "목", "금", "토", "일"];

const WeeklySearchRatio: React.FC<WeeklySearchRatioProps> = ({ data }) => {
  const [YYYYMMDDArray, setYYYYMMDDArray] = useState<string[]>([]);

  useEffect(() => {
    setYYYYMMDDArray(generateDateArray(1, "year"));
  }, []);

  const customWeeks = useMemo(() => {
    const today = new Date();
    const current_week = today.getDay() - 1;
    const result: string[] = [];
    weeks.forEach((week, index) => {
      result.push(weeks[(index + current_week) % 7]);
    });

    return result;
  }, []);

  //비율을 구하기 위한 각각의 총값
  const { dataset, keywords } = useMemo(() => {
    let keywords: string[] = [];
    const dataset: resultType = {};

    if (!!data) {
      keywords = Object.keys(data);
    }

    if (!!data && !!keywords) {
      //자료 세팅
      keywords.forEach((keyword) => {
        dataset[keyword] = [0, 0, 0, 0, 0, 0, 0, 0];

        //자료 채워 넣기
        YYYYMMDDArray.forEach((item) => {
          const day = new Date(item);
          const week = day.getDay(); // 몇요일인지 구하기
          const value = !!data[keyword][item] ? data[keyword][item] : 0;
          dataset[keyword][week] += value;
          dataset[keyword][7] += value;
        });

        if (dataset[keyword][7] === 0) {
          dataset[keyword][7] = 1;
        }
      });
    }
    return { dataset, keywords };
  }, [data, YYYYMMDDArray]);

  return (
    <>
      <Card sx={{ ...ComponentStyle }}>
        <TitleAndDescription
          title={description.title}
          content={description.content}
        />
        {!!data && (
          <LineChart
            sx={{ pb: 2 }}
            xAxis={[
              {
                id: "Weeks", //
                data: customWeeks, // x축 범위 데이터
                scaleType: "point",
              },
            ]}
            yAxis={[
              {
                min: 0,
              },
            ]}
            series={keywords.map((keyword, i) => ({
              curve: "linear",
              id: keyword,
              label: keyword, // hover했을 때 나오는 그래프 이름

              data: dataset[keyword].slice(0, 7).map((item) => {
                return Math.round((item * 100) / dataset[keyword][7]);
              }),
              showMark: false, // data 마다 점찍기
              color: getColor(i), // 그래프 색상
              valueFormatter: (item) => item + "%",
            }))}
            height={400} // 높이
            margin={{ left: 50, top: 50, bottom: 80, right: 50 }}
            slotProps={{
              legend: {
                direction: "row",
                position: { vertical: "bottom", horizontal: "middle" },
                padding: 0,
              },
            }}
          />
        )}
      </Card>
    </>
  );
};

export default WeeklySearchRatio;

//임시 데이터들
const description = {
  title: "요일별 검색 비율",
  content: ["전체 기간에서 요일별 검색 비율을 나타냅니다."],
};
