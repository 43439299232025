
import { customAxios } from "../axios-config";

export const keywordAnalysisBulkAPI = {
  // 대량 키워드 정보
  getBulkKeywordTools: async(keywords: string) => {
    return customAxios.post("bulk_keyword_tools", { keywords }).then(res=>res.data);
  },
};

