import {
  Grid,
  Card,
  Box,
  Stack,
  Tooltip,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { MAIN_COLOR } from "../../config/color-config";
import emptyImage from "../../asset/image/empty_image.jpg";
import {
  useBannerListQuery,
  useCreateBannerMutation,
  useDeleteBannerMutation,
} from "../../service/admin/adminHook";
interface BannerListProps {}

const BannerList: React.FC<BannerListProps> = () => {
  const { data: bannerList } = useBannerListQuery();

  // const;

  return (
    <>
      <Grid
        container
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={""}
        sx={{ margin: "0 auto" }}
      >
        {[1, 2, 3, 4].map((item, i) => {
          let id = item;
          let image = "";
          let url = "";

          if (!!bannerList && !!bannerList[id]) {
            image = bannerList[id].image;
            url = bannerList[id].url;
          }
          return <BannerItem imgUrl={image} link={url} id={id + ""} key={i} />;
        })}
      </Grid>
    </>
  );
};

export default BannerList;

interface BannerItemProps {
  imgUrl: string; //사진경로
  id: string;
  link: string;
}
const BannerItem: React.FC<BannerItemProps> = ({ imgUrl, link, id }) => {
  const { mutate: createBanner } = useCreateBannerMutation();
  const [file, setFile] = React.useState<File | null>(null);
  const [open, setOpen] = React.useState(false);
  const [url, setUrl] = React.useState<string>("");
  const { mutate: deleteBanner } = useDeleteBannerMutation();

  const handleCreateBanner = () => {
    if (!!file && !!url) {
      const formData = new FormData();
      formData.append("id", id + "");
      formData.append("url", url); //눌렀을때 넘어갈 페이지
      formData.append("file", file); //파일명
      createBanner(formData);
    } else {
      alert("파일을 선택해 주세요");
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];

    if (selectedFile) {
      // 파일의 확장자를 가져옵니다.
      const fileExtension = selectedFile.name.split(".").pop()?.toLowerCase();

      // 허용하려는 확장자들을 배열로 정의합니다.
      const allowedExtensions = ["png", "jpeg", "jpg"];

      // 선택한 파일의 확장자가 허용되는 확장자인지 확인합니다.
      if (fileExtension && allowedExtensions.includes(fileExtension)) {
        // 허용된 확장자면 파일을 설정합니다.
        setFile(selectedFile);
      } else {
        // 허용되지 않는 확장자면 사용자에게 알립니다.
        e.target.value = "";
        alert("PNG, JPEG, JPG 형식의 이미지 파일만 업로드할 수 있습니다.");
        setFile(null);
      }
    } else {
      // 파일이 선택되지 않았을 경우에 대한 처리
      setFile(null);
    }
  };

  const handleChangeUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setUrl(link);
    setOpen(false);
  };

  const deleteButton = () => {
    if (window.confirm("삭제하시겠습니까?")) {
      deleteBanner(id + "");
    }
  };
  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
        lg={3}
        sx={{
          p: 1,
        }}
      >
        <Card
          sx={{
            aspectRatio: "2/1",
            backgroundImage: `url(${
              !!imgUrl ? imgUrl : emptyImage.toString()
            })`,
            backgroundSize: "100% 100%",
          }}
        >
          <Box
            sx={{
              backgroundColor: "rgba(0,0,0,0.4)",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              opacity: 0,
              transition: "0.2s",
              "&:hover": {
                opacity: 1,
              },
            }}
          >
            <Stack gap={2}>
              {!imgUrl ? (
                <Tooltip title="수정" placement="top">
                  <IconButton onClick={handleClickOpen}>
                    <EditIcon sx={{ color: "white" }} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="삭제" placement="top">
                  <IconButton onClick={deleteButton}>
                    <DeleteIcon sx={{ color: "white" }} />
                  </IconButton>
                </Tooltip>
              )}
              {!!imgUrl && (
                <Typography sx={{ color: "white" }}>{link}</Typography>
              )}
            </Stack>
          </Box>
        </Card>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">배너 수정</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" sx={{ mb: 2 }}>
            베너 리스트에 추가할 사진과 배너를 눌렀을 때 이동할 url을 입력해
            주세요. <br></br>(이미지는 jpg, jpeg, png 만 가능합니다.)
          </DialogContentText>
          <Stack gap={1}>
            <TextField fullWidth type="file" onChange={handleFileChange} />
            <TextField
              fullWidth
              type="text"
              value={url}
              onChange={handleChangeUrl}
              label="url"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              border: `1px solid ${MAIN_COLOR}`,
              color: MAIN_COLOR,
              width: "100px",
            }}
          >
            취소
          </Button>
          <Button
            onClick={() => {
              handleCreateBanner();
              handleClose();
            }}
            type="submit"
            autoFocus
            sx={{ backgroundColor: MAIN_COLOR, width: "100px" }}
            variant="contained"
          >
            등록
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
