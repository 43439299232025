import {
  Box,
  Divider,
  Menu,
  MenuList,
  Stack,
  Typography,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useState } from "react";

interface DescriptionProps {
  title: string;
  content: string[];
}

const TitleAndDescription: React.FC<DescriptionProps> = ({
  title,
  content,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack gap={1} alignItems="center" direction={"row"} sx={{p:3}}>
      
      <Box
        onClick={handleOpen}
        sx={{ ml: 1, display: "inline-block", cursor: "pointer" }}
      >
        <HelpOutlineIcon color="primary" fontSize="small" />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "center", horizontal: "right" }}
        sx={{
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.05))",
        }}
      >
        <Stack sx={{ p: 2, pb: 0 }}>
          <Typography variant="subtitle1" fontWeight={"bold"}>
            {title}
          </Typography>
          <Divider sx={{ mt: 1, mb: 2 }} />
          <MenuList sx={{ overflowY: "auto", maxHeight: "300px" }}>
            {content &&
              content.map((item, i) => (
                <div key={i}>
                  <Typography variant="caption">
                    {item}
                  </Typography>
                  <br />
                </div>
              ))}
          </MenuList>
        </Stack>
      </Menu>
      <Typography variant="subtitle1" fontWeight={"bold"}>
        {title}
      </Typography>
    </Stack>
  );
};

export default TitleAndDescription;
