import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { MAIN_COLOR } from "../../config/color-config";

interface SelectInputProps {
    width?:string;
    list :string[] | number[];
    value:string;
    onChange:(value:any) => void;    
    title?:string
}

const SelectInput:React.FC<SelectInputProps> = ({title="행개수",width = "160px",list,value,onChange}) =>{
    return(
        <>
        <FormControl sx={{mr:1}}>
              <Select
                displayEmpty
                sx={{
                  width,
                  borderRadius: "100px",
                  backgroundColor: "white",
                  border: `2px solid ${MAIN_COLOR}`,
                  height: "50px",
                }}
                startAdornment={
                  <Box
                    sx={{
                      position: "relative",
                      borderRadius: "30px",
                      backgroundColor: MAIN_COLOR,
                      color: "white",
                      px: 2,
                      p: 1,
                      right: "10px",
                    }}
                  >
                    {title}
                  </Box>
                }
                id="demo-simple-select"
                value={value}
                onChange={onChange}
              >
                {!!list && list.map((item,i)=>(
                <MenuItem value={item} key={i}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
        </>
    )
}

export default SelectInput;