import { Grid, Typography, Stack, Card, Divider } from "@mui/material";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import { getColor } from "../../config/color-config";
import { relDataType } from "../../service/keywordAnalysis/keywordAnalysisHooks";
import { useMemo } from "react";
import { blogCountType } from "../../service/blogKeywordAnalysis/blogKeywordAnalysisHooks";

const color = getColor(0);

interface KeywordAnalysisDataProps {
  searchVolum: relDataType | undefined;
  keyword: string;
  blogCount: blogCountType | undefined;
}

const KeywordAnalysisData: React.FC<KeywordAnalysisDataProps> = ({
  searchVolum,
  keyword,
  blogCount,
}) => {
  const { pc, mobile, total } = useMemo(() => {
    let pc = 0;
    let mobile = 0;
    let total = 0;
    if (!!searchVolum && !!keyword) {
      pc = Number(
        typeof searchVolum[keyword]!.monthlyPcQcCnt === "string"
          ? 10
          : searchVolum[keyword]!.monthlyPcQcCnt
      );
      mobile = Number(
        typeof searchVolum[keyword]!.monthlyMobileQcCnt === "string"
          ? 10
          : searchVolum[keyword]!.monthlyMobileQcCnt
      );
      total = pc + mobile;
    }

    return { pc, mobile, total };
  }, [searchVolum, keyword]);

  return (
    <>
      <Typography variant="h5" fontWeight={"bold"} sx={{ mb: 2 }}>
        키워드 분석 데이터
      </Typography>

      <Grid container spacing={3}>
        {/* 월간 검색량 */}
        {!!searchVolum && (
          <Grid item xs={12} md={4}>
            <Card>
              <Grid container sx={{ p: 3 }}>
                <Grid item xs={12}>
                  <Typography textAlign={"center"} fontWeight={"bold"}>
                    월간 검색량
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                </Grid>
                <Grid item xs={4}>
                  <Stack alignItems={"center"}>
                    <Typography variant="caption" color={"grey"}>
                      PC
                    </Typography>
                    <DesktopWindowsOutlinedIcon sx={{ color }} />
                    <Typography variant="body1" fontWeight={"bold"}>
                      {pc.toLocaleString()}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack alignItems={"center"}>
                    <Typography variant="caption" color={"grey"}>
                      Mobile
                    </Typography>
                    <PhoneAndroidOutlinedIcon sx={{ color }} />
                    <Typography variant="body1" fontWeight={"bold"}>
                      {mobile.toLocaleString()}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack alignItems={"center"}>
                    <Typography variant="caption" color={"grey"}>
                      Total
                    </Typography>
                    <ControlPointIcon sx={{ color }} />
                    <Typography variant="body1" fontWeight={"bold"}>
                      {total.toLocaleString()}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}

        {/* 월간 블로그 발행량 / 포화도 */}
        {!!blogCount && (
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <Grid container sx={{ p: 3 }}>
                <Grid item xs={12}>
                  <Typography textAlign={"center"} fontWeight={"bold"}>
                    월간 블로그 발행량 / 포화도
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                </Grid>
                <Grid item xs={6}>
                  <Stack alignItems={"center"}>
                    <Typography variant="caption" color={"grey"}>
                      Blog
                    </Typography>
                    <AssignmentOutlinedIcon sx={{ color }} />
                    <Typography variant="body1" fontWeight={"bold"}>
                      {blogCount.blogCount_today === 1000 &&
                      blogCount.blogCount_week === 7000
                        ? "3만 +"
                        : Math.round(
                            blogCount.blogCount_week * 4.35
                          ).toLocaleString()}
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={6} alignItems="center">
                  <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{ height: "100%" }}
                  >
                    <Typography variant="caption" color={"grey"}>
                      포화도
                    </Typography>
                    <Typography variant="body1" fontWeight={"bold"}>
                      {Number(
                        (
                          (blogCount.blogCount_week * 4.35 * 100) /
                          total
                        ).toFixed(1)
                      ).toLocaleString()}{" "}
                      %
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}

        {/* 전체 블로그 발행량 / 포화도 */}
        {!!blogCount && (
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <Grid container sx={{ p: 3 }}>
                <Grid item xs={12}>
                  <Typography textAlign={"center"} fontWeight={"bold"}>
                    전체 블로그 발행량 / 포화도
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                </Grid>
                <Grid item xs={6}>
                  <Stack alignItems={"center"}>
                    <Typography variant="caption" color={"grey"}>
                      Blog
                    </Typography>
                    <AssignmentOutlinedIcon sx={{ color }} />
                    <Typography variant="body1" fontWeight={"bold"}>
                      {blogCount.blogCount_all.toLocaleString()}
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={6} alignItems="center">
                  <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{ height: "100%" }}
                  >
                    <Typography variant="caption" color={"grey"}>
                      포화도
                    </Typography>
                    <Typography variant="body1" fontWeight={"bold"}>
                      {Number(
                        ((blogCount.blogCount_all / total) * 100).toFixed(1)
                      ).toLocaleString()}{" "}
                      %
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default KeywordAnalysisData;
