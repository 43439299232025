import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { SIDENAV_WIDTH } from "../side-nav/SideNav";
interface FooterProps{
  lgUp:boolean
}
const Footer: React.FC<FooterProps> = ({lgUp}) => {
  const smUp = useMediaQuery("(min-width:600px)");
  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          width: lgUp ? `calc(100% - ${lgUp? SIDENAV_WIDTH : 0})`:"100%",
          height: "140px",
          position: "absolute",
          bottom: "0px",
          py: 3,
          boxSizing: "border-box",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          color:"grey"
        }}
      >
        <Typography variant="subtitle1" fontWeight={"bold"} color={"#4a4a4a"}>
          (주)원정대
        </Typography>
        <Stack direction={"row"} sx={{flexWrap:"wrap"}} gap={1} >
          <Typography variant="body2">대표:정경석</Typography>
          <Typography variant="body2">
            주소:서울특별시 금천구 가산디지털1로 168, C동 1207호
          </Typography>
        </Stack>
        <Stack direction={!smUp ? "column":"row"} gap={!smUp ? 0:1}>
          <Typography variant="body2">전화번호 : 070-8872-4151</Typography>
          <Typography variant="body2">
            전자우편 : biz@tangomedia.co.kr
          </Typography>
          <Typography variant="body2">사업자등록번호 : 658-81-03203</Typography>
        </Stack>
        <Typography variant="body2">
          Copyright by 2022 탱고그룹 Co.Ltd. All right reserved.
        </Typography>
      </Box>
    </>
  );
};

export default Footer;
