import { Card, Stack, Typography, Box, Link } from "@mui/material";
import { getColor } from "../../config/color-config";
import CollectionsIcon from "@mui/icons-material/Collections";
import { blogInfoItemType } from "../../service/blogKeywordAnalysis/blogKeywordAnalysisHooks";
import naverBlogIcon from "../../asset/image/naver_blog_icon.png";
const labelStyle = (idx: number) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: getColor(idx < 3 ? 1 : idx < 6 ? 3 : 2),
  position: "relative",
  top: "-30px",
  width: "30px",
  height: "50px",
  zIndex: 5,
  mr: 2,
  borderRadius: "0px 0px 8px 8px",
  color: "white",
  fontWeight: "bold",
});

interface TopBlogsListProps {
  data: blogInfoItemType[] | undefined;
}

const TopBlogsList: React.FC<TopBlogsListProps> = ({ data }) => {
  return (
    <>
      <Typography variant="h5" fontWeight={"bold"} sx={{ mb: 2 }}>
        키워드 상위 블로그 리스트
      </Typography>
      {!!data && (
        <Stack gap={3}>
          {data.map((item, i) => (
            <Card sx={{ position: "relative", p: 3 }} key={i}>
              {/* 제목 url */}
              <Stack
                direction="row"
                justifyContent={"space-between"}
                sx={{ flexWrap: "wrap" }}
              >
                <Stack direction={"row"}>
                  <Box sx={labelStyle(i)}>{i + 1}</Box>

                  <Link href={item.link} underline="none" target="_blank">
                    <Typography fontWeight={600} color="black">
                      {item.title.replace(/<[^>]*>/g, "")}
                    </Typography>
                  </Link>
                </Stack>
                {!!item.isNaver ? (
                  <Link
                    target="_blank"
                    href={item.link.split("/").slice(0, 4).join("/")}
                    underline="none"
                  >
                    <Typography
                      variant="caption"
                      sx={{ display: "flex", alignItems: "center" }}
                      alignItems={"center"}
                    >
                      {item.bloggername}({item.link.split("/")[3]})
                      <img
                        src={naverBlogIcon}
                        height="20"
                        alt="블로그 이미지"
                        style={{ marginLeft: "5px" }}
                      />
                    </Typography>
                  </Link>
                ) : (
                  <Link
                    target="_blank"
                    href={item.link.split("/").slice(0, 3).join("/")}
                    underline="none"
                  >
                    <Typography variant="caption">
                      {`${item.bloggername}(${
                        item.link.replace("https://", "").split(".")[0]
                      })`}
                    </Typography>
                  </Link>
                )}
              </Stack>
              <Stack
                direction={"row"}
                sx={{ mt: 2, flexWrap: "wrap" }}
                justifyContent={"space-between"}
              >
                {/*  글자수 */}
                <Stack direction={"row"} alignItems="center" gap={3}>
                  <CollectionsIcon sx={{ color: getColor(0) }} />
                  <Stack>
                    <Typography variant="caption">글자 수</Typography>
                    <Typography fontWeight={"bold"}>
                      {item.TextCount.toLocaleString()}
                    </Typography>
                  </Stack>
                </Stack>
                {/* 이미지개수 */}
                <Stack direction={"row"} alignItems="center" gap={3}>
                  <CollectionsIcon sx={{ color: getColor(0) }} />
                  <Stack>
                    <Typography variant="caption">이미지 개수</Typography>
                    <Typography fontWeight={"bold"}>{item.imgCount}</Typography>
                  </Stack>
                </Stack>
                {/* 방문자 수 */}
                <Stack direction={"row"} alignItems="center" gap={3}>
                  <CollectionsIcon sx={{ color: getColor(0) }} />
                  <Stack>
                    <Typography variant="caption">방문자 수</Typography>
                    <Typography fontWeight={"bold"}>
                      {!!item.visitorCount
                        ? item.visitorCount.toLocaleString()
                        : "알수없음"}
                    </Typography>
                  </Stack>
                </Stack>
                {/* 키워드 반복 수 */}
                <Stack direction={"row"} alignItems="center" gap={3}>
                  <CollectionsIcon sx={{ color: getColor(0) }} />
                  <Stack>
                    <Typography variant="caption">키워드 반복 수</Typography>
                    <Typography fontWeight={"bold"}>
                      {item.repeatCount.toLocaleString()}
                    </Typography>
                  </Stack>
                </Stack>
                {/* 발행일 */}
                <Stack direction={"row"} alignItems="center" gap={3}>
                  <CollectionsIcon sx={{ color: getColor(0) }} />
                  <Stack>
                    <Typography variant="caption">발행일</Typography>
                    <Typography fontWeight={"bold"}>
                      {item.postdate.slice(0, 4)}-{item.postdate.slice(4, 6)}-
                      {item.postdate.slice(6, 8)}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          ))}
        </Stack>
      )}
    </>
  );
};

export default TopBlogsList;
