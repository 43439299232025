export const COLOR_LIST = [
  "#21a3fe", //파
  "#f25461", //빨
  "#01d79d", //초
  "#ffac06", //노
  "#a74cfb", //보
];

export const getColor = (index: number, opacity: number = 1): string => {
  if (index < 0 || index >= COLOR_LIST.length) {
    throw new Error("Invalid color index");
  }

  const color = COLOR_LIST[index];

  // hex 색상 코드를 rgba로 변환
  const hexToRgb = (hex: string): number[] =>
    hex.match(/\w\w/g)?.map((x) => parseInt(x, 16)) || [0, 0, 0];
  const [r, g, b] = hexToRgb(color);
  const rgbaColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;

  return rgbaColor;
};





export const MAIN_COLOR = "#170c88";
export const BACKGROUND_COLOR = "#F4F5F6";
export const DESCRIPTION_COLOR = "#1e1e1e";
