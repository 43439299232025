import {
  Box,
  Stack,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Typography,
  Menu,
  MenuList,
  MenuItem,
  Divider,
  Card,
  IconButton,
  Pagination,
  Grid,
  SelectChangeEvent,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TitleAndDescription from "../title-and-description/TitleAndDescription";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import * as React from "react";
import { visuallyHidden } from "@mui/utils";
import { MAIN_COLOR, getColor } from "../../config/color-config";
import { ComponentStyle } from "./common";
import { CSVLink } from "react-csv";
import { relDataType } from "../../service/keywordAnalysis/keywordAnalysisHooks";
import SearchInput from "../input/SearchInput";
import SelectInput from "../input/SelectInput";
interface Data {
  keyword: string;
  pc: number;
  mobile: number;
  total: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Data;
  label: string;
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              p: 1,
              pr: 0,
              whiteSpace: "nowrap",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              style={{
                color: orderBy === headCell.id ? getColor(0) : "black",
                fontWeight: orderBy === headCell.id ? "bold" : "normal",
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface RelationKeywordTableProps {
  data: relDataType | undefined;
  onSubmit: (text: string) => void;
}

//검색량 테이블 ----------------------------------------------------------------------------------------------------------------------------------------------------------------
const RelationKeywordTable: React.FC<RelationKeywordTableProps> = ({
  data,
  onSubmit,
}) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("keyword");
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [copyMenuOpen, setCopyMenuOpen] = React.useState<null | HTMLElement>(
    null
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const handleCopyMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setCopyMenuOpen(event.currentTarget);
  };
  const handleCopyMenuClose = () => {
    setCopyMenuOpen(null);
  };

  const handleChangeSearchQuery = (e: any) => {
    const { value } = e.target;
    setSearchQuery(value);
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //키워드 복사
  const handleCoypClipBoard = (e: any) => {
    const { id } = e.target;
    let value = "";
    if (id === "1") {
      value = rows.map((item) => item.keyword).join(" ");
    } else if (id === "2") {
      value = rows.map((item) => item.keyword).join(",");
    } else if (id === "3") {
      value = rows.map((item) => "#" + item.keyword).join(" ");
    } else {
      value = rows.map((item) => "#" + item.keyword).join(",");
    }
    navigator.clipboard
      .writeText(value)
      .then(() => alert("클립보드에 복사되었습니다."));
  };

  // 줄 데이터 가공
  const rows = React.useMemo(() => {
    if (!!data) {
      return Object.keys(data).map((keyword) => {
        const pc =
          typeof data[keyword].monthlyPcQcCnt === "number"
            ? data[keyword].monthlyPcQcCnt
            : 10;
        const mobile =
          typeof data[keyword].monthlyMobileQcCnt === "number"
            ? data[keyword].monthlyMobileQcCnt
            : 10;
        const total = Number(pc) + Number(mobile);
        return {
          keyword,
          pc,
          mobile,
          total,
        };
      });
    } else {
      return [];
    }
  }, [data]);

  const cellStyle = (index: number) => {
    return {
      backgroundColor: index % 2 === 0 ? "#fafafa" : "white",
      p: 1,
      pr: 0,
    };
  };

  //실제로 보이는 줄
  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy))
        .filter((item) => item.keyword.includes(searchQuery))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, searchQuery, rows]
  );

  //엑셀 데이터
  const csvData = React.useMemo(() => {
    return rows
      .filter((item) => item.keyword.includes(searchQuery))
      .map((item) => {
        return [item.keyword, item.pc, item.mobile, item.total];
      });
  }, [searchQuery, rows]);

  return (
    <Card sx={ComponentStyle}>
      <TitleAndDescription
        title={description.title}
        content={description.content}
      />
      {!!data && (
        <Grid container sx={{ width: "100%", p: 2 }} rowSpacing={1}>
          {/* 행변경 & 검색창 */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <SelectInput
              list={[15, 30, 50]}
              value={rowsPerPage + ""}
              onChange={handleChangeRowsPerPage}
            />
            <SearchInput
              width="200px"
              onChange={handleChangeSearchQuery}
              value={searchQuery}
            />
          </Grid>

          {/* 연관키워드 복사 & 엑셀 다운로드 */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
            }}
          >
            <CSVLink
              data={csvData}
              headers={csvHeader}
              filename="연관키워드.csv"
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: MAIN_COLOR,
                  height: "50px",
                  borderRadius: "30px",
                }}
              >
                <SaveAltOutlinedIcon />
                엑셀 다운로드
              </Button>
            </CSVLink>
            <Button
              variant="contained"
              sx={{
                backgroundColor: MAIN_COLOR,
                height: "50px",
                borderRadius: "30px",
                mr: 1,
              }}
              onClick={handleCopyMenuOpen}
            >
              <ArrowDropDownIcon />
              연관키워드 복사
            </Button>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={copyMenuOpen}
              open={!!copyMenuOpen}
              onClose={handleCopyMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Typography
                variant="subtitle1"
                textAlign={"center"}
                fontWeight={"bold"}
              >
                형식을 선택해 주세요
              </Typography>
              <Divider />
              <MenuList sx={{ width: "250px" }}>
                <MenuItem id="1" onClick={handleCoypClipBoard}>
                  키워드1 키워드2 키워드3
                </MenuItem>
                <MenuItem id="2" onClick={handleCoypClipBoard}>
                  키워드1,키워드2,키워드3
                </MenuItem>
                <MenuItem id="3" onClick={handleCoypClipBoard}>
                  #키워드1#키워드2#키워드3
                </MenuItem>
                <MenuItem id="4" onClick={handleCoypClipBoard}>
                  #키워드1,#키워드2,#키워드3
                </MenuItem>
              </MenuList>
            </Menu>
          </Grid>
          <Grid item xs={12} sx={{ width: "100%", my: 2 }}>
            <Paper>
              <TableContainer>
                <Table aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell
                            sx={{ ...cellStyle(index), whiteSpace: "nowrap" }}
                            component="th"
                            id={labelId}
                            scope="row"
                          >
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              gap={2}
                            >
                              <IconButton
                                onClick={() => {
                                  onSubmit(row.keyword);
                                }}
                                sx={{
                                  backgroundColor: getColor(0),
                                  color: "white",
                                }}
                              >
                                <SearchIcon fontSize="small" />
                              </IconButton>
                              {row.keyword}
                            </Stack>
                          </TableCell>
                          <TableCell sx={cellStyle(index)} align="left">
                            {row.pc.toLocaleString()}
                          </TableCell>
                          <TableCell sx={cellStyle(index)} align="left">
                            {row.mobile.toLocaleString()}
                          </TableCell>
                          <TableCell sx={cellStyle(index)} align="left">
                            {row.total.toLocaleString()}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* 페이지 네이션 */}
              <Box
                sx={{
                  p: 4,
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Typography variant="body2">
                  전체 {visibleRows.length}개 중 {1 + page * rowsPerPage}~
                  {Math.min(
                    rows.filter((row) => row.keyword.includes(searchQuery))
                      .length,
                    (page + 1) * rowsPerPage
                  )}
                </Typography>
                <Pagination
                  count={Math.ceil(
                    rows.filter((item) => item.keyword.includes(searchQuery))
                      .length / rowsPerPage
                  )}
                  onChange={handleChangePage}
                  color="primary"
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};

export default RelationKeywordTable;

//임시 데이터들
const description = {
  title: "연관 키워드",
  content: [
    "연관키워드 검량을 표로 나타냅니다.",
    "연관 키워드 목록을 다양한 형태로 복사할 수 있습니다.",
    "엑셀로 다운받기도 가능합니다.",
  ],
};

const headCells: readonly HeadCell[] = [
  {
    id: "keyword",
    label: "키워드",
  },
  {
    id: "pc",
    label: "PC 검색량",
  },
  {
    id: "mobile",
    label: "모바일 검색량",
  },
  {
    id: "total",
    label: "총 검색량",
  },
];

const csvHeader = ["키워드", "PC 검색량", "모바일 검색량", "총 검색량	"];
