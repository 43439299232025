import { customAxios } from "../axios-config";

export const blogKeywordAnalysisAPI = {
  // 섹션 배치 순서
  getSection: async(keywords: string) => {
    return customAxios.post("section", { keywords }).then(res=>res.data);
  },

  // 블로그 정보 
  getBlogInfo: async(keywords: string) => {
    return customAxios.post("blog_info", { keywords }).then(res=>res.data);
  },

  // 블로그 카운트 블로그 발행량
  getBlogCount: async(keywords: string) => {
    return customAxios.post("blog_count", { keywords }).then(res=>res.data[0]);
  },

};

