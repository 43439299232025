import CardContainer from "../component/keyword-combiner/CardContainer";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CombineContainer from "../component/keyword-combiner/CombineContainer";
import { Grid, Typography } from "@mui/material";
import { useMemo, useState, useEffect } from "react";
import CombineResult from "../component/keyword-combiner/CombineResult";
import { Item } from "../component/keyword-combiner/CardContainer";
import { Helmet } from "react-helmet";
import { DESCRIPTION_COLOR, MAIN_COLOR } from "../config/color-config";
import { CustomIcon } from "../component/side-nav/config";
import toolIcon from "../asset/image/tool-icon-black.png";
const KeywordCombiner: React.FC = () => {
  const [selected, setSelected] = useState<boolean[]>(Array(64).fill(false)); // 어떤 조합을 선택했는지 확인하는 함수
  const [combineList, setCombineList] = useState<number[][]>([]); //조합이 들어있는 함수
  const [combineResult, setCombineResult] = useState<string[]>([]);
  const [keywordSpace, setKeywordSpace] = useState<boolean>(false);
  const [keywords, setKeywords] = useState<Item[]>([
    {
      id: 1,
      text: "",
    },
    {
      id: 2,
      text: "",
    },
    {
      id: 3,
      text: "",
    },
    {
      id: 4,
      text: "",
    },
  ]);

  //키워드 사이에 공백 넣기
  const handleKeywordSpace = () => {
    setKeywordSpace(!keywordSpace);
  };

  useEffect(() => {
    const array1 = combinePattern(1);
    const array2 = combinePattern(2);
    const array3 = combinePattern(3);
    const array4 = combinePattern(4);
    setCombineList([...array1, ...array2, ...array3, ...array4]);
  }, []);
  //전부 체크
  const handleCheckAll = () => {
    setSelected(Array(64).fill(true));
  };

  //전부 체크 해제
  const handleUnCheckAll = () => {
    setSelected(Array(64).fill(false));
  };

  //하나만 체크 하기
  const handleCheckOne = (idx: number) => {
    setSelected((prevSelected) => {
      const newSelected = [...prevSelected];
      newSelected[idx] = !newSelected[idx];
      return newSelected;
    });
  };

  //모든 키워드 카운트하기
  const keywordCount = useMemo(() => {
    let result = 0;

    for (let i = 0; i < 4; i++) {
      result += keywords[i].text
        .split("\n")
        .filter((item) => item !== "").length;
    }

    return result;
  }, [keywords]);

  //키워드 조합하기
  const handleCombineKeyword = () => {
    setCombineResult(() => {
      const result = combineKeyword(
        keywords,
        selected,
        combineList,
        keywordSpace
      );
      return result;
    });
  };

  return (
    <>
      <Helmet>
        <meta
          name="키워드 조합기"
          content="상상할 수 있는 모든 키워드를 조합을 구성할 수 있습니다."
        />
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {/* 제목 */}
          <Typography fontWeight={900} variant="h5" color={MAIN_COLOR}>
            <CustomIcon alt="tool-icon" src={toolIcon} />
            키워드 조합기
          </Typography>
          <Typography
            variant="body1"
            fontWeight={600}
            color={DESCRIPTION_COLOR}
          >
            상상할 수 있는 모든 키워드를 조합을 구성할 수 있습니다.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography textAlign={"right"} fontWeight={"bold"}>
            입력한 총 키워드 개수 : {keywordCount}/200
          </Typography>
          <Typography textAlign={"right"} variant="body2">
            카드는 화살표 버튼으로 이동이 가능합니다.
          </Typography>
        </Grid>
        {/* 키워드 입력 */}
        <Grid item xs={12}>
          <DndProvider backend={HTML5Backend}>
            <CardContainer cards={keywords} setCards={setKeywords} />
          </DndProvider>
        </Grid>
        <Grid item xs={12} md={8}>
          <CombineContainer
            selected={selected}
            handleCheckAll={handleCheckAll}
            handleCheckOne={handleCheckOne}
            handleUnCheckAll={handleUnCheckAll}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CombineResult
            keywordSpace={keywordSpace}
            combineResult={combineResult}
            handleCombineKeyword={handleCombineKeyword}
            handleKeywordSpace={handleKeywordSpace}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default KeywordCombiner;

//숫자 조합 만들기
export function combinePattern(len: number) {
  let visited = [false, false, false, false];
  let result: number[][] = [];
  let temp: number[] = [];
  function func(depth: number) {
    if (depth === len) {
      result.push([...temp]);
      return;
    }

    for (let i = 1; i < 5; i++) {
      if (!visited[i - 1]) {
        visited[i - 1] = true;
        temp.push(i);
        func(depth + 1);
        temp.pop();
        visited[i - 1] = false;
      }
    }
  }

  func(0);
  return result;
}

function combineKeyword(
  keywords: Item[],
  selected: boolean[],
  combineList: number[][],
  keywordSpace: boolean
) {
  let result: string[] = [];
  let temp: string[] = [];

  //result 에 combine대로 조합을 넣는함수
  function func(idx: number, combine: number[]) {
    if (combine.length === idx) {
      result.push(temp.join(keywordSpace ? " " : ""));
      return;
    }

    // id 가 idx인 배열 즉 [1,4,2,3] 조합일 때 id가 차례대로 1,4,2,3 인 배열을 찾는것
    const list = keywords[combine[idx] - 1].text
      .split("\n")
      .filter((item) => item !== "");
    for (let i = 0; i < list.length; i++) {
      temp.push(list[i]);
      func(idx + 1, combine);
      temp.pop();
    }
  }

  for (let i = 0; i < selected.length; i++) {
    if (selected[i]) {
      func(0, combineList[i]);
    }
  }
  return result;
}
