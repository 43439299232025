import { customAxios } from "../axios-config";

export const AuthAPI = {
  async sendEmail(username: string) {
    const body = {
      username,
    };
    const API = "send_email";
    return await customAxios.post(API, body).then((res) => res);
  },

  async checkId(username: string) {
    const API = "id_check";
    const body = {
      username,
    };
    return await customAxios.post(API, body).then((res) => res);
  },
  async checkCode(username: string, code: string) {
    const API = "number_check";
    const body = {
      username,
      code,
    };
    return await customAxios.post(API, body).then((res) => res);
  },
};
