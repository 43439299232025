import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          // 원하는 일괄적인 스타일 변경
          // border: '0.5px solid grey',
          borderRadius:"16px",
          boxShadow:"0px 0px 10px rgba(255, 255, 255, .4);",
          p:6,
        },
      },
    },
    MuiButton:{
      
    }
  },
});
