import { Box, Stack, Typography, Button, OutlinedInput } from "@mui/material";
import KeywordTable from "../component/keyword-analysis-bulk/KeywordTable";
import { DESCRIPTION_COLOR, MAIN_COLOR } from "../config/color-config";
import { ChangeEvent, useMemo, useState } from "react";
import { useBulkKeywordToolsMutation } from "../service/keywordAnalysisBulk/keywordAnalysisBulkHooks";
import Loading from "../component/loading/Loading";
import { reg } from "../config/regular-expression";
import { Helmet } from "react-helmet";
import SearchIcon from "@mui/icons-material/Search";
import { CustomIcon } from "../component/side-nav/config";
import searchIcon from "../asset/image/keyword-icon-main.png";

const MAX_KEYWORD_CNT = 50; // 최대 몇개까지 입력받을 수 있는지 표시

const KeywordAnalysisBulk: React.FC = () => {
  const [keywords, setKeywords] = useState<string>("");
  const { data, mutateAsync, isPending } = useBulkKeywordToolsMutation();

  //입력받기
  const handleChangeKeywrods = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (keywordCnt < MAX_KEYWORD_CNT || value.length < keywords.length) {
      const keywordsWithoutSpaces = value.toUpperCase().replace(" ", ""); // 공백 문자를 제거합니다.
      setKeywords(keywordsWithoutSpaces); // 띄어쓰기가 제거된 문자열로 상태를 업데이트합니다.
    }
  };

  //키워드 가공해서 제공하기
  //매개변수를 받는 이유는 연관 키워드 테이블에서 사용할수 있게 하기 위함
  const onSubmit = async () => {
    if (!!keywords) {
      const keywordArray: string[] = keywords
        .replaceAll(" ", "")
        .replaceAll(",", "")
        .split("\n") //배열로 변경
        .map((item) => item.replace(reg, "")) //공백 제거 특수문자 제거
        .filter(Boolean) //빈값 삭제
        .slice(0, 50); // 1~5번째 까지만

      let flag = true;
      keywordArray.forEach((item) => {
        if (item.length > 20) {
          flag = false;
        }
      });

      if (flag) {
        const query: string = keywordArray.join(",");
        setKeywords(keywordArray.join("\n"));
        //함수 실행
        await mutateAsync(query);
      } else {
        alert("키워드는 20자 이내로 입력해주세요. \n키워드의 구분은 Enter로 해주세요.");
      }
    }
  };

  //키워드 개수
  const keywordCnt = useMemo(() => {
    return keywords.split("\n").filter((item) => item !== "").length;
  }, [keywords]);

  return (
    <>
      <Helmet>
        <meta
          name="키워드 상세 분석"
          content="키워드의 기본정보, 검색량, 검색 비율까지\n 한눈에 확인할 수 있습니다."
        />
      </Helmet>
      <Loading open={isPending} />
      <Stack gap={4}>
        {/* 제목 */}
        <Box>
          <Typography fontWeight={900} variant="h5" color={MAIN_COLOR}>
            <CustomIcon src={searchIcon} alt="search-icon" top="4px" />
            대량 키워드 분석
          </Typography>
          <Typography
            variant="body1"
            fontWeight={600}
            color={DESCRIPTION_COLOR}
          >
            대량의 키워드의 검색량, 클릭수, 클릭률을 한눈에 분석할 수 있습니다.
          </Typography>
        </Box>
        <Box>
          <OutlinedInput
            id="standard-multiline-flexible"
            multiline
            rows={10}
            value={keywords}
            onChange={handleChangeKeywrods}
            placeholder={`한 줄에 키워드 하나씩 입력하세요.(최대 ${MAX_KEYWORD_CNT}개)`}
            sx={{
              width: "100%",
              borderRadius: "30px",
              backgroundColor: "white",
              border: `2px solid ${MAIN_COLOR}`,
            }}
          />
          <Box textAlign={"right"}>
            <Typography variant="body1" sx={{ my: 1 }}>
              키워드 개수 : {keywordCnt} / {MAX_KEYWORD_CNT}
            </Typography>
            <Button
              onClick={onSubmit}
              variant="contained"
              startIcon={<SearchIcon />}
              sx={{
                color: "white",
                mt: 1,
                width: "180px",
                height: "50px",
                backgroundColor: MAIN_COLOR,
                fontSize: "20px",
                borderRadius: "30px",
              }}
            >
              조회하기
            </Button>
          </Box>
        </Box>
        <Box>
          <KeywordTable data={data} />
        </Box>
      </Stack>
    </>
  );
};

export default KeywordAnalysisBulk;
