import { useState } from "react";
import { shoppingItemType } from "../../service/shoppingKeywordAnalysis/shoppingKeywordAnalysisHooks";
import { Box, Card, Stack, Typography } from "@mui/material";
import { DESCRIPTION_COLOR, getColor } from "../../config/color-config";
import React from "react";

interface topItemProps {
  data: shoppingItemType;
  i: number;
}

const TopItem: React.FC<topItemProps> = ({ data, i }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMoveToLink = () => {
    const id = data.link.split("?id=")[1];
    if (data.productType + "" === "1") {
      window.open(`https://search.shopping.naver.com/catalog/${id}`, "_blank");
    }else{
      window.open(`https://search.shopping.naver.com/product/${id}`, "_blank");
    }
  };
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        onClick={handleMoveToLink}
        component="button"
        key={data.title}
        onMouseOver={handleOpen}
        onMouseLeave={handleClose}
        sx={{
          border: "none",
          // cursor: "pointer",
          width: "32px",
          backgroundColor: getColor(0, i < 5 ? 1 : 0.6),
          color: "white",
          textAlign: "center",
          lineHeight: "15px",
          p: 1,
          borderRadius: "8px",
          fontWeight: "bold",
        }}
      >
        {i + 1}
      </Box>
      {open && (
        <Card
          sx={{
            width: "450px",
            position: "absolute",
            zIndex: 10000,
            right: 0,
            p: 3,
            border: `1px solid ${DESCRIPTION_COLOR}`,
          }}
        >
          <Stack direction={"row"} gap={3}>
            <img src={data.image} alt="블로그 이미지" width={100} />
            <Stack>
              {data.brand && (
                <Typography
                  style={{
                    color: getColor(0),
                  }}
                  fontWeight={"bold"}
                >
                  [{data.brand}]
                </Typography>
              )}
              <Typography variant="subtitle1" fontWeight={"bold"}>
                {data.title.replace(/(<([^>]+)>)/gi, "")}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ color: "red" }}
                fontWeight={"bold"}
              >
                {Number(data.lprice).toLocaleString()} 원
              </Typography>
              <Typography variant="caption" color="grey">{`${data.category1}
                ${!!data.category2 ? ">" + data.category2 : ""}
                ${!!data.category3 ? ">" + data.category3 : ""}
                ${!!data.category4 ? ">" + data.category4 : ""}
                `}</Typography>
            </Stack>
          </Stack>
        </Card>
      )}
    </Box>
  );
};

export default React.memo(TopItem);
