import { Box, Card } from "@mui/material";
import { getColor } from "../../config/color-config";
import TitleAndDescription from "../title-and-description/TitleAndDescription";
import { ComponentStyle } from "./common";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { keywordInfoType } from "../../service/keywordAnalysis/keywordAnalysisHooks";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
const tableCellStyle = {
  width: "18%",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

interface KeywordInfoProps {
  data: keywordInfoType[] | undefined;
  mdUp: boolean;
}

//키워드 기본 정보
const KeywordInfo: React.FC<KeywordInfoProps> = ({ data, mdUp }) => {
  return (
    <Card sx={mdUp ? { height: "409px" } : {}}>
      <TitleAndDescription
        title={description.title}
        content={description.content}
      />
      <Box component="div" sx={{ ...ComponentStyle }}>
        {!!data && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>키워드</TableCell>
                  <TableCell align="center" sx={tableCellStyle}>
                    성인
                  </TableCell>
                  <TableCell align="center" sx={tableCellStyle}>
                    제한된 키워드
                  </TableCell>
                  <TableCell align="center" sx={tableCellStyle}>
                    시즌
                  </TableCell>
                  <TableCell align="center" sx={tableCellStyle}>
                    적은 검색량
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, i) => (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={cellStyle}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          fontWeight: "bold",
                        }}
                      >
                        <Box
                          sx={{
                            width: "5px",
                            height: "30px",
                            backgroundColor: getColor(i),
                            mr: 2,
                            borderRadius: "8px",
                          }}
                        ></Box>
                        {row!.keyword}
                      </Box>
                    </TableCell>
                    <TableCell align="center" sx={cellStyle}>
                      {row!.isAdult ? (
                        <ReportGmailerrorredIcon
                          fontSize="small"
                          sx={{ color: getColor(1) }}
                        />
                      ) : (
                        <CheckCircleOutlineIcon
                          fontSize="small"
                          sx={{ color: "limegreen" }}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center" sx={cellStyle}>
                      {row!.isRestricted ? (
                        <ReportGmailerrorredIcon
                          fontSize="small"
                          sx={{ color: getColor(1) }}
                        />
                      ) : (
                        <CheckCircleOutlineIcon
                          fontSize="small"
                          sx={{ color: "limegreen" }}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center" sx={cellStyle}>
                      {row!.isSeason ? (
                        <ReportGmailerrorredIcon
                          fontSize="small"
                          sx={{ color: getColor(1) }}
                        />
                      ) : (
                        <CheckCircleOutlineIcon
                          fontSize="small"
                          sx={{ color: "limegreen" }}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center" sx={cellStyle}>
                      {row!.isLowVolume ? (
                        <ReportGmailerrorredIcon
                          fontSize="small"
                          sx={{ color: "limegreen" }}
                        />
                      ) : (
                        <CheckCircleOutlineIcon
                          fontSize="small"
                          sx={{ color: "limegreen" }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Card>
  );
};
export default KeywordInfo;

const description = {
  title: "키워드 기본 정보",
  content: [
    "키워드의 성인 키워드 여부와 제한된 키워드, 시즌, 적은검색량 여부입니다.",
  ],
};

const cellStyle = {
  p: 1.5,
};
