import { useMutation } from "@tanstack/react-query";
import { UserAPI, SignUpType } from "./userAxios";
import { useNavigate } from "react-router-dom";
import {
  checkIsLogin,
  unCheckIsLogin,
  isLoginState,
  rememberMeState,
  clearUserInfo,
  openLoginModalState,
  openLoginModal,
} from "../../store";

// 첫 번째 매개변수: mutation 성공 시 반환될 데이터의 타입
// 두 번째 매개변수: mutation 실패 시 반환될 에러의 타입
// 세 번째 매개변수: mutation 함수의 매개변수 타입

// 로그인 함수
export const useLoginMutation = () => {
 const {close} = openLoginModalState()
  return useMutation<any, Error, { username: string; password: string }>({
    mutationFn: ({ username, password }) => UserAPI.login(username, password),
    onSuccess: async (data: any) => {
      if (data.data === "ok") {
        close()
        await UserAPI.userInfo();
        checkIsLogin(); // 로그인했다는 표시
      } else {
        alert("잘못된 ID 또는 비밀번호 입니다.");
      }
    },
    onError: (err) => {
      // console.log("로그인 onError >>> ", err);
    },
  });
};

//회원가입함수
export const useSignUpMutation = () => {
  const navigate = useNavigate();
  return useMutation<any, Error, SignUpType>({
    mutationFn: (data) => UserAPI.signUp(data),
    onSuccess: (data: any) => {
      if (data.data) {
        console.log(data)
        alert("회원가입이 성공적으로 완료되었습니다! 로그인 후 사용해주세요");
        openLoginModal()
        navigate("/");
      } else {
        alert(
          "죄송합니다. 회원가입이 실패했습니다. 잠시 후 다시 시도해주세요."
        );
      }
    },
    onError: (err) => {
      // console.log("signUp 함수 error =>", err);
    },
  });
};

//로그아웃
export const useLogoutMutation = () => {
  const { unCheck: unCheckRememberMe } = rememberMeState();
  const navigate = useNavigate()
  return useMutation({
    mutationFn: () => UserAPI.logout(),
    onSuccess: (data: any) => {
      clearUserInfo();
      unCheckIsLogin(); // 로그인 상태 제거
      unCheckRememberMe(); // 로그아웃하면 로그인 기억도 더이상 소용이 없기 때문에 false;
      // window.location.href = "https://셀러원정대.com";
      navigate("/")
    },
    onError: (err) => {
      // console.log("로그아웃 onError >>> ", err);
    },
  });
};

//자동로그인 함수
export const useAutoLogin = () => {
  const { mutate: logout } = useLogoutMutation();
  const { isLogin, check: checkIsLogin } = isLoginState();
  const { rememberMe } = rememberMeState();

  const autoLogin = async () => {
    if (isLogin || rememberMe) {
      // 둘중 하나라도 true
      await UserAPI.userInfo();
      checkIsLogin(); // rememberMe만 true일 경우를 대비
    } else {
      logout(); // 로그아웃
    }
  };
  return autoLogin;
};
