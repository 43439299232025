import { useMutation } from "@tanstack/react-query";
import { blogKeywordAnalysisAPI } from "./blogKeywordAnalysisAxios";

// 첫 번째 매개변수: mutation 성공 시 반환될 데이터의 타입
// 두 번째 매개변수: mutation 실패 시 반환될 에러의 타입
// 세 번째 매개변수: mutation 함수의 매개변수 타입




// 섹션 배치 순서
export const useKeywordSectionMutation = () => {
    return useMutation<sectionType,Error,string>({
      mutationFn: (keywords: string) =>
      blogKeywordAnalysisAPI.getSection(keywords),
      onSuccess: (data: any) => {
        return data;
      },
      onError: (err) => {
        // console.log("키워드 섹션의 onError >>> ", err);
      },
    });
  };

  // 블로그 정보 
export const useBlogInfoMutation = () => {
  return useMutation<blogInfoType,Error,string>({
    mutationFn: (keywords: string) =>
    blogKeywordAnalysisAPI.getBlogInfo(keywords),
    onSuccess: (data: any) => {
      return data;
    },
    onError: (err) => {
      // console.log("블로그 정보 의 onError >>> ", err);
    },
  });
};

// 블로그 발행량 카운트 
export const useBlogCountMutation = () => {
  return useMutation<blogCountType,Error,string>({
    mutationFn: (keywords: string) =>
    blogKeywordAnalysisAPI.getBlogCount(keywords),
    onSuccess: (data: any) => {
      return data;
    },
    onError: (err) => {
      // console.log("블로그 발행량 카운트 의 onError >>> ", err);
    },
  });
};


  export type sectionType={
    pc:string[],
    mo:string[]
  }


  export type blogInfoType={
    relData:{},
    total:number,
    items:blogInfoItemType[]
  }

  export type blogInfoItemType={
    isNaver:number,
    imgCount:number,
    TextCount:number,
    repeatCount:number,
    visitorCount:number,
    title:string,
    link:string,
    bloggername:string,
    postdate:string 
  }


  export type blogCountType = {
    keyword:string,
    blogCount_week:number,
    blogCount_today:number,
    blogCount_all:number,
  }