import { Card } from "@mui/material";
import TitleAndDescription from "../title-and-description/TitleAndDescription";
import { getColor } from "../../config/color-config";
import { LineChart } from "@mui/x-charts/LineChart";
import { ComponentStyle } from "./common";
import { ageRateType } from "../../service/keywordAnalysis/keywordAnalysisHooks";
import { useMemo } from "react";

//월별 검색 비율
const valueFormatter = (value: number) => `${value}%`;

interface AgeSearchRatioProps {
  data: ageRateType | undefined;
  keywords: string[];
}

const AgeSearchRatio: React.FC<AgeSearchRatioProps> = ({ data, keywords }) => {
  const dataset = useMemo(() => {
    if (!!data && !!keywords) {
      return keywords.map((keyword) => {
        return {
          keyword,
          data: data[keyword].map((item) => Math.round(item * 100)),
        };
      });
    } else {
      return [];
    }
  }, [data, keywords]);

  return (
    <>
      <Card sx={{ ...ComponentStyle}}>
        <TitleAndDescription
          title={description.title}
          content={description.content}
        />
        {!!data && (
          <LineChart
          sx={{pb:2}}
            xAxis={[
              {
                id: "ages", //
                data: ages, // x축 범위 데이터
                scaleType: "point",
                // valueFormatter: (data) => data,
              },
            ]}
            yAxis={[{ min: 0}]}
            series={dataset.map((item, i) => {
              return {
                curve: "linear",
                id: item.keyword,
                label: item.keyword, // hover했을 때 나오는 그래프 이름
                data: item.data, //입력할 데이터
                showMark: false, // data 마다 점찍기
                color: getColor(i),
                valueFormatter,
              };
            })}
            height={400} // 높이
            margin={{ left: 50, top: 50, bottom: 80, right: 50 }}
            slotProps={{
              legend: {
                direction: "row",
                position: { vertical: "bottom", horizontal: "middle" },
                padding: 0,
              },
            }}
          />
        )}
      </Card>
    </>
  );
};

export default AgeSearchRatio;

//임시 데이터들
const description = {
  title: "연령별 검색 비율",
  content: ["전체 기간에서 연령별 검색 비율을 나타냅니다."],
};

const ages = ["10대", "20대", "30대", "40대", "50대", "60대이상"];
