import { Box, Button, OutlinedInput } from "@mui/material";
import { MAIN_COLOR } from "../../config/color-config";
import SearchIcon from "@mui/icons-material/Search";

interface SearchInputProps {
  onChange: (value: any) => void;
  onSubmit?: (value?: any) => void;
  width?: string;
  height?: string;
  value?: string;
  placeholder?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  value,
  onChange,
  onSubmit = null,
  width = "100%",
  height = "50px",
  placeholder = "",
}) => {
  return (
    <Box component="form" onSubmit={(e:any)=>{
      e.preventDefault();
      if(onSubmit){
        onSubmit()
      }
      }}>
      <OutlinedInput
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        sx={{
          width,
          height,
          
          borderRadius: "30px",
          border: `2px solid ${MAIN_COLOR}`,
          backgroundColor: "white",
        }}
        endAdornment={
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            sx={{
              width: "110px",
              color: "white",
              backgroundColor: MAIN_COLOR,
              borderRadius: "30px",
              position: "relative",
              left: "7px",
            }}
            onClick={()=>{if(onSubmit)onSubmit()}}
          >
            검색
          </Button>
        }
      />
    </Box>
  );
};

export default SearchInput;
