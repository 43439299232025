import { Card } from "@mui/material";
import TitleAndDescription from "../title-and-description/TitleAndDescription";
import { getColor } from "../../config/color-config";
import { ComponentStyle } from "./common";
import { LineChart } from "@mui/x-charts/LineChart";
import { cntDataType } from "../../service/keywordAnalysis/keywordAnalysisHooks";
import { useMemo, useState, useEffect } from "react";
import { generateDateArray } from "./SearchAmountGraph";

type datasetType = {
  [key: string]: [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number
  ];
};

interface MonthlySearchRatioProps {
  data: cntDataType | undefined;
}

const MonthlySearchRatio: React.FC<MonthlySearchRatioProps> = ({ data }) => {
  const [YYYYMMDDArray, setYYYYMMDDArray] = useState<string[]>([]);

  useEffect(() => {
    setYYYYMMDDArray(generateDateArray(1, "year")); // YYYYMMDD 형태로 1년치 날짜 생성
  }, []);

  //  월별 데이터 만들기
  const { months, dataset, keywords } = useMemo(() => {
    const today = new Date(); //현재 날짜
    const currentMonth = today.getMonth(); // 지금 몇월인지 표시

    let keywords: string[] = [];
    if (!!data) {
      keywords = Object.keys(data);
    }

    // x축 데이터 만들기
    const months = [];
    for (let i = 1; i <= 12; i++) {
      months.push(((i + currentMonth) % 12) + 1);
    }

    // y축 데이터 만들기
    const dataset: datasetType = {};
    if (!!data && !!keywords) {
      keywords.forEach((keyword) => {
        dataset[keyword] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; // 마지막은 total => 각 월의 비율을 구해야 하기 때문
        YYYYMMDDArray.forEach((item) => {
          const day = new Date(item); //날짜 구하기
          const month = (12 + day.getMonth() - currentMonth) % 12; // 현재가 2월일 때 1월이 들어오면 제일 마지막에 들어가야함
          const val = !!data[keyword][item] ? data[keyword][item] : 0;
          dataset[keyword][month] += val;
          dataset[keyword][12] += val;
        });
        if (dataset[keyword][12] === 0) {
          dataset[keyword][12] = 1;
        }
      });
    }

    return { months, dataset, keywords };
  }, [data, YYYYMMDDArray]);

  return (
    <>
      <Card sx={{ ...ComponentStyle }}>
        <TitleAndDescription
          title={description.title}
          content={description.content}
        />
        {!!data && (
          <LineChart
            sx={{ pb: 2 }}
            xAxis={[
              {
                id: "Months", //
                data: months, // x축 범위 데이터
                scaleType: "point",
                valueFormatter: (data) => `${data}월`,
              },
            ]}
            yAxis={[{ min: 0 }]}
            series={keywords.map((keyword, i) => {
              return {
                curve: "linear",
                id: keyword,
                label: keyword,
                data: dataset[keyword].slice(0, 12).map((item) => {
                  return Math.round((item * 100) / dataset[keyword][12]);
                }),
                showMark: false,
                color: getColor(i),
                valueFormatter: (data) => `${data}%`, // 갑에 % 붙이기
              };
            })}
            height={400} // 높이
            margin={{ left: 50, top: 50, bottom: 80, right: 50 }}
            slotProps={{
              legend: {
                direction: "row",
                position: { vertical: "bottom", horizontal: "middle" },
                padding: 0,
              },
            }}
          />
        )}
      </Card>
    </>
  );
};

export default MonthlySearchRatio;

//임시 데이터들
const description = {
  title: "월별 검색 비율",
  content: ["전체 기간에서 월별 검색 비율을 나타냅니다."],
};
