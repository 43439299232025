import {
  Card,
  List,
  Stack,
  Typography,
  ListItem,
  ListItemButton,
  Divider,
  Button,
} from "@mui/material";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { MAIN_COLOR, getColor } from "../../config/color-config";
import { useMemo } from "react";
import { getBlogInfoLiteType } from "../../service/blogKeywordAnalysisLite/blogKeywordAnalysisLiteHooks";

export type relkeywordListType = {
  keyword: string;
  pc: number;
  mobile: number;
  total: number;
};

interface RelatedKeywordsListProps {
  blogInfoLite: getBlogInfoLiteType | undefined;
  handleAddKeyword: (keyword: string) => void;
}

const RelatedKeywordsList: React.FC<RelatedKeywordsListProps> = ({
  blogInfoLite,
  handleAddKeyword,
}) => {
  const relKeywordList = useMemo(() => {
    let result: relkeywordListType[] = [];
    if (!!blogInfoLite && blogInfoLite.relData) {
      const entries = Object.entries(blogInfoLite.relData);
      entries.forEach(([keyword, v]) => {
        const pc = Number(
          typeof v.monthlyPcQcCnt === "string" ? 10 : v.monthlyPcQcCnt
        );
        const mobile = Number(
          typeof v.monthlyMobileQcCnt === "string" ? 10 : v.monthlyMobileQcCnt
        );
        result.push({
          keyword,
          pc,
          mobile,
          total: pc + mobile,
        });
      });
    }
    return result;
  }, [blogInfoLite]);

  const handleCopyKeyword = () => {
    const value = relKeywordList.map((item) => item.keyword).join("\n");
    navigator.clipboard
      .writeText(value)
      .then(() => alert("클립보드에 복사되었습니다."));
  };
  return (
    <>
      <Card sx={{ p: 3 }}>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ mb: 1}}
        >
          <Typography fontWeight="bold">관련 키워드</Typography>
          <Button
            startIcon={<FileCopyOutlinedIcon />}
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: MAIN_COLOR,
              borderRadius: "30px",
            }}
            onClick={handleCopyKeyword}
          >
            클립보드 복사
          </Button>
        </Stack>
        <Divider />
        {!!relKeywordList && (
          <List sx={{ overflowY: "auto", maxHeight: "400px" }}>
            {relKeywordList.map((item, index) => {
              return (
                <ListItem
                  disablePadding
                  key={index}
                  sx={{ color: getColor(0) }}
                >
                  <ListItemButton
                    onClick={() => {
                      handleAddKeyword(item.keyword);
                    }}
                  >
                    {item.keyword}
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        )}
      </Card>
    </>
  );
};

export default RelatedKeywordsList;
