import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { getColor } from "../../config/color-config";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import RepeatIcon from "@mui/icons-material/Repeat";
import CollectionsIcon from "@mui/icons-material/Collections";
import { blogInfoItemType } from "../../service/blogKeywordAnalysis/blogKeywordAnalysisHooks";
import { useMemo } from "react";
//별 검색 비율

interface TopBlogsAnalysisProps {
  data: blogInfoItemType[] | undefined;
}

const TopBlogsAnalysis: React.FC<TopBlogsAnalysisProps> = ({ data }) => {


  
  const {
    characterCount, //이미지수 배열
    keywordRepetitionCount, //키워드 반복수 배열
    imageCount,// 이미지 갯수 배열
    avgCharacterCount, //평균 글자수
    avgKeywordRepetitionCount,//키워드 평균 반복수
    avgImageCount, // 이미지 평균 갯수
  } = useMemo(() => {
    let characterCount: number[] = [];
    let keywordRepetitionCount: number[] = [];
    let imageCount: number[] = [];
    let avgCharacterCount = 0;
    let avgKeywordRepetitionCount = 0;
    let avgImageCount = 0;
    if (!!data) {
      data.forEach((item) => {
        characterCount.push(item.TextCount);
        avgCharacterCount += item.TextCount;
        keywordRepetitionCount.push(item.repeatCount);
        avgKeywordRepetitionCount += item.repeatCount;
        imageCount.push(item.imgCount);
        avgImageCount += item.imgCount;
      });
      avgCharacterCount /= 10;
      avgKeywordRepetitionCount /= 10;
      avgImageCount /= 10;
    }
    return {
      characterCount,
      keywordRepetitionCount,
      imageCount,
      avgCharacterCount,
      avgKeywordRepetitionCount,
      avgImageCount,
    };
  }, [data]);

  return (
    <>
      <Typography variant="h5" fontWeight={"bold"} sx={{ mb: 2 }}>
        키워드 상위 블로그 분석
      </Typography>
      {!!data && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{ backgroundColor: "white", borderRadius: "8px", p: 3 }}>
              <Typography fontWeight={"bold"}>
                {" "}
                <Box
                  component="span"
                  sx={{
                    display: "inline-block",
                    width: "10px",
                    height: "10px",
                    backgroundColor: getColor(0),
                    mr: 1,
                  }}
                />
                글자 수
              </Typography>
              <BarChart
                xAxis={[
                  { scaleType: "band", data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] },
                ]}
                series={[
                  {
                    data: characterCount,
                    color: getColor(0),
                  },
                ]}
                height={300}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ backgroundColor: "white", borderRadius: "8px", p: 3 }}>
              <Typography fontWeight={"bold"}>
                <Box
                  component="span"
                  sx={{
                    display: "inline-block",
                    width: "10px",
                    height: "10px",
                    backgroundColor: getColor(1),
                    mr: 1,
                  }}
                />
                키워드 반복 수
              </Typography>
              <BarChart
                xAxis={[
                  { scaleType: "band", data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] },
                ]}
                series={[
                  {
                    data: keywordRepetitionCount,
                    color: getColor(1),
                  },
                ]}
                height={300}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ backgroundColor: "white", borderRadius: "8px", p: 3 }}>
              <Typography fontWeight={"bold"}>
                <Box
                  component="span"
                  sx={{
                    display: "inline-block",
                    width: "10px",
                    height: "10px",
                    backgroundColor: getColor(2),
                    mr: 1,
                  }}
                />
                이미지 개수
              </Typography>
              <BarChart
                xAxis={[
                  { scaleType: "band", data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] },
                ]}
                series={[
                  {
                    data: imageCount,
                    color: getColor(2),
                  },
                ]}
                height={300}
              />
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card sx={{ p: 3 }}>
              <Stack direction={"row"} alignItems="center" gap={3}>
                <TextFormatIcon sx={{ color: getColor(0) }} />
                <Stack>
                  <Typography variant="caption">평균 글자 수</Typography>
                  <Typography fontWeight={"bold"}>
                    {avgCharacterCount}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ p: 3 }}>
              <Stack direction={"row"} alignItems="center" gap={3}>
                <RepeatIcon sx={{ color: getColor(0) }} />
                <Stack>
                  <Typography variant="caption">평균 키워드 반복 수</Typography>
                  <Typography fontWeight={"bold"}>
                    {avgKeywordRepetitionCount}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ p: 3 }}>
              <Stack direction={"row"} alignItems="center" gap={3}>
                <CollectionsIcon sx={{ color: getColor(0) }} />
                <Stack>
                  <Typography variant="caption">평균 이미지 개수</Typography>
                  <Typography fontWeight={"bold"}>{avgImageCount}</Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default TopBlogsAnalysis;
