import { useMutation } from "@tanstack/react-query";
import { keywordAnalysisBulkAPI } from "./keywordAnalysisBulkAxios";


// 첫 번째 매개변수: mutation 성공 시 반환될 데이터의 타입
// 두 번째 매개변수: mutation 실패 시 반환될 에러의 타입
// 세 번째 매개변수: mutation 함수의 매개변수 타입


// 대량 키워드 분석
export const useBulkKeywordToolsMutation = () => {
  return useMutation<bulkKeywordToolsType,Error,string>({
    mutationFn: (keywords: string) => keywordAnalysisBulkAPI.getBulkKeywordTools(keywords),
    onSuccess: (data: any) => {
      return data;
    },
    onError: (err) => {
   //   console.log("대량 키워드 분석의 onError >>> ", err);
    },
  });
};


export type bulkKeywordToolsType ={
    [key:string]: {
        "relKeyword":string,
        "monthlyPcQcCnt": number,
        "monthlyMobileQcCnt": number,
        "monthlyAvePcClkCnt": number,
        "monthlyAveMobileClkCnt":number,
        "monthlyAvePcCtr":number,
        "monthlyAveMobileCtr": number,
        "plAvgDepth": number,
        "compIdx": string,
    }
}