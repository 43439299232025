import {
  Card,
  Switch,
  Typography,
  FormControlLabel,
  TextField,
  Button,
} from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { CSVLink } from "react-csv";
import { useMemo } from "react";
import { MAIN_COLOR } from "../../config/color-config";
import { CustomIcon } from "../side-nav/config";
import toolIcon from "../../asset/image/tool-icon.png"


const buttonStyle = {
  width: "100%",
  mt: 4,
  height: "50px",
  backgroundColor:MAIN_COLOR,
  fontWeight: "bold",
  borderRadius:"30px"
};
interface CombineResultProps {
  keywordSpace: boolean;
  combineResult: string[];
  handleCombineKeyword: () => void;
  handleKeywordSpace: () => void;
}
const CombineResult: React.FC<CombineResultProps> = ({
  keywordSpace,
  combineResult,
  handleCombineKeyword,
  handleKeywordSpace,
}) => {
  const csvData = useMemo(() => {
    const result = [];
    for (let i = 0; i < combineResult.length; i++) {
      result.push([combineResult[i]]);
    }
    return result;
  }, [combineResult]);

  return (
    <>
      <Card sx={{ p: 3,height:"900px" }}>
        <Typography variant="subtitle1" fontWeight={"bold"} sx={{ mb: 4 }}>
          조합 완료 키워드
        </Typography>
        <FormControlLabel
          control={
            <Switch checked={keywordSpace} onChange={handleKeywordSpace} />
          }
          label="키워드 사이 공백 추가"
        />
        <TextField
          placeholder="조합된 키워드가 출력됩니다."
          sx={{ width: "100%", mt: 3 }}
          value={combineResult.join("\n")}
          multiline
          rows={20}
        />
        <Typography textAlign={"right"} variant="body2">
          중복되는 키워드는 자동 제거됩니다.
        </Typography>
        <Typography textAlign={"right"} fontWeight={"bold"}>
          조합된 키워드 개수 : {combineResult.length}
        </Typography>

        <Button
          sx={buttonStyle}
          variant="contained"
          onClick={handleCombineKeyword}
          startIcon={<CustomIcon src={toolIcon} alt={"tool-icon"}/>}
        >
          키워드 조합하기
        </Button>
        <CSVLink
          data={csvData}
          headers={["키워드 조합"]}
          filename="키워드 조합.csv"
        >
          <Button sx={buttonStyle} variant="contained"
          startIcon={<SaveAltIcon/>}>
            
            
            다운받기
          </Button>
        </CSVLink>
      </Card>
    </>
  );
};

export default CombineResult;
