import { Checkbox, FormControlLabel } from "@mui/material";
//체크 기능
//text 보여주는 기능

interface CombineItemProps {
  text: number[];
  index: number;
  check: boolean;
  onCheck: (num: number) => void;
}

const CombineItem: React.FC<CombineItemProps> = ({
  text,
  index,
  check,
  onCheck,
}) => {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={check}
            onChange={() => {
              onCheck(index);
            }}
            sx={{ mr: 1 }}
          />
        }
        label={text.join(" + ")}
      />
    </>
  );
};

export default CombineItem;
