import { Grid, Card, Stack, Typography, Box } from "@mui/material";
import { getColor } from "../../config/color-config";
import { sectionType } from "../../service/blogKeywordAnalysis/blogKeywordAnalysisHooks";

const boxStyle = {
  border: `2px solid ${getColor(0)}`,
  borderRadius: "8px",
  p: 1,
  width: "100%",
  boxSizing: "border-box",
};

interface SectionOrderPlacementProps {
  data: sectionType | undefined;
}

const SectionOrderPlacement: React.FC<SectionOrderPlacementProps> = ({
  data,
}) => {
  return (
    <>
      <Typography variant="h5" fontWeight={"bold"} sx={{ my: 2 }}>
        섹션 배치 순서
      </Typography>
      {!!data && (
        <Grid container spacing={3}>
          {/* PC 섹션 */}
          <Grid item xs={12} sm={6}>
            <Card sx={{ p: 3, boxSizing: "border-box" }}>
              <Stack alignItems="center" justifyContent="space-between" gap={1}>
                <Typography
                  variant="subtitle1"
                  fontWeight={"bold"}
                  sx={{ mb: !!data ? 2 : 0 }}
                >
                  PC 섹션 배치 순서
                </Typography>
                {data.pc.slice(0, 7).map((item, i) => {
                  return (
                    <Box sx={boxStyle} key={i}>
                      <Box component={"span"}>{i + 1}</Box>
                      <Typography
                        sx={{
                          display: "inline-block",
                          width: "95%",
                          textAlign: "center",
                        }}
                        fontWeight={"bold"}
                        variant="body2"
                      >
                        {item}
                      </Typography>
                    </Box>
                  );
                })}
              </Stack>
            </Card>
          </Grid>

          {/* 모바일 섹션 */}
          <Grid item xs={12} sm={6}>
            <Card sx={{ p: 3, boxSizing: "border-box" }}>
              <Stack alignItems="center" justifyContent="space-between" gap={1}>
                <Typography
                  variant="subtitle1"
                  fontWeight={"bold"}
                  sx={{ mb: !!data ? 2 : 0 }}
                >
                  Mobile 섹션 배치 순서
                </Typography>
                {data.mo.slice(0, 7).map((item, i) => {
                  return (
                    <Box sx={boxStyle} key={i}>
                      <Box component={"span"}>{i + 1}</Box>
                      <Typography
                        sx={{
                          display: "inline-block",
                          width: "95%",
                          textAlign: "center",
                        }}
                        fontWeight={"bold"}
                        variant="body2"
                      >
                        {item}
                      </Typography>
                    </Box>
                  );
                })}
              </Stack>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SectionOrderPlacement;
