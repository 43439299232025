import axios from "axios";
import {
  openMemberShipPopup,
  clearUserInfo,
  unCheckIsLogin,
  unCheckRememberMe,
  isLogin,
} from "../store/index";

export const URL = process.env.REACT_APP_BASE_URL;
// export const URL = "http://localhost:8000"
axios.defaults.withCredentials = true;

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

export const customAxios = axios.create({
  baseURL: URL,
  withCredentials: true, // withCredentials를 true로 설정하여 CORS 요청 시 쿠키를 포함합니다
});

customAxios.interceptors.request.use(
  //axios 요청 직전에 발생하는 함수
  function (config) {
    return config;
  },
  //axios 요청 에러나면 발생하는 함수
  function (error) {
    return Promise.reject(error);
  }
);
customAxios.interceptors.response.use(
  //axios 올바른 response가오면 발동하는 함수
  function (response) {
    return response;
  },

  //에러 메시지가 response되면 발동되는 함수
  async function (error) {
    if (isLogin) {
      // 로그인상태인데 에러가 생긴다는건 세션인 만료되었다는것

      alert("세션이 만료되었습니다. 다시 로그인이 필요합니다.");
      clearUserInfo(); // 사용자 정보 날리기
      unCheckIsLogin(); //로그인 여부 false
      unCheckRememberMe(); //로그인 유지하기 false
      window.location.reload(); // 리로딩 => logout을 동작시키기 위해서는 autoLogin에 있는 logout을 동작시킬 필요가 있음(Top nav의 useEffect에 존재 ), hook이라 여기서는 불러올 수 가 없음
    } else {
      clearUserInfo(); // 사용자 정보 날리기
      unCheckIsLogin(); //로그인 여부 false
      unCheckRememberMe(); //로그인 유지하기 false
      openMemberShipPopup(); // 그냥 로그인도 안되어있을 경우
    }
    return Promise.reject(error);
  }
);
