import { customAxios } from "../axios-config";

export const keywordAnalysisAPI = {
  // 키워드 기본 정보
  getKeywordInfo: async(keywords: string) => {
    return customAxios.post("basic_keyword_info", { keywords }).then(res=>res.data);
  },
  // 키워드 검색량 정보
  getKeywordTools: async(keywords: string) => {
    return customAxios.post("keyword_tools", { keywords }).then(res=>res.data);
  },
  // 키워드 성별 비율
  getGenderRate: async(keywords: string) => {
    return customAxios.post("gender_rate", { keywords }).then(res=>res.data);
  },
  // 키워드 연령 비율
  getAgeRate:async (keywords: string) => {
    return customAxios.post("age_rate", { keywords }).then(res=>res.data);
  },
};

