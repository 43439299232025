import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AdminAPI, BannerAPI } from "./adminAxios";

// 첫 번째 매개변수: mutation 성공 시 반환될 데이터의 타입
// 두 번째 매개변수: mutation 실패 시 반환될 에러의 타입
// 세 번째 매개변수: mutation 함수의 매개변수 타입

//유저 목록
export const useUserListQuery = () => {
  return useQuery({
    queryKey: ["user"],
    queryFn: async () => await AdminAPI.getUserList(),
    select: (data: UserType[]) => {
      return data.map((item: UserType) => {
        return {
          email: item.email,
          path: item.funnels,
          interest: item.interest,
          signupDate: item.join.slice(0, 10),
          lastLoginDate: item.recent
            ? item.recent.slice(0, 10)
            : item.join.slice(0, 10),
          name: item.name,
        };
      });
    },
  });
};

export interface Data {
  email: string;
  name: string;
  interest: string;
  path: string;
  signupDate: string;
  lastLoginDate: string;
}

export type UserType = {
  email: string;
  funnels: string;
  interest: string;
  join: string;
  name: string;
  recent:string;
};

//광고 생성
export const useCreateBannerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, FormData>({
    mutationFn: (data) => BannerAPI.createBanner(data),
    onSuccess: (data: any) => {
      queryClient.invalidateQueries();
    },
    onError: (err) => {
      alert("이미지를 추가하지 못했습니다. 잠시 후 다시 시도해주세요");
    },
  });
};

//광고 삭제
export const useDeleteBannerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, string>({
    mutationFn: (id) => BannerAPI.deleteBanner(id),
    onSuccess: (data: any) => {
      queryClient.invalidateQueries();
      // console.log(data); //결과 뽑아보기
    },
    onError: (err) => {
      alert("이미지를 삭제하지 못했습니다. 잠시 후 다시 시도해주세요");
    },
  });
};

//광고 목록 불러오기
export const useBannerListQuery = () => {
  return useQuery({
    queryKey: ["banner"],
    queryFn: async () => await BannerAPI.getBannerList(),
    select: (data: BannerType[]) => {
      const result: { [key: string]: BannerType } = {};
      data.forEach((item: any) => {
        result[item.id] = {
          id: item.id,
          url: item.url,
          image: "https://셀러원정대.com/api/" + item.image,
        };
      });
      return result;
    },
  });
};

export type BannerType = {
  id: string;
  image: string;
  url: string;
};
