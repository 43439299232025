import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogContentText,
  FormControlLabel,
  IconButton,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoImage from "../logo/LogoImage";
import { DESCRIPTION_COLOR, MAIN_COLOR } from "../../config/color-config";
import {
  openLoginModal,
  openLoginModalState,
  rememberMeState,
} from "../../store/index";
import { useNavigate } from "react-router-dom";
import { useState, ChangeEvent } from "react";
import { useAutoLogin, useLoginMutation } from "../../service/user/userHook";
import { openMembershipPopupState } from "../../store/openMembershipPopupState";
import { useEffect } from "react";
import freeImage from "../../asset/image/free.png";
import { userInfoState } from "../../store/userInfoState";
interface TopNavProps {
  onOpen: () => void;
  lgUp: boolean;
}
const TopNav: React.FC<TopNavProps> = ({ onOpen, lgUp }) => {
  const autoLogin = useAutoLogin();
  const { userInfo } = userInfoState();

  useEffect(() => {
    autoLogin(); // rememberMe, isLogin 상태를 보고 자동로그인 할지를 결정
  }, []);

  return (
    <Stack
      direction={"row"}
      sx={{
        position: lgUp ? "" : "fixed",
        width: "100%",
        zIndex: 10,
        backgroundColor: lgUp ? "" : "white",
        justifyContent: "space-between",
        fontSize: "10px",
        alignItems: "center",
        px: lgUp ? 2 : 1,
        py: lgUp ? 4 : 1,
        boxSizing: "border-box",
      }}
    >
      <Box sx={{ width: "100px", textAlign: "center" }}>
        <IconButton onClick={onOpen}>{!lgUp && <MenuIcon />}</IconButton>
      </Box>
      <LogoImage height={lgUp ? "40px" : "32px"} />

      {!!userInfo.name ? (
        <Stack direction="row" alignItems={"center"}>
          <Typography variant="body2" color={MAIN_COLOR} fontWeight={"bold"}>
            {userInfo?.email.split("@")[0]}&nbsp;
          </Typography>
          <Typography variant="body2" color="grey">
            님 환영합니다.
          </Typography>
        </Stack>
      ) : (
        <LoginButton />
      )}
    </Stack>
  );
};

export default TopNav;

const LoginButton: React.FC = () => {
  const { open, close, openLoginModal } = openLoginModalState();

  const navigate = useNavigate();

  const { rememberMe, check, unCheck } = rememberMeState(); // 전역변수로 관리

  const handleCheckChange = () => {
    // 변수로
    if (rememberMe) {
      unCheck();
    } else {
      check();
    }
  };

  const goSignUpPage = () => {
    close();
    navigate("signup");
  };

  const { mutate: login } = useLoginMutation();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleChangeUsername = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };
  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleLoginSubmit = (e: any) => {
    e.preventDefault();
    login({ username, password });
  };
  const handleDialogClose = () => {
    setUsername("");
    setPassword("");
    close();
  };

  return (
    <>
      <MembershipPopup />
      <Button
        onClick={open}
        sx={{ width: "100px", fontWeight: "bold", color: DESCRIPTION_COLOR }}
      >
        로그인
      </Button>
      <Dialog
        open={openLoginModal}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={handleLoginSubmit}>
          <Card sx={{ p: 3, width: "300px", margin: "0 auto", py: 5 }}>
            <Stack alignItems={"center"} gap={3}>
              <Typography
                variant="subtitle1"
                fontSize={"22px"}
                fontWeight={900}
                sx={{
                  color: MAIN_COLOR,
                }}
              >
                로그인
              </Typography>
              <OutlinedInput
                onChange={handleChangeUsername}
                value={username}
                placeholder="아이디"
                fullWidth
              />
              <OutlinedInput
                type="password"
                onChange={handleChangePassword}
                value={password}
                placeholder="비밀번호"
                fullWidth
              />
              <Box sx={{ width: "100%" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ mr: 1 }}
                      checked={rememberMe}
                      onChange={handleCheckChange}
                    />
                  }
                  label="로그인 유지하기"
                />
              </Box>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  height: "50px",
                  backgroundColor: MAIN_COLOR,
                }}
                fullWidth
              >
                로그인
              </Button>
              <Button
                onClick={goSignUpPage}
                variant="outlined"
                sx={{
                  height: "50px",
                  color: MAIN_COLOR,
                  border: `1px solid ${MAIN_COLOR}`,
                }}
                fullWidth
              >
                회원가입
              </Button>
            </Stack>
          </Card>
        </form>
      </Dialog>
    </>
  );
};

// 회원가입 유도 팝업
const MembershipPopup = () => {
  const { close, openMemberShipPopup } = openMembershipPopupState();

  const handleMoveToLogin = () => {
    openLoginModal();
    close();
  };

  return (
    <>
      <Dialog
        open={openMemberShipPopup}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Stack alignItems="center" sx={{ width: "350px", mb: 7, mt: 7 }}>
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <img src={freeImage} width={130} alt="공짜 이미지" />
          </Box>
          <DialogContentText sx={{ my: 4 }}>
            <Typography variant="h5" fontWeight={"bold"} textAlign={"center"}>
              지금 회원가입하고 <br />
              평생 무료로 이용해 보세요!
            </Typography>
            <Typography variant="subtitle1" textAlign={"center"}>
              로그인 후 사용할 수 있는 기능입니다.
            </Typography>
          </DialogContentText>

          <Button
            variant="contained"
            sx={{
              backgroundColor: MAIN_COLOR,
              borderRadius: "30px",
              width: "200px",
              fontWeight: "bold",
              p: 2,
            }}
            onClick={handleMoveToLogin}
          >
            로그인 하기
          </Button>
        </Stack>
      </Dialog>
    </>
  );
};
