import { useMutation } from "@tanstack/react-query";
import { keywordAnalysisAPI } from "./KeywordAnalysisAxios";
import { openMemberShipPopup } from "../../store";

// 첫 번째 매개변수: mutation 성공 시 반환될 데이터의 타입
// 두 번째 매개변수: mutation 실패 시 반환될 에러의 타입
// 세 번째 매개변수: mutation 함수의 매개변수 타입

// 키워드 기본정보
export const useKeywordInfoMutation = () => {
  return useMutation<keywordInfoType[], Error, string>({
    mutationFn: (keywords: string) =>
      keywordAnalysisAPI.getKeywordInfo(keywords),
    onSuccess: (data: any) => {
      return data;
    },
    onError: (err) => {
      // alert("로그인이 필요한 서비스 입니다.");
      console.log("키워드 기본정보의 onError >>> ", err);
      openMemberShipPopup();
    },
  });
};

// 키워드 검색량 정보
export const useKeywordToolsMutation = () => {
  return useMutation<keywordToolsType, Error, string>({
    mutationFn: (keywords: string) =>
      keywordAnalysisAPI.getKeywordTools(keywords),
    onSuccess: (data: any) => {
      return data;
    },
    onError: (err) => {
      //   console.log("키워드 검색량 정보의 onError >>> ", err);
    },
  });
};

// 키워드 성별 비율
export const useGenderRateMutation = () => {
  return useMutation<genderRateType, Error, string>({
    mutationFn: (keywords: string) =>
      keywordAnalysisAPI.getGenderRate(keywords),
    onSuccess: (data: any) => {
      return data;
    },
    onError: (err) => {
      //   console.log("키워드 성별 비율의 onError >>> ", err);
    },
  });
};

// 키워드 성별 비율
export const useAgeRateMutation = () => {
  return useMutation<ageRateType, Error, string>({
    mutationFn: (keywords: string) => keywordAnalysisAPI.getAgeRate(keywords),
    onSuccess: (data: any) => {
      return data;
    },
    onError: (err) => {
      //  console.log("키워드 연령 비율의 onError >>> ", err);
    },
  });
};

// 키워드 기본정보 타입
export type keywordInfoType = {
  categoryId: string;
  isAdult: number;
  isLowVolume: number;
  isRestricted: number;
  isSeason: number;
  keyword: string;
} | null;

// 키워드 검색량 정보 타입

export type keywordToolsType = {
  relData: relDataType;
  cntData: cntDataType;
} | null;

export type relDataType = {
  [key: string]: {
    monthlyPcQcCnt: string | number;
    monthlyMobileQcCnt: string | number;
  };
};

export type cntDataType = {
  [key: string]: {
    [keyu: string]: number;
  };
};

// 성별 비율 type
export type genderRateType = {
  [key: string]: genderKeyword;
} | null;

type genderKeyword = {
  [keyword: string]: {
    f: number;
    m: number;
  };
};

//연령 비율 type
export type ageRateType = {
  [key: string]: [number, number, number, number, number, number];
} | null;
