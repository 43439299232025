import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface UserInfoState {
  userInfo: {
    name: string;
    email: string;
    isAdmin: boolean;
  };
  setUserInfo: (name: string, email: string, isAdmin: boolean) => void;
  clearUserInfo: () => void;
}

export const userInfoState = create(
  persist<UserInfoState>(
    (set, get) => ({
      userInfo: {
        name: "",
        email: "",
        isAdmin: false,
      },
      setUserInfo: (name: string, email: string, isAdmin: boolean) => {
        set({
          userInfo: {
            name,
            email,
            isAdmin,
          },
        });
      },
      clearUserInfo: () => {
        set({ userInfo: { name: "", email: "", isAdmin: false } });
      },
    }),
    {
      name: "USER_INFO",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
export const setUserInfo = userInfoState.getState().setUserInfo;
export const clearUserInfo = userInfoState.getState().clearUserInfo;
