import {
  Box,
  Stack,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Typography,
  Menu,
  MenuList,
  MenuItem,
  Divider,
  Card,
  IconButton,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import * as React from "react";
import { visuallyHidden } from "@mui/utils";
import {
  DESCRIPTION_COLOR,
  MAIN_COLOR,
  getColor,
} from "../../config/color-config";
import { CSVLink } from "react-csv";
import { blogDetailListType } from "../../pages/BlogKeywordAnalysisLite";
import { blogItemType } from "../../service/blogKeywordAnalysisLite/blogKeywordAnalysisLiteHooks";
import { useState } from "react";
import { Link } from "react-router-dom";
import SearchInput from "../input/SearchInput";
interface Data {
  keyword: string;
  pc: number;
  mobile: number;
  total: number;
  rate: number;
  productCount: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Data;
  label: string;
}

const tableHeadCellStyle = {
  padding: "0px",
  py: 2,
  whiteSpace: "nowrap",
};

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          align="left"
          sx={{
            ...tableHeadCellStyle,
            maxWidth: "50px",
            width: "50px",
            minWidth: "50px",
          }}
        />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sx={tableHeadCellStyle}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              style={{
                color: orderBy === headCell.id ? getColor(0) : "black",
                fontWeight: orderBy === headCell.id ? "bold" : "normal",
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell sx={tableHeadCellStyle} align={"left"}>
          블로그 top 10
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

interface RelatedKeywordsTableProps {
  handleDeleteKeywordDetail: (id: string) => void;
  rows: blogDetailListType[];
}

//검색량 테이블
const RelatedKeywordsTable: React.FC<RelatedKeywordsTableProps> = ({
  handleDeleteKeywordDetail,
  rows,
}) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("keyword");
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [copyMenuOpen, setCopyMenuOpen] = React.useState<null | HTMLElement>(
    null
  );

  //키워드 복사 메뉴 on off
  const handleCopyMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setCopyMenuOpen(event.currentTarget);
  };
  const handleCopyMenuClose = () => {
    setCopyMenuOpen(null);
  };

  //검색
  const handleChangeSearchQuery = (e: any) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  //정렬
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  //엑셀 데이터
  const csvData = React.useMemo(() => {
    return rows.map((item) => {
      return [
        item.keyword,
        item.pc,
        item.mobile,
        item.total,
        item.productCount,
        item.rate.toFixed(2),
      ];
    });
  }, [rows]);

  //키워드 복사
  const handleCoypClipBoard = (e: any) => {
    const { id } = e.target;
    let value = "";
    if (id === "1") {
      value = rows.map((item) => item.keyword).join(" ");
    } else if (id === "2") {
      value = rows.map((item) => item.keyword).join(",");
    } else if (id === "3") {
      value = rows.map((item) => "#" + item.keyword).join(" ");
    } else {
      value = rows.map((item) => "#" + item.keyword).join(",");
    }
    navigator.clipboard
      .writeText(value)
      .then(() => alert("클립보드에 복사되었습니다."));
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).filter((item) =>
        item.keyword.includes(searchQuery)
      ),
    [rows, order, orderBy, searchQuery]
  );

  return (
    <Card sx={{ borderRadius: "8px", backgroundColor: "white" }}>
      <Grid container sx={{ width: "100%", p: 2 }} spacing={1}>
        {/* 엑셀 다운로드 */}
        <Grid item xs={12}>
          <Stack
            justifyContent={"space-between"}
            direction="row"
            flexWrap={"wrap"}
          >
            {/* 검색 */}
            <SearchInput
              width="200px"
              value={searchQuery}
              onChange={handleChangeSearchQuery}
            />
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"end"}
              gap={2}
            >
              <CSVLink
                data={csvData}
                headers={csvHeader}
                filename="연관키워드.csv"
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: MAIN_COLOR,
                    height: "50px",
                    borderRadius: "30px",
                  }}
                >
                  <SaveAltOutlinedIcon sx={{ mr: 1 }} />
                  엑셀 다운로드
                </Button>
              </CSVLink>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: MAIN_COLOR,
                  height: "50px",
                  borderRadius: "30px",
                }}
                onClick={handleCopyMenuOpen}
              >
                <FileCopyOutlinedIcon sx={{ mr: 1 }} />
                키워드 복사
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={copyMenuOpen}
                open={!!copyMenuOpen}
                onClose={handleCopyMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Typography
                  variant="subtitle1"
                  textAlign={"center"}
                  fontWeight={"bold"}
                >
                  형식을 선택해 주세요
                </Typography>
                <Divider />
                <MenuList sx={{ width: "250px" }}>
                  <MenuItem id="1" onClick={handleCoypClipBoard}>
                    키워드1 키워드2 키워드3
                  </MenuItem>
                  <MenuItem id="2" onClick={handleCoypClipBoard}>
                    키워드1,키워드2,키워드3
                  </MenuItem>
                  <MenuItem id="3" onClick={handleCoypClipBoard}>
                    #키워드1#키워드2#키워드3
                  </MenuItem>
                  <MenuItem id="4" onClick={handleCoypClipBoard}>
                    #키워드1,#키워드2,#키워드3
                  </MenuItem>
                </MenuList>
              </Menu>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 600, overflowY: "hidden" }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell
                      padding="none"
                      sx={cellStyle(index)}
                      align="left"
                    >
                      <IconButton
                        onClick={() => {
                          handleDeleteKeywordDetail(row.keyword);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      align="left"
                      padding="none"
                      sx={{ ...cellStyle(index) }}
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      <Link
                        to="/keyword-analysis"
                        state={{ keyword: row.keyword }}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography
                          variant="subtitle2"
                          fontWeight={"bold"}
                          sx={{
                            cursor: "pointer",
                            transition: "all 0.1s",
                            color: getColor(0),
                            whiteSpace: "nowrap",
                            "&:hover": {
                              opacity: 0.7,
                            },
                          }}
                        >
                          {row.keyword}
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell sx={cellStyle(index)} align="left">
                      {row.pc.toLocaleString()}
                    </TableCell>
                    <TableCell sx={cellStyle(index)} align="left">
                      {row.mobile.toLocaleString()}
                    </TableCell>
                    <TableCell sx={cellStyle(index)} align="left">
                      {row.total.toLocaleString()}
                    </TableCell>
                    <TableCell sx={cellStyle(index)} align="left">
                      {row.productCount.toLocaleString()}
                    </TableCell>
                    <TableCell sx={cellStyle(index)} align="left">
                      {row.rate.toFixed(2).toLocaleString()}
                    </TableCell>
                    <TableCell sx={cellStyle(index)} align="left">
                      <Stack
                        direction={"row"}
                        gap={1}
                        flexWrap="wrap"
                        sx={{ width: "200px" }}
                      >
                        {row.items.map((item, i) => {
                          return <TopItem data={item} i={i} key={i} />;
                        })}
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
              {!!visibleRows && <TableRow sx={{ height: "300px" }} />}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="body2" sx={{ p: 3 }}>
          전체 {rows.length}개
        </Typography>
      </Paper>
    </Card>
  );
};

interface topItemProps {
  data: blogItemType;
  i: number;
}

const TopItem: React.FC<topItemProps> = ({ data, i }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMoveToLink = () => {
    window.open(data.link, "_blank");
  };
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        onClick={handleMoveToLink}
        component="button"
        key={data.title}
        onMouseOver={handleOpen}
        onMouseLeave={handleClose}
        sx={{
          border: "none",
          cursor: "pointer",
          width: "32px",
          backgroundColor: getColor(0, i < 5 ? 1 : 0.6),
          color: "white",
          textAlign: "center",
          lineHeight: "15px",
          p: 1,
          borderRadius: "8px",
          fontWeight: "bold",
        }}
      >
        {i + 1}
      </Box>

      {open && (
        <Card
          sx={{
            width: "450px",
            position: "absolute",
            zIndex: 100,
            right: 0,
            p: 3,
            border: `1px solid ${DESCRIPTION_COLOR}`,
          }}
        >
          <Stack gap={1}>
            <Typography
              variant="subtitle1"
              sx={{ color: getColor(0) }}
              fontWeight={"bold"}
            >
              {data.title.replace(/(<([^>]+)>)/gi, "")}
            </Typography>
            <Typography variant="body1">
              {data.description.replace(/(<([^>]+)>)/gi, "")}
            </Typography>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography color="grey" variant="caption" fontWeight={"bold"}>
                {data.bloggername}
              </Typography>
              <Typography variant="caption" color="#c2c2c2" fontWeight={"bold"}>
                {data.postdate.slice(0, 4)}-{data.postdate.slice(4, 6)}-
                {data.postdate.slice(6, 8)}
              </Typography>
            </Stack>
          </Stack>
        </Card>
      )}
    </Box>
  );
};

const cellStyle = (index: number) => {
  return {
    backgroundColor: index % 2 === 0 ? "#fafafa" : "white",
    py: 1,
    pl: "5px",
  };
};
export default RelatedKeywordsTable;
const headCells: readonly HeadCell[] = [
  {
    id: "keyword",
    label: "키워드",
  },
  {
    id: "pc",
    label: "PC 검색량",
  },
  {
    id: "mobile",
    label: "모바일 검색량",
  },
  {
    id: "total",
    label: "전체 검색량",
  },
  {
    id: "productCount",
    label: "블로그 총 발행량",
  },
  {
    id: "rate",
    label: "비율",
  },
];

const csvHeader = [
  "키워드",
  "PC 검색량",
  "모바일 검색량",
  "총 검색량	",
  "블로그 총 발행량",
  "비율",
];
