import { Typography, Button, Stack, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ErrorBoundary: React.FC = () => {
  const navigate = useNavigate();
  const moveToLink = () => {
    navigate(`/`);
  };

  return (
    <Stack
      gap={3}
      sx={{ maxWidth: "1000px", margin: "0 auto", marginTop: "20%" }}
      alignItems={"center"}
    >
      <Typography variant="h3" fontWeight={900}>
        404
      </Typography>
      <Typography variant="h5" fontWeight={900}>
        {data.title}
      </Typography>
      <Box>
        {data.body.map((item,i) => {
          return (
            <Typography variant="body1" textAlign={"center"} key={i}>
              {item}
            </Typography>
          );
        })}
      </Box>
      <Button
        onClick={moveToLink}
        variant="outlined"
        sx={{ border: "1px solid black", color: "black", width: "200px" }}
      >
        메인페이지로 이동
      </Button>
    </Stack>
  );
};

export default ErrorBoundary;

const data = {
  title: "원하시는 페이지를 찾을 수 없습니다.",
  body: [
    "찾으려는 주소가 잘 못 입력되었거나.",
    "주소의 변경 혹은 삭제로 이용할 수 없습니다.",
    "입력하신 페이지의 주소가 정확한지 다시한번 확인해 주세요.",
  ],
};
