import blog from "../../asset/image/blog-icon.png";
import shopping from "../../asset/image/shopping-icon.png";
import tool from "../../asset/image/tool-icon.png";
import keyword from "../../asset/image/keyword-icon.png";

interface CustomIconProps {
  src: string;
  alt: string;
  top?: string;
  width?:string;
}

export const CustomIcon: React.FC<CustomIconProps> = ({
  src,
  alt,
  top = "2px",
  width = "26px"
}) => {
  return (
    <img
      src={src}
      alt={alt}
      style={{ marginRight: "10px", position: "relative", top, aspectRatio: '1.15 / 1' }}
      width={width}
    />
  );
};

export const nav_items = [
  {
    title: "키워드",
    icon: <CustomIcon src={keyword} alt="키워드_로고" />,
    subtitles: [
      { path: "keyword-analysis", title: "키워드 상세 분석" },
      { path: "keyword-analysis-bulk", title: "대량 키워드 분석" },
    ],
  },
  {
    title: "블로그",
    icon: <CustomIcon src={blog} alt="키워드_로고" />,
    subtitles: [
      { path: "blog-keyword-analysis", title: "블로그 키워드 상세 분석" },
      {
        path: "blog-keyword-analysis-lite",
        title: "블로그 키워드 간편 분석",
      },
    ],
  },
  {
    title: "쇼핑",
    icon: <CustomIcon src={shopping} alt="키워드_로고" />,
    subtitles: [
      { path: "shopping-keyword-analysis", title: "쇼핑 키워드 분석" },
    ],
  },
  {
    title: "도구",
    icon: <CustomIcon src={tool} alt="키워드_로고" />,
    subtitles: [{ path: "keyword-combiner", title: "키워드 조합기" }],
  },
];
