import { Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { DESCRIPTION_COLOR, MAIN_COLOR } from "../config/color-config";
import KeywordAnalysisData from "../component/blog-keyword-analysis/KeywordAnalysisData";
import SectionOrderPlacement from "../component/blog-keyword-analysis/SectionOrderPlacement";
import TopBlogsAnalysis from "../component/blog-keyword-analysis/TopBlogsAnalysis";
import TopBlogsList from "../component/blog-keyword-analysis/TopBlogsList";
import {
  useBlogCountMutation,
  useBlogInfoMutation,
  useKeywordSectionMutation,
} from "../service/blogKeywordAnalysis/blogKeywordAnalysisHooks";
import Loading from "../component/loading/Loading";
import { ChangeEvent, useEffect, useRef } from "react";
import { useState, useMemo } from "react";
import { useBlogInfoLiteMutation } from "../service/blogKeywordAnalysisLite/blogKeywordAnalysisLiteHooks";
import { reg } from "../config/regular-expression";
import { Helmet } from "react-helmet";
import { CustomIcon } from "../component/side-nav/config";
import blogIcon from "../asset/image/blog-icon-black.png";
import SearchInput from "../component/input/SearchInput";
import html2canvas from "html2canvas";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
const BlogKeywordAnalysis: React.FC = () => {
  const { data: section, mutateAsync: getSection, isPending: pending1 } = useKeywordSectionMutation();
  const { data: blogInfo, mutateAsync: getBlogInfo, isPending: pending2 } = useBlogInfoMutation();
  const { data: blogCount, mutateAsync: getBlogCount, isPending: pending3 } = useBlogCountMutation();
  const { data: blogInfoLite, mutateAsync: getBlogInfoLite, isPending: pending4 } = useBlogInfoLiteMutation();

  const [inputText, setInputText] = useState<string>("");
  const [keyword, setkeyword] = useState<string>("");
  const captureRef = useRef<HTMLDivElement>(null);
  const [padding, setPadding] = useState<number>(0);
  const [isCapture, setIsCapture] = useState<boolean>(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputText(value.toUpperCase());
  };

  const onSubmit = async (value: string) => {
    value = value.replaceAll(reg, "");
    setInputText(value);
    setkeyword(value);
    try {
      if (!!value) {
        await getBlogInfoLite(value);
        await getBlogCount(value);
        await getSection(value);
        await getBlogInfo(value);
      }
    } catch {
      // alert("잘못된 키워드 입니다. 다시 입력해 주세요.");
    }
  };

  //엔터 누르기
  const handlePressEnter = () => {
    onSubmit(inputText);
  };
  const isLoading = useMemo(() => {
    return pending1 || pending2 || pending3 || pending4;
  }, [pending1, pending2, pending3, pending4]);

  //저장함수
  const onSaveAs = (uri: string, filename: string) => {
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.href = uri;
    link.download = filename;
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (padding !== 0 && captureRef.current && isCapture) {
      html2canvas(captureRef.current).then((canvas) => {
        onSaveAs(canvas.toDataURL("image/png"), `셀러원정대_${inputText}_${getCurrentDateTime()}png`);
      });
      setPadding(0);
    }
  }, [padding, isCapture]);

  // //캡쳐
  const onCapture = () => {
    setTimeout(() => {
      setIsCapture(false);
    }, 1000);
    setIsCapture(true);
    setPadding(3);
  };

  return (
    <>
      <Helmet>
        <meta name="블로그 상세 분석" content="키워드에 해당하는 블로그를 분석할 수 있습니다." />
      </Helmet>
      <Loading open={isLoading || isCapture} />
      <Grid rowSpacing={5} container>
        <Grid item xs={12} ref={captureRef} sx={{ boxSizing: "border-box", p: padding }}>
          <Grid container rowSpacing={5}>
            <Grid item xs={12}>
              {/* 제목 */}
              <Stack gap={1}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack gap={1}>
                    <Typography fontWeight={900} variant="h5" color={MAIN_COLOR}>
                      <CustomIcon src={blogIcon} alt="blog-icon" width={"40px"} top="5px" />
                      블로그 키워드 상세 분석
                    </Typography>
                    <Typography variant="body1" fontWeight={600} color={DESCRIPTION_COLOR}>
                      키워드에 해당하는 블로그를 분석할 수 있습니다.
                    </Typography>
                  </Stack>
                  <Tooltip arrow placement="top" title={"화면캡쳐"}>
                    <IconButton onClick={onCapture} sx={{ width: "60px" }} disabled={!blogInfoLite} color="primary">
                      <CameraAltIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
                <SearchInput
                  placeholder="키워드를 입력해 주세요"
                  value={inputText}
                  onChange={handleInputChange}
                  onSubmit={handlePressEnter}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              {blogInfoLite && (
                <KeywordAnalysisData blogCount={blogCount} searchVolum={blogInfoLite?.relData} keyword={keyword} />
              )}
            </Grid>
            <Grid item xs={12}>
              {section && <SectionOrderPlacement data={section} />}
            </Grid>
            <Grid item xs={12}>
              {blogInfo && <TopBlogsAnalysis data={blogInfo?.items} />}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {blogInfo && <TopBlogsList data={blogInfo?.items} />}
        </Grid>
      </Grid>
    </>
  );
};

export default BlogKeywordAnalysis;

function getCurrentDateTime() {
  // 현재 날짜와 시간 객체 생성
  var currentDateTime = new Date();

  // 년, 월, 일 가져오기
  var year = currentDateTime.getFullYear();
  var month = currentDateTime.getMonth() + 1; // 월은 0부터 시작하므로 1을 더합니다.
  var day = currentDateTime.getDate();

  // 시, 분, 초 가져오기
  var hours = currentDateTime.getHours();
  var minutes = currentDateTime.getMinutes();
  var seconds = currentDateTime.getSeconds();

  // 현재 날짜와 시간 문자열로 반환
  var dateTimeString = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;

  return dateTimeString;
}
