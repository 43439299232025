import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Typography,
  Card,
  Pagination,
  Grid,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import * as React from "react";
import { visuallyHidden } from "@mui/utils";
import { MAIN_COLOR } from "../../config/color-config";
import { ComponentStyle } from "../keyword-analysis/common";
import { CSVLink } from "react-csv";
import SearchInput from "../input/SearchInput";
import SelectInput from "../input/SelectInput";
import { Data } from "../../service/admin/adminHook";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Data;
  label: string;
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              p: 1,
              pr: 0,
              whiteSpace: "nowrap",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              style={{
                color: orderBy === headCell.id ? MAIN_COLOR : "black",
                fontWeight: orderBy === headCell.id ? "bold" : "normal",
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface RelationKeywordTableProps {
  rows?: Data[];
}

//검색량 테이블 ----------------------------------------------------------------------------------------------------------------------------------------------------------------
const UserTable: React.FC<RelationKeywordTableProps> = ({ rows = [] }) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("name");
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [searchType, setSearchType] =
    React.useState<string>("스토어(블로그) 주소");

  const handleChangeSearchQuery = (e: any) => {
    const { value } = e.target;
    setSearchQuery(value);
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSearchType = (event: SelectChangeEvent) => {
    setSearchType(event.target.value);
  };

  const cellStyle = (index: number) => {
    return {
      backgroundColor: index % 2 === 0 ? "#fafafa" : "white",
      p: 1,
      pr: 0,
    };
  };

  //실제로 보이는 줄
  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy))
        // .filter((item) => item.name.includes(searchQuery))
        .filter((item) => item[SearchType[searchType]].includes(searchQuery))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, searchQuery, rows, searchType]
  );

  //엑셀 데이터
  const csvData = React.useMemo(() => {
    return (
      rows
        // .filter((item) => item.name.includes(searchQuery))
        .filter((item) => item[SearchType[searchType]].includes(searchQuery))
        .map((item) => {
          return [
            item.name,
            item.email,
            item.interest,
            item.path,
            item.signupDate,
            item.lastLoginDate,
          ];
        })
    );
  }, [searchQuery, rows, searchType]);

  return (
    <Card sx={{ ...ComponentStyle, width: "100%", pt: 5 }}>
      {!!rows && (
        <Grid container sx={{ width: "100%", p: 3 }} rowSpacing={1}>
          {/* 행변경 & 검색창 */}
          <Grid item xs={12} md={9}>
            <Stack direction="row" flexWrap={"wrap"}>
              <SelectInput
                list={[15, 30, 50]}
                value={rowsPerPage + ""}
                onChange={handleChangeRowsPerPage}
              />

              <SelectInput
                list={Object.keys(SearchType)}
                title="항목"
                width="220px"
                value={searchType + ""}
                onChange={handleChangeSearchType}
              />

              <SearchInput
                width="250px"
                onChange={handleChangeSearchQuery}
                value={searchQuery}
              />
            </Stack>
          </Grid>

          {/* 연관키워드 복사 & 엑셀 다운로드 */}
          <Grid item xs={12} md={3} sx={{ textAlign: "end" }}>
            <CSVLink data={csvData} headers={csvHeader} filename="유저목록.csv">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: MAIN_COLOR,
                  height: "50px",
                  borderRadius: "30px",
                }}
              >
                <SaveAltOutlinedIcon />
                엑셀 다운로드
              </Button>
            </CSVLink>
          </Grid>
          <Grid item xs={12} sx={{ width: "100%", mt: 2 }}>
            <Paper>
              <TableContainer>
                <Table aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell
                            sx={{ ...cellStyle(index)}}
                            component="th"
                            id={labelId}
                            scope="row"
                          >
                            {row.name}
                          </TableCell>
                          <TableCell sx={cellStyle(index)} align="left">
                            {row.email}
                          </TableCell>
                          <TableCell sx={cellStyle(index)} align="left">
                            {row.interest}
                          </TableCell>
                          <TableCell sx={cellStyle(index)} align="left">
                            {row.path}
                          </TableCell>
                          <TableCell sx={{...cellStyle(index), whiteSpace: "nowrap" }} align="left">
                            {row.signupDate}
                          </TableCell>
                          <TableCell sx={{...cellStyle(index), whiteSpace: "nowrap" }} align="left">
                            {row.lastLoginDate}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* 페이지 네이션 */}
              <Box
                sx={{
                  p: 4,
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Typography variant="body2">
                  전체 {visibleRows.length}개 중 {1 + page * rowsPerPage}~
                  {Math.min(
                    rows.filter((row) => row.name.includes(searchQuery)).length,
                    (page + 1) * rowsPerPage
                  )}
                </Typography>
                <Pagination
                  count={Math.ceil(
                    rows.filter((item) => item.name.includes(searchQuery))
                      .length / rowsPerPage
                  )}
                  onChange={handleChangePage}
                  color="primary"
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};

export default UserTable;

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    label: "스토어(블로그) 주소",
  },
  {
    id: "email",
    label: "이메일",
  },
  {
    id: "interest",
    label: "관심사",
  },
  {
    id: "path",
    label: "유입경로",
  },
  {
    id: "signupDate",
    label: "가입일",
  },
  {
    id: "lastLoginDate",
    label: "최근 접속일자",
  },
];

const csvHeader = [
  "스토어(블로그) 주소",
  "이메일",
  "관심사",
  "유입경로",
  "가입일",
  "최근접속일자",
];

const SearchType: { [key: string]: keyof Data } = {
  "스토어(블로그) 주소": "name",
  유입경로: "path",
  관심사: "interest",
  이메일: "email",
};
