import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; //
import { Card, Stack, Grid, Button, ListItemText } from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";
import TitleAndDescription from "../title-and-description/TitleAndDescription";
import { MAIN_COLOR, getColor } from "../../config/color-config";
import { useEffect, useMemo, useState } from "react";
import { cntDataType } from "../../service/keywordAnalysis/keywordAnalysisHooks";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import { DateRangePicker } from "react-date-range";

// function valueFormatter(date: Date) {
//   const year = date.getFullYear();
//   const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 +1 필요
//   const day = String(date.getDate()).padStart(2, "0");

//   return `${year}-${month}-${day}`;
// }

//버튼 스타일
const buttonBackgroundColor = "#ddf1fe";
const ButtonStyle = (active: boolean) => {
  return {
    borderRadius: "30px",
    fontWeight: "bold",
    backgroundColor: active ? MAIN_COLOR : buttonBackgroundColor,
    color: active ? "white" : MAIN_COLOR,
    "&:hover": { backgroundColor: MAIN_COLOR, color: "white" },
  };
};

interface SearchMountGraphProps {
  data: cntDataType | undefined;
}

const SearchAmountGraph: React.FC<SearchMountGraphProps> = ({ data }) => {
  const [dateType, setDateType] = useState<"week" | "month" | "year">("week");
  const [YYYYMMDDArray, setYYYYMMDDArray] = useState<string[]>([]);
  const [MMDDArray, setMMDDArray] = useState<string[]>([]);

  useEffect(() => {
    setYYYYMMDDArray(generateDateArray(1, dateType));
    setMMDDArray(generateDateArray(2, dateType));
  }, [dateType]);

  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // const [term, setTerm] = useState({
  //   startDate: new Date(),
  //   endDate: new Date(),
  //   key: "selection",
  // });

  // const handleDateSelect = (e: any) => {
  //   setTerm(e.selection);
  // };

  //날짜 범위 변경
  const handleDateTypeChange = (value: any) => {
    setDateType(value);
  };
  // const open = Boolean(anchorEl);
  // const handleOpenTermForm = (event: any) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleCloseTermForm = () => {
  //   setAnchorEl(null);
  // };

  // 날짜가 들어갈 배열 만들기 (string 배열이 들어가면 오류가 나기 때문에, 숫자배열을 만들어 넣어두고 각 배열에 맞는 날짜를 나중에 넣어줌 )
  const { days, keywords } = useMemo(() => {
    let keywords: string[] = [];
    if (!!data) {
      keywords = Object.keys(data);
    }

    const days = [];
    for (let i = 0; i < YYYYMMDDArray.length; i++) {
      days.push(i);
    }
    return { days, keywords };
  }, [YYYYMMDDArray, data]);

  return (
    <>
      <Card>
        <Grid container alignItems={"center"}>
          <Grid item xs={12}>
            <TitleAndDescription
              title={description.title}
              content={description.content}
            />
          </Grid>
          {!!data && (
            <>
              <Grid
                item
                xs={12}
                sx={{ p: 3, display: "flex", flexDirection: "row-reverse" }}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"end"}
                  sx={{
                    // width: "215px",
                    borderRadius: "30px",
                    backgroundColor: buttonBackgroundColor,
                  }}
                >
                  <Button
                    sx={ButtonStyle(dateType === "week")}
                    onClick={() => {
                      handleDateTypeChange("week");
                    }}
                  >
                    <ListItemText>최근 1주</ListItemText>
                  </Button>
                  <Button
                    sx={ButtonStyle(dateType === "month")}
                    onClick={() => {
                      handleDateTypeChange("month");
                    }}
                  >
                    <ListItemText>최근 1달</ListItemText>
                  </Button>
                  <Button
                    sx={ButtonStyle(dateType === "year")}
                    onClick={() => {
                      handleDateTypeChange("year");
                    }}
                  >
                    <ListItemText>최근 1년</ListItemText>
                  </Button>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <LineChart
                  xAxis={[
                    {
                      data: days ? days : [], // x축 범위 데이터
                      scaleType: "point",
                      valueFormatter: (item) => {
                        //날짜 포멧 지정하기
                        return MMDDArray[item];
                      },
                      tickInterval: (value, index) => {
                        return tickIntervalFunction(index, dateType);
                      },
                      tickLabelStyle: {
                        angle: -45,
                        textAnchor: "end",
                        fontSize: 12,
                      },
                    },
                  ]}
                  series={keywords.map((keyword, i) => ({
                    curve: "linear",
                    id: keyword,
                    label: keyword, // hover했을 때 나오는 그래프 이름
                    data: YYYYMMDDArray.map((item) => {
                      return data[keyword][item] ? data[keyword][item] : 0;
                    }), //입력할 데이터
                    showMark: false, // data 마다 점찍기
                    color: getColor(i), // 그래프 색상
                  }))}
                  height={400} // 높이
                  margin={{ left: 70, top: 50, bottom: 80 }}
                />
              </Grid>
            </>
          )}
          {/* <Grid item xs={4}>
            <Button
              onClick={handleOpenTermForm}
              sx={{ width: "80%", pr: 1, mr: 1 }}
              variant="outlined"
            >
              <CalendarMonthIcon />
              &nbsp;
              {formatDate(term.startDate)} ~ {formatDate(term.endDate)}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseTermForm}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <DateRangePicker ranges={[term]} onChange={handleDateSelect} />  
            </Menu>
          </Grid>  */}
        </Grid>
      </Card>
    </>
  );
};

export default SearchAmountGraph;

//임시 데이터들
const description = {
  title: "검색량 그래프",
  content: [
    "키워드 검색량을 그래프로 나타냅니다.",
    "날짜와 일/월/연별로 선택해 그래프를 볼 수 있습니다.",
  ],
};

//키 값을 만들어 내는 날짜 포멧
function getFormattedDate(date: Date, type: number) {
  const year = String(date.getFullYear());
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  if (type === 1) {
    return `${year}-${month}-${day}`;
  } else {
    return `${month} / ${day}`;
  }
}

//지날달 부터 이번달까지의 날짜 배열을 생성하는 함수
export function generateDateArray(type: number, dateType: string) {
  const currentDate = new Date();
  const startDate = new Date();
  startDate.setFullYear(currentDate.getFullYear() - 1); // 한 달 전 날짜로 설정
  startDate.setDate(startDate.getDate() - 1); // 한 달 전의 날짜에서 하루를 빼줌

  const dateArray = [];
  while (startDate < currentDate) {
    dateArray.push(getFormattedDate(startDate, type));
    startDate.setDate(startDate.getDate() + 1); // 다음 날짜로 이동
  }

  if (dateType === "week") {
    return dateArray.slice(-7);
  } else if (dateType === "month") {
    return dateArray.slice(-32);
  } else {
    return dateArray;
  }
}

function tickIntervalFunction(index: number, dateType: string) {
  switch (dateType) {
    case "week":
      return index % 1 === 0;
    case "month":
      return index % 3 === 0;
    default:
      return index % 20 === 0;
  }
}
