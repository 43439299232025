import { create } from "zustand";

interface OpenMembershipPopupStateType {
  openMemberShipPopup: boolean;
  open: () => void;
  close: () => void;
}

export const openMembershipPopupState = create<OpenMembershipPopupStateType>()((set) => ({
  openMemberShipPopup: false,
  open: () => set({ openMemberShipPopup: true }),
  close: () => set({ openMemberShipPopup: false }),
}));

export const openMemberShipPopup = openMembershipPopupState.getState().open;

