import { Grid, Typography, Stack } from "@mui/material";
import { DESCRIPTION_COLOR, MAIN_COLOR } from "../config/color-config";
import RelatedKeywordsList from "../component/blog-keyword-analysis-lite/RelatedKeywordsList";
import RelatedKeywordsTable from "../component/blog-keyword-analysis-lite/RelatedKeywordsTable";
import Loading from "../component/loading/Loading";
import { ChangeEvent, useState } from "react";
import {
  blogItemType,
  useBlogInfoLiteMutation,
  useBlogInfoOnlyItemsMutation,
} from "../service/blogKeywordAnalysisLite/blogKeywordAnalysisLiteHooks";
import { reg } from "../config/regular-expression";
import { Helmet } from "react-helmet";
import SearchInput from "../component/input/SearchInput";
import { CustomIcon } from "../component/side-nav/config";
import blogIcon from "../asset/image/blog-icon-black.png";

export type blogDetailListType = {
  keyword: string;
  pc: number;
  mobile: number;
  total: number;
  productCount: number;
  rate: number;
  items: blogItemType[];
};

const BlogKeywordAnalysisLite: React.FC = () => {
  const [inputText, setInputText] = useState<string>("");
  const [blogList, setBlogList] = useState<blogDetailListType[]>([]);
  const { mutateAsync: getBlogInfoOnlyItems, isPending: isPending1 } =
    useBlogInfoOnlyItemsMutation(); // item만 가져오기
  const {
    data: blogInfoLite,
    mutateAsync: getBlogInfo,
    isPending: isPending2,
  } = useBlogInfoLiteMutation(); //전부다 가져오기

  //검색창에서 검색했을 경우
  const onSubmit = async (keyword: string) => {
    //연관 키워드 불러오기
    const data = await getBlogInfo(keyword);
    if (!!data) {
      setBlogList((prev) => {
        if (prev.filter((item) => item.keyword === keyword).length === 0) {
          const pc = Number(
            typeof data.relData[keyword].monthlyPcQcCnt === "string"
              ? 10
              : data.relData[keyword].monthlyPcQcCnt
          );
          const mobile = Number(
            typeof data.relData[keyword].monthlyMobileQcCnt === "string"
              ? 10
              : data.relData[keyword].monthlyMobileQcCnt
          );
          return [
            ...prev,
            {
              keyword,
              pc,
              mobile,
              total: pc + mobile,
              productCount: data.total,
              rate: data.total / (pc + mobile),
              items: data.items,
            },
          ];
        } else {
          return prev;
        }
      });
    }
  };

  //연관 키워드 => 테이블에 추가하기
  const handleAddKeyword = async (keyword: string) => {
    const arr = blogList.filter((item) => item.keyword === keyword);
    if (arr.length === 0) {
      const data = await getBlogInfoOnlyItems(keyword);
      if (!!data && !!blogInfoLite) {
        setBlogList((prev) => {
          const pc = Number(
            typeof blogInfoLite.relData[keyword].monthlyPcQcCnt === "string"
              ? 10
              : blogInfoLite.relData[keyword].monthlyPcQcCnt
          );
          const mobile = Number(
            typeof blogInfoLite.relData[keyword].monthlyMobileQcCnt === "string"
              ? 10
              : blogInfoLite.relData[keyword].monthlyMobileQcCnt
          );
          return [
            ...prev,
            {
              keyword,
              pc,
              mobile,
              total: pc + mobile,
              productCount: data.total,
              rate: data.total / (pc + mobile),
              items: data.items,
            },
          ];
        });
      }
    }
  };

  //연관 키워드에서 제거
  const handleDeleteKeywordDetail = (keyword: string) => {
    setBlogList((prev) => {
      return prev.filter((item) => item.keyword !== keyword);
    });
  };

  //input 값 변경
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value.toUpperCase());
  };

  //enter 누를 때 발생하는 일
  const handleSubmit = () => {
    const value = inputText.replaceAll(reg, "");
    setInputText(value);
    if (!!value) {
      onSubmit(value);
    }
  };

  return (
    <>
      <Helmet>
        <meta
          name="블로그 키워드 간편 분석"
          content="대량의 키워드를 블로그 검색량을 통해 더욱 간편하게 분석할 수 있습니다."
        />
      </Helmet>
      <Loading open={isPending2 || isPending1} />
      <Grid rowSpacing={5} container>
        <Grid item xs={12}>
          {/* 제목 */}
          <Stack gap={1}>
            <Typography fontWeight={900} variant="h5" color={MAIN_COLOR}>
              <CustomIcon
                src={blogIcon}
                alt="blog-icon"
                width={"40px"}
                top="5px"
              />
              블로그 키워드 간편 분석
            </Typography>
            <Typography
              variant="body1"
              fontWeight={600}
              color={DESCRIPTION_COLOR}
            >
              키워드에 해당하는 블로그를 분석할 수 있습니다.
            </Typography>

            <SearchInput
              placeholder="키워드를 입력해 주세요"
              value={inputText}
              onChange={handleInputChange}
              onSubmit={handleSubmit}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <RelatedKeywordsList
            blogInfoLite={blogInfoLite}
            handleAddKeyword={handleAddKeyword}
          />
        </Grid>
        <Grid item xs={12}>
          <RelatedKeywordsTable
            handleDeleteKeywordDetail={handleDeleteKeywordDetail}
            rows={blogList}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BlogKeywordAnalysisLite;
  