  import { Card, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import BannerList from "../component/admin/BannerList";
import UserTable from "../component/admin/UserTable";
import { MAIN_COLOR } from "../config/color-config";
import { userInfoState } from "../store";
import { useNavigate } from "react-router-dom";
import { useUserListQuery } from "../service/admin/adminHook";
import { useQueryClient } from "@tanstack/react-query";

const Admin: React.FC = () => {
  const { data: userList } = useUserListQuery();
  const { userInfo } = userInfoState();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  useEffect(() => {
    //관리자 아니면 보내버리기
    queryClient.invalidateQueries();
    if (!userInfo.isAdmin) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Stack alignItems={"center"} gap={4}>
        <Card sx={cardStyle}>
          <Typography variant="h5" sx={titleStyle}>
            광고 목록
          </Typography>
          <Typography variant="body2"sx={{pl: 1,}}>배너의 비율은 폭과 높이가 각각 가로세로 1:2 비율로 구성되어 있습니다.</Typography>
          <BannerList />
        </Card>
        <Card sx={cardStyle}>
          <Typography variant="h5" sx={{ ...titleStyle }}>
            전체 유저 목록
          </Typography>
          <UserTable rows={userList ? userList : []} />
        </Card>
      </Stack>
    </>
  );
};

const cardStyle = { width: "100%", p: 3, boxSizing: "border-box" };

export default Admin;

const titleStyle = {
  color: MAIN_COLOR,
  textAlign: "start",
  width: "100%",
  p: 1,
  boxSizing: "border-box",
  fontWeight: "bold",
};
