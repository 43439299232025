import { customAxios } from "../axios-config";
export const blogKeywordAnalysisLiteAPI = {
  // 키워드 기본 정보
  getBlogInfoLite: async (keywords: string) => {
    return customAxios
      .post("blog_lite", { keywords, type: "all" })
      .then((res) => res.data);
  },
  getBlogInfoOnlyItems: async (keywords: string) => {
    return customAxios
      .post("blog_lite", { keywords })
      .then((res) => res.data);
  },
};
