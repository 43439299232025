import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface IsLoginStateType {
  isLogin: boolean;
  check: () => void;
  unCheck: () => void;
}

export const isLoginState = create(
  persist<IsLoginStateType>(
    (set, get) => ({
      isLogin: false,
      check: () => {
        set({ isLogin: true });
      },
      unCheck: () => {
        set({ isLogin: false });
      },
    }),
    {
      name: "IS_LOGIN",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const isLogin = isLoginState.getState().isLogin;
export const checkIsLogin = isLoginState.getState().check;
export const unCheckIsLogin = isLoginState.getState().unCheck;