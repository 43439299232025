import axios from "axios";
import { customAxios } from "../axios-config";

export const AdminAPI = {
  getUserList: async () => {
    const API = "get_all_user";
    return await customAxios.get(API).then((res) => res.data);
  },
};

export const BannerAPI = {
  createBanner: async (body: FormData) => {
    const API = "create_image";
    return await customAxios.post(API, body).then((res) => res.data);
  },
  deleteBanner: async (id: string) => {
    const API = "delete_image";
    const body = {
      id: id,
    };
    return await customAxios
      .delete(API, { data: body }) // 어거지로 body 넣은것
      .then((res) => res.data);
  },
  getBannerList:async () =>{
    const API = "https://셀러원정대.com/api/get_image_list";
    return await axios.get(API).then(res => res.data)
  }
};
