import {
  Button,
  Card,
  Stack,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Box,
  Dialog,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  IconButton,
  Chip,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { DESCRIPTION_COLOR, MAIN_COLOR } from "../config/color-config";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthAPI } from "../service/auth/authAxios";
import { useSignUpMutation } from "../service/user/userHook";
import CheckIcon from "@mui/icons-material/Check";

import { TERMS_OF_SERVICE, PRIVACY_POLICY, MARKETING_CONSENT } from "../asset/text/text";

//비밀번호 검사
const authenticator = {
  email: (value: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(value);
  },
  password1: (value: string) => {
    const len = value.length;
    return len <= 20 && len >= 8;
  },
  password2: (value: string) => {
    //공백 혹은 특수문자가 있는지 확인
    const specialCharRegex = /\s/;
    return !specialCharRegex.test(value);
  },
  password3: (value: string) => {
    // 숫자 확인
    const numberRegex = /\d/;
    return numberRegex.test(value);
  },
  password4: (value: string) => {
    // 문자 확인
    const alphabetRegex = /[a-zA-Z]/;
    return alphabetRegex.test(value);
  },
  passwordConfirm: (a: string, b: string) => {
    return a === b;
  },
  totalPassword: (value: string) => {
    return (
      authenticator.password1(value) &&
      authenticator.password2(value) &&
      authenticator.password3(value) &&
      authenticator.password4(value)
    );
  },
};

type ListType = {
  title: string;
  isCheck: boolean;
};

const interestList: ListType[] = [
  {
    title: "블로그",
    isCheck: false,
  },
  {
    title: "온라인 마케팅",
    isCheck: false,
  },
  {
    title: "온라인 쇼핑",
    isCheck: false,
  },
  {
    title: "광고",
    isCheck: false,
  },
  {
    title: "유튜브",
    isCheck: false,
  },
  {
    title: "트렌드",
    isCheck: false,
  },
  {
    title: "스마트 스토어",
    isCheck: false,
  },
];

const pathList: ListType[] = [
  {
    title: "광고",
    isCheck: false,
  },
  {
    title: "SNS",
    isCheck: false,
  },
  {
    title: "유튜브",
    isCheck: false,
  },
  {
    title: "블로그",
    isCheck: false,
  },
  {
    title: "검색",
    isCheck: false,
  },
  {
    title: "인스타그램",
    isCheck: false,
  },
  {
    title: "페이스북",
    isCheck: false,
  },
];

const COUNTDOWN_TIME = 300;
let timerInterval: any; // 타이머 전역변수로 설정 (이래야지 중복안됨)

const SignUp: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isCheckId, setIsCheckId] = useState<boolean>(false);
  const [sendAuthCode, setSendAuthCode] = useState<boolean>(false); // 인즈코드 발송 여부
  const [authCodeConfirm, setAuthCodeConfirm] = useState<boolean>(false); // 인증코드 인증여부
  const [isAuthCodeWrong, setIsAuthCodeWrong] = useState<boolean>(false); // 인증코드 틀렸을 때 error input만드는 용도
  const [authCodeDisable, setAuthCodeFormDisable] = useState<boolean>(true);
  const [authCode, setAuthCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [path, setPath] = useState<ListType[]>(pathList);
  const [interest, setInterest] = useState<ListType[]>(interestList);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [termOfService, setTermOfService] = useState<boolean>(false);
  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);
  const [marketingConsent, setMarketingConsent] = useState<boolean>(false);
  const [countdown, setCountdown] = useState(COUNTDOWN_TIME); //타이머
  const navigate = useNavigate();
  const [sendingEmail, setSendingEmail] = useState<boolean>(false);

  //  hook
  const { mutate: signUp } = useSignUpMutation();

  const goBack = () => {
    navigate(-1);
  };

  //username 중복 확인함수
  const handleCheckUsername = () => {
    if (authenticator.email(email)) {
      AuthAPI.checkId(email).then((res: any) => {
        setIsCheckId(!res.data);
        if (!res.data) {
          alert("사용가능한 아이디 입니다.");
        } else {
          alert("이미 사용중인 아이디 입니다.");
        }
      });
    } else {
      alert("유효지 않은 이메일입니다. 다시 확인해주세요.");
    }
  };

  //이메일로 인증코드 전송하는 함수
  const handleSendEmail = () => {
    setSendingEmail(true);
    AuthAPI.sendEmail(email).then((res: any) => {
      const result = res.data;
      if (result) {
        setSendingEmail(false);
        //이메일 보내기 성공
        alert(`${email}로 인증 코드를 발송하였습니다.`);
        clearInterval(timerInterval); //타이머 초기화
        setSendAuthCode(true); //인증코드 발송
        setAuthCodeFormDisable(false); //인증코드 input 비활성화 해제
        setCountdown(COUNTDOWN_TIME); //타이머 원상복귀
        setAuthCode(""); //인증번호 input 초기화
        startTimer();
      } else {
        setSendingEmail(false);
        //이메일 보내기 실패
        alert("인증코드 전송 실패하였습니다. 잠시후 다시 시도해주세요.");
      }
    });
  };

  //이름 회사명
  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setName(value);
  };

  //5분 타이머
  const startTimer = () => {
    clearInterval(timerInterval);
    timerInterval = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 0) {
          clearInterval(timerInterval);
          return prev;
        }
        return prev - 1;
      });
    }, 1000);
  };

  //비밀번호 보이게 하기
  const handleChangeShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  //countdown을 통해 남은시간 보여주기
  const showTimer = () => {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;

    return `${minutes}분 ${seconds}초`;
  };

  //인증코드 확인함수
  const handleCheckCode = () => {
    AuthAPI.checkCode(email, authCode).then((res) => {
      if (typeof res.data === "string") {
        alert(res.data);
      } else if (res.data) {
        //성공했을 경우
        alert("이메일 인증을 완료했습니다!");
        clearInterval(timerInterval); // 타이머 초기화
        setAuthCodeConfirm(true);
        setAuthCodeFormDisable(true); // 인증코드 적는 form 비활성화
        setSendAuthCode(false); //인증코드를 보냈다는 메시지 제거
      } else {
        //실패했을경우
        setIsAuthCodeWrong(true); //인증코드가 틀리면 이게 true가 되어야함
      }
    });
  };

  //   이메일 변경
  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAuthCodeConfirm(false); //인증 안됨으로 변경
    setSendAuthCode(false); //비밀번호 발송 안된걸로 변경
    setIsCheckId(false); // 이메일 보낸거 초기화됨
    setAuthCode(""); //입력한 인증코드 초기화
    setEmail(value);
  };

  //   비밀번호 변경
  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPassword(value);
  };

  //   비밀번호확인 변경
  const handleChangePasswordConfirm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPasswordConfirm(value);
  };
  //   인증번호 변경
  const handleChangeAuthCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAuthCode(value);
  };

  // 유입경로 변경
  const handleChangePath = (index: number) => {
    setPath((prev: ListType[]) => {
      const newPrev = [...prev];
      newPrev[index].isCheck = !newPrev[index].isCheck;
      return newPrev;
    });
  };

  // 관심사
  const handleChangeInterest = (index: number) => {
    setInterest((prev: ListType[]) => {
      const newPrev = [...prev];
      newPrev[index].isCheck = !newPrev[index].isCheck;
      return newPrev;
    });
  };

  //   이용약관
  const handleChangeTermOfService = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermOfService(event.target.checked);
  };

  //   개인정보이용동의
  const handleChangePrivacyPolicy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrivacyPolicy(event.target.checked);
  };

  //   마케팅수신동이
  const handleChangeMarketingConsent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarketingConsent(event.target.checked);
  };

  const handleSubmit = () => {
    if (!isCheckId) {
      alert("이메일 중복을 확인해주세요.");
    } else if (!authCodeConfirm && false) {
      alert("이메일을 인증해주세요.");
    } else if (!authenticator.totalPassword(password) || !authenticator.passwordConfirm(password, passwordConfirm)) {
      alert("비밀번호를 확인해주세요.");
    } else if (!termOfService) {
      alert("이용약관 동의해주세요");
    } else if (!privacyPolicy) {
      alert("개인정보 처리 방침에 동의해주세요");
    } else if (!marketingConsent) {
      alert("마케팅 정보 수신에 동의해주세요");
    } else if (!name) {
      alert("스토어(블로그) 주소를 입력해주세요");
    } else {
      signUp({
        username: email,
        password,
        firstName: name,
        //문자열로 변환
        funnels: path
          .map(({ isCheck, title }) => {
            return isCheck ? title : "";
          })
          .filter(Boolean)
          .join(","),
        //문자열로 변환
        interest: interest
          .map(({ isCheck, title }) => {
            return isCheck ? title : "";
          })
          .filter(Boolean)
          .join(","),
      });
    }
  };

  return (
    <>
      <Card sx={{ maxWidth: "400px", margin: "0 auto", my: 10, p: 3 }}>
        <Stack gap={5}>
          <Typography variant="h5" color={MAIN_COLOR} fontWeight={"bold"} sx={{ my: 3 }} textAlign={"center"}>
            회원가입
          </Typography>
          {/* 이메일 == 아이디 입력 */}
          <Box sx={{ position: "relative" }}>
            <Stack direction={"row"} gap={1}>
              <TextField
                fullWidth
                type="email"
                label="이메일"
                id="email"
                required
                error={!!email && !authenticator.email(email)}
                onChange={handleChangeEmail}
                value={email}
              />
              {isCheckId ? (
                <Button
                  // onClick={handleSendEmail}  //인증코드 클릭하는 버튼
                  variant="outlined"
                  sx={{
                    width: "180px",
                    border: "1px solid grey",
                    color: "primary",
                  }}
                  disabled={!authenticator.email(email)}
                  endIcon={<CheckIcon color="primary" />}
                >
                  {/* {sendingEmail ? "전송중.. " : "인증코드발송"} */}
                  중복확인완료
                </Button>
              ) : (
                <Button
                  onClick={handleCheckUsername}
                  variant="outlined"
                  sx={{
                    width: "220px",
                    border: "1px solid grey",
                    color: "grey",
                  }}
                >
                  {" "}
                  아이디 중복 확인{" "}
                </Button>
              )}
            </Stack>
            {sendAuthCode && (
              <Typography sx={{ position: "absolute", bottom: "-20px" }} variant="caption" color="primary">
                인증코드가 이메일로 전송되었습니다.
              </Typography>
            )}
          </Box>

          {/* 인증번호 입력 */}
          {/* <Box sx={{ position: "relative" }}>
            <Stack direction={"row"} gap={1}>
              <TextField
                fullWidth
                type="email"
                label="인증번호입력"
                required
                id="authCode"
                onChange={handleChangeAuthCode}
                value={authCode}
                error={isAuthCodeWrong}
                disabled={authCodeDisable}
              />
              <Button
                variant="contained"
                disabled={authCodeDisable}
                sx={{ width: "150px", backgroundColor: MAIN_COLOR }}
                onClick={handleCheckCode}
              >
                {authCodeConfirm ? "인증완료" : "인증하기"}
              </Button>
            </Stack>
            {!authCodeDisable && (
              <Typography
                variant="caption"
                color="error"
                sx={{ position: "absolute", bottom: "-20px" }}
              >
                {showTimer()}
              </Typography>
            )}
          </Box> */}

          {/* 비밀번호 */}
          <Box sx={{ position: "relative" }}>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={handleChangeShowPassword}>
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              type={showPassword ? "text" : "password"}
              label="비밀번호"
              required
              id="password"
              onChange={handleChangePassword}
              value={password}
              fullWidth
            />
            {!!password && (
              <Stack direction="row" gap={2} sx={{ position: "absolute", bottom: "-20px" }}>
                <Typography variant="caption" color={authenticator.password1(password) ? "primary" : "error"}>
                  비밀번호 길이 8이상 20이하*
                </Typography>
                <Typography variant="caption" color={authenticator.password2(password) ? "primary" : "error"}>
                  공백(X)*
                </Typography>
                <Typography variant="caption" color={authenticator.password3(password) ? "primary" : "error"}>
                  숫자*
                </Typography>
                <Typography variant="caption" color={authenticator.password4(password) ? "primary" : "error"}>
                  영문*
                </Typography>
              </Stack>
            )}
          </Box>
          {/* 비밀번호 확인 */}
          <Box sx={{ position: "relative" }}>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={handleChangeShowPassword}>
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              type={showPassword ? "text" : "password"}
              label="비밀번호확인"
              required
              id="passwordConfirm"
              error={!!passwordConfirm && !authenticator.passwordConfirm(password, passwordConfirm)}
              onChange={handleChangePasswordConfirm}
              value={passwordConfirm}
              fullWidth
            />
            {passwordConfirm && (
              <Typography
                sx={{ position: "absolute", bottom: "-20px", left: "0px" }}
                variant="caption"
                color={authenticator.passwordConfirm(password, passwordConfirm) ? "primary" : "error"}
              >
                {authenticator.passwordConfirm(password, passwordConfirm)
                  ? "비밀번호가 일치합니다."
                  : "비밀번호가 일치하지 않습니다."}
              </Typography>
            )}
          </Box>

          {/* 회사명  */}
          <TextField
            type="text"
            label="스토어(블로그) 주소"
            required
            id="name"
            onChange={handleChangeName}
            value={name}
            fullWidth
          />

          {/* 유입경로 */}
          <Box>
            <Typography variant="subtitle1" fontWeight={"bold"}>
              유입경로
            </Typography>
            <Typography variant="caption" color="grey">
              해당하는 항목을 모두 선택해 주세요.
            </Typography>
            <Stack direction="row" flexWrap={"wrap"} gap={1} sx={{ width: "300px", mt: 2 }}>
              {interest.map(({ title, isCheck }, i) => (
                <Chip
                  key={i}
                  onClick={() => {
                    handleChangeInterest(i);
                  }}
                  label={title}
                  color={isCheck ? "primary" : "default"}
                  variant={isCheck ? "filled" : "outlined"}
                />
              ))}
            </Stack>
          </Box>

          {/* 관심사 */}
          <Box>
            <Typography variant="subtitle1" fontWeight={"bold"}>
              관심사
            </Typography>
            <Typography variant="caption" color="grey">
              해당하는 항목을 모두 선택해 주세요.
            </Typography>
            <Stack direction="row" flexWrap={"wrap"} gap={1} sx={{ width: "260px", mt: 2 }}>
              {path.map(({ title, isCheck }, i) => (
                <Chip
                  onClick={() => {
                    handleChangePath(i);
                  }}
                  label={title}
                  color={isCheck ? "primary" : "default"}
                  variant={isCheck ? "filled" : "outlined"}
                />
              ))}
            </Stack>
          </Box>

          <Stack gap={1}>
            {/* 이용약관 */}
            <Stack direction="row" justifyContent={"space-between"}>
              <FormControlLabel
                required
                control={<Checkbox onChange={handleChangeTermOfService} checked={termOfService} />}
                label={TERMS_OF_SERVICE.title}
              />
              <ShowAgreement title={TERMS_OF_SERVICE.title} description={TERMS_OF_SERVICE.description} />
            </Stack>
            {/* 개인정보이용동의 */}
            <Stack direction="row" justifyContent={"space-between"}>
              <FormControlLabel
                required
                control={<Checkbox checked={privacyPolicy} onChange={handleChangePrivacyPolicy} />}
                label={PRIVACY_POLICY.title}
              />
              <ShowAgreement title={PRIVACY_POLICY.title} description={PRIVACY_POLICY.description} />
            </Stack>
            {/* 마케팅수신동의 */}
            <Stack direction="row" justifyContent={"space-between"}>
              <FormControlLabel
                required
                control={<Checkbox checked={marketingConsent} onChange={handleChangeMarketingConsent} />}
                label={MARKETING_CONSENT.title}
              />
              <ShowAgreement title={MARKETING_CONSENT.title} description={MARKETING_CONSENT.description} />
            </Stack>
          </Stack>

          <Stack direction="row" gap={2} sx={{ my: 4 }}>
            <Button
              fullWidth
              variant="outlined"
              sx={{
                border: `1px solid ${MAIN_COLOR}`,
                color: MAIN_COLOR,
                height: "50px",
              }}
              onClick={goBack}
            >
              뒤로가기
            </Button>
            <Button
              fullWidth
              variant="contained"
              sx={{ backgroundColor: MAIN_COLOR, height: "50px" }}
              onClick={handleSubmit}
            >
              회원가입
            </Button>
          </Stack>
        </Stack>
      </Card>
    </>
  );
};

export default SignUp;

interface ShowAgreementProps {
  title: string;
  description: string;
}
const ShowAgreement: React.FC<ShowAgreementProps> = ({ title, description }) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClickOpen} sx={{ color: DESCRIPTION_COLOR }}>
        보기
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"body"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          <pre
            style={{
              width: "600px",
              fontSize: "14px",
              whiteSpace: "pre-line",
              padding: "10px",
              boxSizing: "border-box",
            }}
          >
            {description}
          </pre>
        </DialogContentText>
      </Dialog>
    </>
  );
};
