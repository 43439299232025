import {
  Box,
  Typography,
  Button,
  Grid,
  useMediaQuery,
  OutlinedInput,
} from "@mui/material";
import React, { ChangeEvent, useCallback, useState } from "react";
import KeywordInfo from "../component/keyword-analysis/KeywordInfo";
import RecentSearchVolume from "../component/keyword-analysis/RecentSearchVolume";
import SearchAmountGraph from "../component/keyword-analysis/SearchAmountGraph";
// import SearchAmountTable from "../component/keyword-analysis/SearchAmountTable";
import MonthlySearchRatio from "../component/keyword-analysis/MonthlySearchRatio";
import WeeklySearchRatio from "../component/keyword-analysis/WeeklySearchRatio";
import AgeSearchRatio from "../component/keyword-analysis/AgeSearchRatio";
import GenderAndDeviceSearchRatio from "../component/keyword-analysis/GenderAndDeviceSearchRatio";
import RelationKeywordTable from "../component/keyword-analysis/RelationKeywordTable";
import Loading from "../component/loading/Loading";
import {
  useAgeRateMutation,
  useGenderRateMutation,
  useKeywordInfoMutation,
  useKeywordToolsMutation,
} from "../service/keywordAnalysis/keywordAnalysisHooks";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { reg } from "../config/regular-expression";
import { Helmet } from "react-helmet";
import SearchAndBanner from "../component/blog-keyword-analysis/SearchAndBanner";
import { CustomIcon } from "../component/side-nav/config";
import keywordICon from "../asset/image/keyword-icon-main.png";
import { DESCRIPTION_COLOR, MAIN_COLOR } from "../config/color-config";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@mui/material/styles";

const KeywordAnalysis: React.FC = () => {
  const { state } = useLocation();
  const theme = useTheme();
  const [pageChange, setPageChange] = useState<boolean>(false);
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  // const lgUp = useMediaQuery("(min-width:1200px)");
  const [inputValue, setInputValue] = useState<string>("");
  const [keywords, setKeywords] = useState<string[]>([]); // props로 내리는 용도
  const {
    data: keywordInfo,
    mutateAsync: mutation1,
    isPending: isPendding1,
  } = useKeywordInfoMutation();
  const {
    data: keywordTools,
    mutateAsync: mutation2,
    isPending: isPendding2,
  } = useKeywordToolsMutation();
  const {
    data: ageRate,
    mutateAsync: mutation3,
    isPending: isPendding3,
  } = useAgeRateMutation();
  const {
    data: genderRate,
    mutateAsync: mutation4,
    isPending: isPendding4,
  } = useGenderRateMutation();

  //input 입력 받기
  const handeInputValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const arr = value.replaceAll(" ", "").split(",").filter(Boolean);
    if (arr.length > 5) {
      alert("키워드는 5개까지 입력할 수 있습니다.");
    } else {
      setInputValue(value.toUpperCase());
    }
  };

  //엔터 누르기
  const handleSubmit = async (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      if (!e.nativeEvent.isComposing) {
        if (!!inputValue.replaceAll("\n", "")) onSubmit(inputValue);
      }
    }
  };

  //키워드 가공해서 제공하기
  //매개변수를 받는 이유는 연관 키워드 테이블에서 사용할수 있게 하기 위함
  const onSubmit = useCallback(
    async (text: string) => {
      if (!!text) {
        const keywords: string[] = text
          .replaceAll(" ", "")
          .split(",") //배열로 변경
          .map((item) => item.replace(reg, "").replaceAll("\n", "")) // 특수문자 제거
          .filter(Boolean) //빈값 삭제
          .slice(0, 5); // 1~5번째 까지만

        const query: string = keywords.join(",");
        //함수 실행
        setKeywords([]);
        // try {
          await mutation1(query);
          const data = await mutation2(query);
          await mutation3(query);
          await mutation4(query);

          if (!!data && !!data.cntData) {
            const keywordarr = Object.keys(data.cntData);
            setKeywords(keywordarr);
            setInputValue(keywordarr.join(",")); // 올바른 값으로 변경시킴
          }
          window.scrollTo(0, 0);
          setPageChange(true);
        // } catch (err) {
        //   alert(`잘못된 키워드 입니다. 다시 입력해 주세요.`);
        // }
      }
    },
    [mutation1, mutation2, mutation3, mutation4]
  );

  useEffect(() => {
    if (!!state && !!state.keyword) {
      onSubmit(state.keyword);
    }
  }, [state, onSubmit]);

  if (!keywordInfo && !pageChange) {
    return (
      <SearchAndBanner
        value={inputValue}
        onChange={handeInputValueChange}
        onSubmit={()=>{onSubmit(inputValue)}}
      />
    ); 
  } else {
    return (
      <>
        <Helmet>
          <meta
            name="키워드 상세 분석"
            content="키워드의 기본정보, 검색량, 검색 비율까지\n 한눈에 확인할 수 있습니다."
          />
        </Helmet>
        <Loading
          open={isPendding1 || isPendding2 || isPendding3 || isPendding4}
        />

        <Grid container spacing={2}>
          {/* 검색창 */}
          <Grid item xs={12} md={4} sx={{ boxSizing: "border-box" }}>
            <Box sx={{ mb: 2 }}>
              <Typography
                fontWeight={900}
                variant="h5"
                sx={{ color: MAIN_COLOR }}
              >
                <CustomIcon src={keywordICon} alt="키워드 아이콘" top="4px" />
                키워드 상세 분석
              </Typography>
            </Box>

            <Box
              sx={{
                minWidth: "100%",
                boxSizing: "border-box",
              }}
            >
              <Box sx={{ my: 1 }}>
                <Typography
                  variant="body1"
                  fontWeight={600}
                  color={DESCRIPTION_COLOR}
                >
                  분석할 키워드를 입력하세요.
                  <br />
                  키워드의 기본정보, 검색량, 검색 비율까지 한눈에 확인할 수
                  있습니다.
                </Typography>
              </Box>

              {mdUp ? (
                <OutlinedInput
                  sx={{
                    position: "relative",
                    width: "100%",
                    borderRadius: "16px",
                    backgroundColor: "white",
                    border: `2px solid ${MAIN_COLOR}`,
                    height: "280px",
                  }}
                  value={inputValue}
                  onChange={handeInputValueChange}
                  onKeyDown={handleSubmit}
                  placeholder="쉼표로 구분하여 최대 5개 까지!"
                  rows={10}
                  multiline
                  fullWidth
                  endAdornment={
                    <Button
                      onClick={() => {
                        onSubmit(inputValue);
                      }}
                      variant="contained"
                      startIcon={<SearchIcon />}
                      sx={{
                        position: "absolute",
                        width: "90px",
                        color: "white",
                        backgroundColor: MAIN_COLOR,
                        borderRadius: "30px",
                        right: "10px",
                        bottom: "10px",
                      }}
                    >
                      분석
                    </Button>
                  }
                />
              ) : (
                <OutlinedInput
                  sx={{
                    width: "100%",
                    borderRadius: "30px",
                    backgroundColor: "white",
                    border: `2px solid ${MAIN_COLOR}`,
                    height: "50px",
                  }}
                  value={inputValue}
                  onChange={handeInputValueChange}
                  onKeyDown={handleSubmit}
                  placeholder="쉼표로 구분하여 최대 5개 까지!"
                  fullWidth
                  endAdornment={
                    <Button
                      onClick={() => {
                        onSubmit(inputValue);
                      }}
                      variant="contained"
                      startIcon={<SearchIcon />}
                      sx={{
                        position: "relative",
                        width: "90px",
                        color: "white",
                        backgroundColor: MAIN_COLOR,
                        borderRadius: "30px",
                        left: "10px",
                      }}
                    >
                      분석
                    </Button>
                  }
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <KeywordInfo data={keywordInfo} mdUp={mdUp} />
          </Grid>
          <Grid item xs={12}>
            <RecentSearchVolume
              keywords={keywords}
              data={keywordTools?.relData}
            />
          </Grid>
          <Grid item xs={12}>
            <SearchAmountGraph data={keywordTools?.cntData} />
          </Grid>
          {/* <Grid item xs={12}>
              <SearchAmountTable data={keywordTools?.cntData} />
            </Grid> */}
          <Grid item xs={12} md={6}>
            <MonthlySearchRatio data={keywordTools?.cntData} />
          </Grid>
          <Grid item xs={12} md={6}>
            <WeeklySearchRatio data={keywordTools?.cntData} />
          </Grid>
          <Grid item xs={12}>
            <AgeSearchRatio keywords={keywords} data={ageRate} />
          </Grid>
          <Grid item xs={12}>
            <GenderAndDeviceSearchRatio
              keywords={keywords}
              genderRate={genderRate}
              relData={keywordTools?.relData}
            />
          </Grid>
          <Grid item xs={12}>
            <RelationKeywordTable
              onSubmit={onSubmit}
              data={keywordTools?.relData}
            />
          </Grid>
        </Grid>
      </>
    );
  }
};

export default KeywordAnalysis;
