import axios from "axios";
import { clearUserInfo, setUserInfo } from "../../store";

export const UserAPI = {
  async login(username: string, password: string) {
    const body = {
      username,
      password, //암호화
    };
    return await axios
      .post("https://셀러원정대.com/api/login", body, { withCredentials: true })
      .then((res) => res);
  },

  async signUp(body: SignUpType) {
    return await axios
      .post("https://셀러원정대.com/api/register", body, { withCredentials: true })
      .then((res) => res);
  },
  async logout() {
    return await axios
      .post("https://셀러원정대.com/api/logout", { withCredentials: true })
      .then((res) => res);
  },

  //불로오는 순간 전역 변수에 저장할 수 있음
  async userInfo() {
    return await axios
      .get("https://셀러원정대.com/api/user_info", { withCredentials: true })
      .then((res) => {
        const { name, username, staff } = res.data;
        setUserInfo(name, username, staff === 1);
      })
      .catch((err) => {
        clearUserInfo();
      });
  },
};

export type SignUpType = {
  username: string;
  password: string;
  firstName: string;
  funnels: string | undefined;
  interest: string | undefined;
};
