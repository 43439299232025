import { useMutation } from "@tanstack/react-query";
import { shoppingKeywordAnalysisAPI } from "./shoppingKeywordAnalysisAxios";
import { relDataType } from "../keywordAnalysis/keywordAnalysisHooks";

// 첫 번째 매개변수: mutation 성공 시 반환될 데이터의 타입
// 두 번째 매개변수: mutation 실패 시 반환될 에러의 타입
// 세 번째 매개변수: mutation 함수의 매개변수 타입

// 쇼핑키워드 분석
export const useShoppingMutation = () => {
  return useMutation<shoppingType, Error, string>({
    mutationFn: (keywords: string) =>
      shoppingKeywordAnalysisAPI.getShoppingInfoLite(keywords),
    onSuccess: (data: any) => {
      return data;
    },
    onError: (err) => {
      //   console.log("쇼핑키워드 분석 onError >>> ", err);
    },
  });
};

// 쇼핑 키워드 분석 only items
export const useShoppingOnlyItemsMutation = () => {
  return useMutation<shoppingType, Error, string>({
    mutationFn: (keywords: string) =>
      shoppingKeywordAnalysisAPI.getShoppinginfoLiteOnlyItems(keywords),
    onSuccess: (data: any) => {
      return data;
    },
    onError: (err) => {
      //  console.log("쇼핑키워드 분석 only items onError >>> ", err);
    },
  });
};

export type shoppingType = {
  relData: relDataType;
  total: number;
  items: shoppingItemType[];
};

export type shoppingItemType = {
  title: string;
  link: string;
  image: string;
  hprice: number;
  lprice: number;
  mallname: string;
  productId: number;
  productType: number;
  brand: string;
  maker: string;
  category1: string;
  category2: string;
  category3: string;
  category4: string;
};
