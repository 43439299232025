import { Card, Typography, Stack, Grid, Divider } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import TitleAndDescription from "../title-and-description/TitleAndDescription";
import { useMemo } from "react";
import { getColor } from "../../config/color-config";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { relDataType } from "../../service/keywordAnalysis/keywordAnalysisHooks";
const xAxisType = ["월간검색량 PC", "월간검색량 MO", "월간검색량 Total"];

type dataSetType = {
  [key: string]: number;
};

interface RecentSearchVolumeProps {
  data: relDataType | undefined;
  keywords: string[];
}

const RecentSearchVolume: React.FC<RecentSearchVolumeProps> = ({
  data,
  keywords,
}) => {
  //라벨 설정
  const series = useMemo(() => {
    return keywords.map((key, i) => {
      return { dataKey: key, label: key, color: getColor(i) };
    });
  }, [keywords]);

  //dataset 만들기
  const dataset = useMemo(() => {
    return ["PC", "Mobile", "total"].map((order) => {
      const value: dataSetType = {};
      //
      if (order === "PC") {
        keywords.forEach((keyword) => {
          let val = data![keyword].monthlyPcQcCnt;
          if (typeof val === "string") {
            val = 10;
          }
          value[keyword] = val;
        });
      } else if (order === "Mobile") {
        keywords.forEach((keyword) => {
          let val = data![keyword].monthlyMobileQcCnt;
          if (typeof val === "string") {
            val = 10;
          }
          value[keyword] = val;
        });
      } else if (order === "total") {
        keywords.forEach((keyword) => {
          let val1 = data![keyword].monthlyMobileQcCnt;
          let val2 = data![keyword].monthlyPcQcCnt;
          if (typeof val1 === "string") {
            val1 = 10;
          }
          if (typeof val2 === "string") {
            val2 = 10;
          }
          value[keyword] = val1 + val2;
        });
      }

      return value;
    });
  }, [data, keywords]);

  return (
    <Card>
      <Stack
        direction={"column"}
        sx={{ backgroundColor: !data ? "white" : "#f0f2f6" }}
      >
        {/* 제목 */}

        <TitleAndDescription
          title={description.title}
          content={description.content}
        />
        {/* 그래프  */}
        {!!data && (
          <>
            <Stack direction={"row"} justifyContent={"space-around"}>
              {dataset.map((item, i) => {
                return (
                  <BarChart
                    key={i}
                    slotProps={{ legend: { hidden: true } }} // 라벨 숨기기
                    dataset={[item]} //데이터 셋
                    bottomAxis={null} //하단 범위 숨기기
                    leftAxis={null} //좌측 범위 숨기기
                    series={series} //마우스 올렸을 때 나오는 정보 라벨링
                    height={200} //높이
                    xAxis={[
                      {
                        data: [xAxisType[i]],
                        scaleType: "band",
                      },
                    ]}
                    margin={{ top: 0, bottom: 0, left: 30, right: 30 }} //이건 심심하면 ㄱㄱ
                  />
                );
              })}
            </Stack>

            {/* 아이콘 숫자 */}
            <Stack
              direction={"column"}
              sx={{
                backgroundColor: "white",
                borderRadius: "8px",
                p: 1,
              }}
            >
              {keywords &&
                keywords.map((item, i) => {
                  return (
                    <div key={i}>
                      <VolumnItem
                        key={i}
                        title={item}
                        color={getColor(i)}
                        pc={dataset[0][item]}
                        mobile={dataset[1][item]}
                        total={dataset[2][item]}
                      />
                      {i + 1 !== keywords.length && <Divider sx={{ my: 1 }} />}
                    </div>
                  );
                })}
            </Stack>
          </>
        )}
      </Stack>
    </Card>
  );
};

export default RecentSearchVolume;

interface VolumnItemProps {
  title: string;
  color: string;
  pc: string | number;
  mobile: string | number;
  total: string | number;
}

const VolumnItem: React.FC<VolumnItemProps> = ({
  title,
  color,
  pc,
  mobile,
  total,
}) => {
  return (
    <>
      <Grid container sx={{ p: 1 }}>
        <Grid item xs={12}>
          <Typography textAlign={"center"} fontWeight={"bold"}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Stack alignItems={"center"}>
            <Typography variant="caption" color={"grey"}>
              PC
            </Typography>
            <DesktopWindowsOutlinedIcon sx={{ color }} />
            <Typography variant="body1" fontWeight={"bold"}>
              {pc.toLocaleString()}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack alignItems={"center"}>
            <Typography variant="caption" color={"grey"}>
              Mobile
            </Typography>
            <PhoneAndroidOutlinedIcon sx={{ color }} />
            <Typography variant="body1" fontWeight={"bold"}>
              {mobile.toLocaleString()}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack alignItems={"center"}>
            <Typography variant="caption" color={"grey"}>
              Total
            </Typography>
            <ControlPointIcon sx={{ color }} />
            <Typography variant="body1" fontWeight={"bold"}>
              {total.toLocaleString()}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

//임시 데이터들
const description = {
  title: "최근 한달 검색량",
  content: ["최근 한달동안 키워드가 검색된 횟수입니다."],
};
